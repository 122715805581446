import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "../../config/axios";
import { Redirect, useHistory } from "react-router-dom";
import ModalDelete from "./users/ModalDelete";
import RenderAccount from "./users/RenderAccount";
import ErrorSuccess from "./login/ErrorSuccess";
import KotakCari from "../meeting/meetings/KotakCari";
import ReactPaginate from "react-paginate";
import { config } from "../../config";

const Users = ({ userData, location }) => {
  const history = useHistory(); // utk push halaman pagination di url
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState([]);
  const [query, setQuery] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const pageFromParams = new URLSearchParams(location.search).get("page");
  const [page, setPage] = useState(pageFromParams ? pageFromParams : 1);
  const onPageClick = (e) => {
    const halaman = e.selected + 1;
    history.push("?page=" + halaman);
    setPage(halaman);
    window.scrollTo(0, 0);
  };

  const [modal, setModal] = useState({
    display: "modal",
    disableModalBtn: false,
    accountId: 0,
  });

  // matikan modal
  const modalOff = () =>
    setModal({
      ...modal,
      display: "modal",
    });

  // aktifkan modal dan kirim id account ke state
  const modalOn = (e) =>
    setModal({
      ...modal,
      display: "modal is-active",
      accountId: e.target.id,
    });

  // hapus user
  const deleteUser = () => {
    if (!modal.disableModalBtn) {
      setModal({
        ...modal,
        disableModalBtn: true,
      });

      axios
        .delete("/accounts/" + modal.accountId, {
          headers: {
            Authorization: "Bearer " + userData.data.jwtToken,
          },
        })
        .then(() => {
          setError("");
          window.scrollTo(0, 0);
          setSuccess("User berhasil dihapus");
          setModal({
            ...modal,
            display: "modal",
            disableModalBtn: false,
          });
        })
        .catch((err) => {
          setSuccess("");
          setModal({
            ...modal,
            display: "modal",
            disableModalBtn: false,
          });
          console.log(err);
          window.scrollTo(0, 0);
          if (err.response) {
            err.response.data.message && setError(err.response.data.message);
            err.response.data.title && setError(err.response.data.title);
          } else {
            setError("Maaf terjadi kesalahan");
          }
        });
    }
  };

  useEffect(() => {
    // jika user telah berhasil menambah berita tampilkan pesan sukses
    setSuccess(location.state?.pesanSukses);
    setError("");
    window.scrollTo(0, 0);
  }, [location.state?.pesanSukses]);

  // ubah title
  useEffect(() => {
    document.title = "Daftar User - BPPT";
  }, []);

  // atur pencarian user
  const changeCari = (e) => {
    setQuery(e.target.value);
    setPage(1);
  };

  // GET /accounts
  useEffect(() => {
    axios
      .get(`/accounts?page=${page}&query=${query}`, {
        headers: {
          Authorization: "Bearer " + userData.data.jwtToken,
        },
      })
      .then((res) => {
        console.log("user nih", res.data);
        setAccounts(sortUsers(res.data.data));
        setPageCount(res.data.totalPage);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        window.scrollTo(0, 0);
        if (err.response) {
          err.response.data.message && setError(err.response.data.message);
          err.response.data.title && setError(err.response.data.title);
        } else {
          setError("Maaf terjadi kesalahan");
        }
      });
  }, [success, page, query]); // eslint-disable-line react-hooks/exhaustive-deps

  // pindah ke halaman login jika blm login
  if (userData.data.role !== "Admin") {
    return <Redirect to="/meeting?page=1" />;
  }

  return (
    <>
      <div className="column">
        {/* peringatan success */}
        <ErrorSuccess error={error} success={success} />

        {/* kotak pencarian utk mencari users */}
        <KotakCari changeCari={changeCari} placeholder="Cari nama user" />

        {/* loading bar */}
        {loading && (
          <progress className="progress is-small is-info" max="100"></progress>
        )}

        {/* render accounts */}
        {accounts.length > 0 &&
          accounts.map((account) => (
            <RenderAccount
              key={account.id.toString()}
              title={account.fullName}
              email={account.email}
              role={account.role}
              img={
                account.avatar
                  ? config.REACT_APP_URL_GAMBAR + account.avatar.imageSmall
                  : "/img/placeholder/bppt120x120.png"
              }
              id={account.id}
              isVerified={account.isVerified}
              isActive={account.isActive}
              modalOn={modalOn}
            />
          ))}

        {/* pagination */}
        <ReactPaginate
          initialPage={page - 1}
          pageCount={pageCount}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          previousLabel="Sebelumnya"
          nextLabel="Selanjutnya"
          containerClassName="pagination is-rounded is-justify-content-start mt-6"
          pageLinkClassName="pagination-link"
          activeLinkClassName="pagination-link is-current"
          previousClassName="pagination-previous"
          nextClassName="pagination-next"
          breakLinkClassName="pagination-ellipsis"
          onPageChange={onPageClick}
          disableInitialCallback
        />
      </div>

      <ModalDelete
        display={modal.display}
        disableModalBtn={modal.disableModalBtn}
        modalOff={modalOff}
        deleteUser={deleteUser}
      />
    </>
  );
};

// sort user berdasarkan yg terbaru
const sortUsers = (users) => {
  return users.sort((a, b) => new Date(b.created) - new Date(a.created));
};

const mapStateToProps = (state) => {
  return {
    userData: state,
  };
};

export default connect(mapStateToProps)(Users);
