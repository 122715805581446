import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import axios from "../../config/axios";

const ForgotPassword = ({ userData }) => {
  // ubah title
  useEffect(() => {
    document.title = "Lupa Password - BPPT";
  }, []);

  // tampilkan pesan error / sukses
  const [error, setError] = useState(""); // error title
  const [errorFields, setErrorFields] = useState({}); // error per-fields
  const [success, setSuccess] = useState("");

  // atur perubahan status button jika fetch data
  const [button, setButton] = useState(false);

  // atur perubahan field email
  const [email, setEmail] = useState("");
  const updateEmail = (e) => setEmail(e.target.value);

  // jika tombol submit diklik
  const handleSubmit = () => {
    if (!button) {
      // disable button saat fetch
      setButton(true);

      // lakukan fetch
      axios
        .post("/forgot-password", { email })
        .then((res) => {
          setError("");
          setSuccess(res.data.message);
          setButton(false);
          window.scrollTo(0, 0);
        })
        .catch((err) => {
          setSuccess("");
          setButton(false);
          console.log(err);
          window.scrollTo(0, 0);
          if (err.response) {
            err.response.data.message && setError(err.response.data.message);
            err.response.data.title && setError(err.response.data.title);
            err.response.data.errors &&
              setErrorFields(err.response.data.errors);
          } else {
            setError("Maaf terjadi kesalahan");
          }
        });
    }
  };

  // pindah ke halaman dashboard jika sdh login
  if (userData.data.jwtToken) {
    return <Redirect to="/meeting/hari-ini" />;
  }

  return (
    <div className="column p-6">
      {/* peringatan error dan sukses */}
      <div className="my-5">
        {error && <p className="notification is-danger">{error}</p>}
        {success && <p className="notification is-info">{success}</p>}
      </div>

      <p className="is-size-3 mb-5">Lupa Password</p>

      <div className="field">
        <label className="label">Email</label>
        <div className="control">
          <input
            className="input"
            type="email"
            placeholder="Email"
            onChange={updateEmail}
          />
        </div>
        {errorFields.Email &&
          errorFields.Email.map((error, i) => (
            <small className="help is-danger" key={i.toString()}>
              {error}
            </small>
          ))}
      </div>

      {/* button */}
      <button
        className="button is-link"
        disabled={button}
        onClick={handleSubmit}
      >
        Kirim
      </button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state,
  };
};

export default connect(mapStateToProps)(ForgotPassword);
