import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import RenderChat from "./RenderChat";
import DaftarGambar from "./DaftarGambar";
import UploadMenu from "./upload/UploadMenu";
import DaftarFile from "./upload/DaftarFile";

const ChatPanel = ({
  userData,
  show,
  roomName,
  scheduleId,
  signalRConn,
  error,
  messages,
  chatDiv,
  jumlahGambar,
  setJumlahGambar,
  jumlahFile,
  setJumlahFile,
  chatParrentRef,
  recipentProps,
}) => {
  console.log("messages dr chat panel nih", messages);
  const [showFile, setShowFile] = useState(false);
  const toggleShowFile = () => setShowFile((prevShowFile) => !prevShowFile);
  const [pesan, setPesan] = useState("");
  const changePesan = (e) => setPesan(e.target.value);
  const [chatError, setChatError] = useState("");
  const displayChatError = (err) => setChatError(err);
  const [recipientId, setRecipientId] = useState(null);
  const displayRecipientId = (id) => setRecipientId(id);
  const [recipientName, setRecipientName] = useState("");
  const displayRecipientName = (name) => setRecipientName(name);
  const [indicatorUpload, setIndicatorUpload] = useState("");

  useEffect(() => {
    if (recipentProps) {
      setRecipientId(recipentProps.id);
      setRecipientName(recipentProps.name);
    }
  }, [recipentProps]); // eslint-disable-line react-hooks/exhaustive-deps

  const batalChatPrivate = () => {
    setRecipientId("");
    setRecipientName("");
  };

  const kirimPesan = (e) => {
    e.preventDefault();

    if (!pesan) {
      setChatError("Ketik pesan terlebih dahulu");
    } else {
      const varPesan = pesan;
      setPesan("");
      if (signalRConn) {
        if (recipientId) {
          // chat private
          signalRConn
            .invoke("SendPrivateMessage", roomName, recipientId, varPesan)
            .then(() => {
              console.log("kirim pesan private berhasil");
              setChatError("");
            })
            .catch((err) => {
              console.log("kirim pesan private gagal", err);
              setChatError("Maaf terjadi kesalahan, silahkan refresh halaman");
            });
        } else {
          // chat biasa
          signalRConn
            .invoke("SendMessage", roomName, varPesan)
            .then(() => {
              console.log("kirim pesan berhasil");
              setChatError("");
            })
            .catch((err) => {
              console.log("kirim pesan gagal", err);
              setChatError("Maaf terjadi kesalahan, silahkan refresh halaman");
            });
        }
      }
    }
  };

  return (
    <div
      ref={chatParrentRef}
      className={show ? "" : "is-hidden"}
      style={{
        position: "absolute",
        bottom: "95px",
        top: "90px",
        left: 0,
        right: 0,
      }}
    >
      <div>
        <div>
          {/* chats */}
          <div
            ref={chatDiv}
            style={{
              overflowY: "scroll",
              overflowX: "hidden",
              position: "absolute",
              left: 0,
              right: 0,
              bottom: "110px",
              top: 0,
            }}
            className="p-2"
          >
            {showFile ? (
              <DaftarFile
                jumlahFile={jumlahFile}
                setJumlahFile={setJumlahFile}
                roomName={roomName}
                scheduleId={scheduleId}
                jwtToken={userData.data.jwtToken}
                toggleShowFile={toggleShowFile}
              />
            ) : messages.length > 0 ? (
              messages.map((message) => (
                <div className="columns" key={message.id?.toString()}>
                  <RenderChat
                    chatku={message.author?.id === userData.data.id}
                    message={message}
                    displayRecipientId={displayRecipientId}
                    displayRecipientName={displayRecipientName}
                  />
                </div>
              ))
            ) : (
              <p className="p-2 has-text-centered has-text-grey-light">
                {error ? (
                  <>
                    Maaf terjadi kesalahan, silahkan refresh halaman
                    <br />
                    <button
                      className="button is-small is-link mt-2"
                      onClick={refreshPage}
                    >
                      Refresh Halaman
                    </button>
                  </>
                ) : (
                  "Belum ada pesan"
                )}
              </p>
            )}
          </div>
        </div>

        <div style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
          {/* chat private */}
          <div className="panel-block" style={{ border: "none" }}>
            {recipientName && (
              <>
                <span
                  className="icon has-text-danger mb-1 is-clickable"
                  title="Batal"
                  onClick={batalChatPrivate}
                >
                  <i className="fas fa-times"></i>
                </span>

                <small className="help is-danger mb-2">
                  Mengirim pesan privat ke {recipientName}:
                </small>
              </>
            )}
          </div>

          {/* kirim pesan */}
          <div className="panel-block" style={{ border: "none" }}>
            <form onSubmit={kirimPesan} className="control has-icons-left">
              <input
                className="input"
                type="text"
                placeholder="Kirim pesan"
                value={pesan}
                onChange={changePesan}
              />
              <button className="is-hidden"></button>
              <span className="icon is-left">
                <i className="fas fa-paper-plane"></i>
              </span>
            </form>

            {/* dot icon (upload file) */}
            <UploadMenu
              roomName={roomName}
              scheduleId={scheduleId}
              userData={userData}
              signalRConn={signalRConn}
              displayChatError={displayChatError}
              toggleShowFile={toggleShowFile}
              setIndicatorUpload={setIndicatorUpload}
            />
          </div>

          <div className="panel-block mt-1" style={{ border: "none" }}>
            {/* chat error */}
            {chatError && (
              <small className="help is-danger mb-2">{chatError}</small>
            )}

            {/* indicator upload gambar & file*/}
            {indicatorUpload && (
              <small className="help has-text-grey mb-2">
                {indicatorUpload}
              </small>
            )}
          </div>

          <DaftarGambar
            jwtToken={userData.data.jwtToken}
            roomName={roomName}
            scheduleId={scheduleId}
            jumlahGambar={jumlahGambar}
            setJumlahGambar={setJumlahGambar}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state,
  };
};

const refreshPage = () => window.location.reload();

export default connect(mapStateToProps)(ChatPanel);
