import dayjs from "dayjs";
import formatTanggal from "../../../helpers/formatTanggal";
import msToTime from "../../../helpers/msToTime";

const formatDataStatistik = (roomName, data) => {
  return [
    {
      subtitle: "Kondisi Meeting Saat Ini",
      content: [
        {
          title: "Sedang Berlangsung",
          value: data.schedule.isStarted ? "Ya" : "Tidak",
        },
      ],
    },
    {
      subtitle: "Jadwal",
      content: [
        {
          title: "Kode Meeting",
          value: roomName,
        },
        {
          title: "Tanggal",
          value: formatTanggal(data.schedule.startDate),
        },
        {
          title: "Jam Mulai",
          value: data.schedule.start,
        },
        {
          title: "Jam Berakhir",
          value: data.schedule.end,
        },
      ],
    },
    {
      subtitle: "Pelaksanaan",
      content: [
        {
          title: "Telah Dimulai",
          value: formatTglJam(data.schedule.meetingStart),
        },
        {
          title: "Telah Berakhir",
          value: formatTglJam(data.schedule.meetingEnd),
        },
        {
          title: "Durasi",
          value: data.schedule.durationMinutes + " Menit",
        },
        {
          title: "Sedang Berlangsung",
          value: checkOrUncheck(data.schedule.isStarted),
        },
        {
          title: "Telah Selesai",
          value: checkOrUncheck(data.schedule.hasStarted),
        },
      ],
    },
    {
      subtitle: "Kehadiran",
      content: [
        {
          title: "Jumlah Partisipan",
          value: data.participatedUser.length,
        },
      ],
    },
    {
      subtitle: "",
      content: [
        {
          title: "Daftar Partisipan",
          value: formatDataPartisipan(data.participatedUser),
        },
      ],
    },
    {
      subtitle: "",
      content: [
        {
          title: "Kehadiran",
          value: formatDataKehadiran(data.meetingLog),
        },
      ],
    },
  ];
};

const formatDataKehadiran = (meetingLog) => {
  return (
    <>
      {meetingLog
        .sort((a, b) => a.author.id - b.author.id)
        .map((log) => (
          <p key={"meetinglog" + log.id}>
            <strong>{log.author.fullName}</strong> telah{" "}
            {log.action === 0 ? "bergabung" : "keluar"} pada{" "}
            <strong>{formatTglJam(log.created)}</strong>
          </p>
        ))}
    </>
  );
};

const formatDataPartisipan = (participatedUser) => {
  return (
    <>
      {participatedUser.map((user) => (
        <p key={"participated" + user.id}>
          <i className="fas fa-user has-text-grey-light mr-2"></i>
          {user.fullName}
        </p>
      ))}
    </>
  );
};

const checkOrUncheck = (data) => {
  return data ? (
    <i className="fas fa-check has-text-success"></i>
  ) : (
    <i className="fas fa-times has-text-danger"></i>
  );
};

const formatTglJam = (date) =>
  dayjs(date).add(7, "hour").format("D MMMM YYYY, HH:mm");

const formatDataFetchStatistikMeetingLog = (meetingLog) => {
  let logSorted = meetingLog.sort((a, b) => a.author.id - b.author.id);
  let newLog = [];

  do {
    // cari action gabung pertama (0)
    const gabungPertama = logSorted.find((log) => log.action === 0);
    const keluarPertama = logSorted.find((log) => log.action === 1);

    if (gabungPertama && keluarPertama) {
      // jika action gabung & action keluar ketemu
      newLog.push({
        id: gabungPertama.author.id,
        username: gabungPertama.author.fullName,
        bergabung: formatTglJam(gabungPertama.created),
        keluar: formatTglJam(keluarPertama.created),
        durasi:
          dayjs(keluarPertama.created).valueOf() -
          dayjs(gabungPertama.created).valueOf(),
      });

      logSorted = logSorted.filter((log) => log.id !== gabungPertama.id);
      logSorted = logSorted.filter((log) => log.id !== keluarPertama.id);
    } else if (gabungPertama && !keluarPertama) {
      // jika action gabung ketemu & action keluar g ketemu
      newLog.push({
        id: gabungPertama.author.id,
        username: gabungPertama.author.fullName,
        bergabung: formatTglJam(gabungPertama.created),
        keluar: "-",
        durasi: "-",
      });

      logSorted = logSorted.filter((log) => log.id !== gabungPertama.id);
    } else {
      // jika action gabung g ktemu & action keluar ketemu
      // jika action gabung & action keluar g ketemu
      logSorted = [];
    }
  } while (logSorted.length > 0);

  return newLog;
};

const formatDataFetchStatistikTotalDuration = (meetingLog) => {
  const reducer = (accumulator, currentValue) =>
    accumulator.durasi !== "-" && currentValue.durasi !== "-"
      ? { durasi: accumulator.durasi + currentValue.durasi }
      : { durasi: "-" };
  const logDurations = formatDataFetchStatistikMeetingLog(meetingLog);
  const uniqueUserIds = [...new Set(logDurations.map((log) => log.id))];
  let totalDurations = [];

  for (let i = 0; i < uniqueUserIds.length; i++) {
    const durationsThatId = logDurations.filter(
      (logDuration) => logDuration.id === uniqueUserIds[i]
    );

    totalDurations.push({
      id: durationsThatId[0].id,
      username: durationsThatId[0].username,
      totalDuration: durationsThatId.reduce(reducer).durasi,
    });
  }

  return totalDurations;
};

const formatDataFetchStatistik = (roomName, dataStatistik) => {
  return {
    jadwal: [
      {
        roomName,
        startDate: formatTanggal(dataStatistik.schedule.startDate),
        start: dataStatistik.schedule.start,
        end: dataStatistik.schedule.end,
      },
    ],
    meetingLog:
      dataStatistik.meetingLog.length > 0
        ? formatDataFetchStatistikMeetingLog(dataStatistik.meetingLog).map(
            (log) => {
              return {
                ...log,
                durasi: log.durasi !== "-" ? msToTime(log.durasi) : "-",
              };
            }
          )
        : [],
    participatedUser:
      dataStatistik.participatedUser.length > 0
        ? dataStatistik.participatedUser.map((user) => {
            return {
              id: user.id,
              username: user.fullName,
            };
          })
        : [],
    totalDuration:
      dataStatistik.meetingLog.length > 0
        ? formatDataFetchStatistikTotalDuration(dataStatistik.meetingLog).map(
            (log) => {
              return {
                ...log,
                totalDuration:
                  log.totalDuration !== "-" ? msToTime(log.totalDuration) : "-",
              };
            }
          )
        : [],
  };
};

export {
  formatDataFetchStatistik,
  formatTglJam,
  checkOrUncheck,
  formatDataPartisipan,
  formatDataKehadiran,
  formatDataStatistik,
};
