import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import axios from "../../config/axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import formatTanggal2 from "../../helpers/formatTanggal2";
import formatDate from "../../helpers/formatDate";
import { sortData } from "../meeting/video/chat-panel/DaftarGambar";
import AddKategori from "../news/AddKategori";
import { useDropzone } from "react-dropzone";
import { config } from "../../config";

const EditEvent = ({ userData }) => {
  // utk dragndrop
  const [files, setFiles] = useState([]);

  // looping fields
  const [fields, setFields] = useState([
    { label: "Judul", name: "Title", type: "text", id: "title" },
    {
      label: "Pilih gambar",
      name: "FeaturedImage",
      type: "file",
      id: "featuredImage",
    },
    {
      label: "Kategori",
      name: "CategoryId",
      type: "select",
      id: "category",
      options: [],
    },
    { label: "Dimulai", name: "StartDate", type: "date", id: "startDate" },
    { label: "Berakhir", name: "EndDate", type: "date", id: "endDate" },
  ]);

  const setOptions = (option) => {
    const data = fields.map((field) =>
      field.label === "Kategori"
        ? { ...field, options: field.options.concat(option) }
        : field
    );
    const sortedData = sortData(data);
    setFields(sortedData);
  };

  const { slug } = useParams(); // utk ambil slug di URL
  const form = useRef(null); // utk submit form
  const [redirectSlug, setRedirectSlug] = useState(false); // redirect jika slug tdk ditemukan di db
  const [loading, setLoading] = useState(true); // menampilkan loading saat data meeting sedang di fetch
  const [event, setEvent] = useState({}); // GET /event/slug
  const [redirect, setRedirect] = useState(false); // redirect ke daftar acara jika berhasil tambah acara
  const [disableBtn, setDisableBtn] = useState(false); // disableBtn button & field saat fetch
  const [error, setError] = useState("");
  const [errorFields, setErrorFields] = useState({
    FeaturedImage: [],
  });
  const [editorValue, setEditorValue] = useState("");
  const editorChange = (e, editor) => {
    setEditorValue(editor.getData());
    console.log(editor.getData());
  };

  useEffect(() => {
    // GET /event/slug
    axios
      .get("/event/" + slug, {
        headers: {
          Authorization: "Bearer " + userData.data.jwtToken,
        },
      })
      .then((res) => {
        setLoading(false);
        const data = res.data.data;
        console.log(data);
        setEvent({
          ...data,
          category: data.category ? data.category.title : "",
        });
        setEditorValue(data.content);
      })
      .catch((err) => {
        setRedirectSlug(true);
        setLoading(false);
        console.log(err);
        window.scrollTo(0, 0);
        if (err.response) {
          err.response.data.message && setError(err.response.data.message);
          err.response.data.title && setError(err.response.data.title);
        } else {
          setError("Maaf terjadi kesalahan");
        }
      });

    // get data event category
    axios
      .get("/event-category", {
        headers: {
          Authorization: "Bearer " + userData.data.jwtToken,
        },
      })
      .then((res) => setOptions(sortData(res.data.data)))
      .catch((err) => {
        console.log(err);
        if (err.response) {
          err.response.data.message && setError(err.response.data.message);
          err.response.data.title && setError(err.response.data.title);
        } else {
          setError("Maaf terjadi kesalahan");
        }
        window.scrollTo(0, 0);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // PUT ke /api/event
  const editEvent = (e) => {
    // spy tdk refresh halaman saat submit form
    e.preventDefault();

    if (!disableBtn) {
      // disableBtn button saat fetch
      setDisableBtn(true);

      // mulai fetch
      let data = new FormData(form.current);
      data.append("Content", editorValue);
      data.append("FeaturedImage", files[0]);
      axios
        .put("/event/" + slug, data, {
          headers: {
            Authorization: "Bearer " + userData.data.jwtToken,
          },
        })
        .then(() => setRedirect(true))
        .catch((err) => {
          setDisableBtn(false);
          console.log(err);
          window.scrollTo(0, 0);
          if (err.response) {
            if (err.response.data.message === "Sequence contains no elements") {
              setRedirect(true);
            } else {
              err.response.data.message && setError(err.response.data.message);
              err.response.data.title && setError(err.response.data.title);
              err.response.data.errors &&
                setErrorFields(err.response.data.errors);
            }
          } else {
            setError("Maaf terjadi kesalahan");
          }
        });
    }
  };

  useEffect(() => {
    // ubah title
    document.title = "Edit Acara - BPPT";
  }, []);

  // pindah ke halaman login jika blm login
  if (userData.data.role !== "Admin") {
    return <Redirect to="/meeting/hari-ini" />;
  } else if (redirect) {
    return (
      <Redirect
        to={{
          pathname: "/acara",
          state: { pesanSukses: "Edit acara berhasil" },
        }}
      />
    );
  }

  return (
    <form ref={form} className="column p-5" onSubmit={editEvent}>
      {slug && loading ? (
        <progress className="progress is-small is-info" max="100"></progress>
      ) : redirectSlug ? (
        <p className="has-text-centered has-text-grey-light">
          {error ? error : "Maaf terjadi kesalahan"}
        </p>
      ) : (
        <>
          {/* judul */}
          <p className="is-size-3 mb-5">Edit Acara</p>

          {/* preview gambar */}
          <div className="field is-horizontal">
            <div className="field-label">{/* kosong utk kasih jarak */}</div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <img
                    src={
                      files.length > 0
                        ? files[0].preview
                        : event.featuredImage
                        ? config.REACT_APP_URL_GAMBAR +
                          event.featuredImage.imageDefault
                        : "/img/placeholder/bppt800x800.png"
                    }
                    alt={"gambar-" + event.slug}
                  />
                </div>
              </div>
            </div>
          </div>

          {fields.map((field, i) => (
            <div className="field is-horizontal" key={i.toString()}>
              <div className="field-label is-normal">
                <label className="label">{field.label}</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <RenderInput
                      field={field}
                      files={files}
                      setFiles={setFiles}
                      setErrorFields={setErrorFields}
                      defaultValue={event[field.id]}
                    />
                    {field.label === "Kategori" && (
                      <AddKategori
                        endpoint="/event-category"
                        jwtToken={userData.data.jwtToken}
                        setOptions={setOptions}
                      />
                    )}
                  </div>

                  {/* error per field  */}
                  {errorFields[field.name] &&
                    errorFields[field.name].map((error, i) => (
                      <small className="help is-danger" key={i.toString()}>
                        {error}
                      </small>
                    ))}
                </div>
              </div>
            </div>
          ))}

          {editorValue && (
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">Konten</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <CKEditor
                      data={editorValue}
                      editor={ClassicEditor}
                      onChange={editorChange}
                      config={editorConfiguration}
                    />
                  </div>

                  {/* error per field  */}
                  {errorFields.Content &&
                    errorFields.Content.map((error, i) => (
                      <small className="help is-danger" key={i.toString()}>
                        {error}
                      </small>
                    ))}
                </div>
              </div>
            </div>
          )}

          <div className="field is-horizontal">
            <div className="field-label">{/* kosong utk kasih jarak */}</div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <button className="button is-link" disabled={disableBtn}>
                    Simpan
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </form>
  );
};

const editorConfiguration = {
  toolbar: [
    "heading",
    "|",
    "bold",
    "italic",
    "link",
    "bulletedList",
    "numberedList",
    "|",
    "blockQuote",
    "insertTable",
    "undo",
    "redo",
  ],
};

const RenderInput = ({
  field,
  files,
  setFiles,
  setErrorFields,
  defaultValue,
}) => {
  // utk drandrop
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );

      if (acceptedFiles[0].size > 5242880) {
        setErrorFields((prevErr) => {
          return {
            ...prevErr,
            FeaturedImage: ["Maksimal ukuran gambar 5MB"],
          };
        });
      } else {
        window.scrollTo(0, 0);
        setErrorFields((prevErr) => {
          return {
            ...prevErr,
            FeaturedImage: [],
          };
        });
      }
    },
  });

  const [date, setDate] = useState("");
  const dateChange = (e) => setDate(e.target.value);

  // destructuring prop field
  const { type, label, name, options } = field;

  const defaultValueValue = options?.find(
    (option) => option.title === defaultValue
  );

  // daftar jsx yg bs dipilih sesuai type
  const inputs = {
    text: (
      <input
        className="input"
        name={name}
        type="text"
        placeholder={label}
        defaultValue={defaultValue}
      />
    ),
    select: (
      <div className="select">
        <select name={name}>
          <option value={defaultValueValue?.id}>
            {defaultValueValue?.title}
          </option>

          {options
            ?.filter((option) => option.title !== defaultValue)
            .map((option) => (
              <option value={option.id} key={option.title}>
                {option.title}
              </option>
            ))}
        </select>
      </div>
    ),
    file: (
      <div
        {...getRootProps()}
        style={{
          height: 100,
          border: "2px dashed #ccc",
          color: "#ccc",
          padding: 10,
        }}
      >
        {files.length > 0 ? files[0].name : "Belum ada file"}
        <input {...getInputProps()} style={{ display: "none" }} />
      </div>
    ),
    date: (
      <>
        <label
          htmlFor={name}
          style={{
            position: "absolute",
            width: "80%",
            height: "100%",
            backgroundColor: "#fff",
            zIndex: 2,
            padding: "7px 10px",
            border: "1px solid lightgrey",
            borderRight: "none",
            borderRadius: 5,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          {date ? formatTanggal2(date) : formatTanggal2(defaultValue)}
        </label>
        <input
          className="input"
          name={name}
          id={name}
          type="date"
          placeholder={label}
          value={date ? formatDate(date) : formatDate(defaultValue)}
          style={{
            border: "1px solid lightgrey",
          }}
          onChange={dateChange}
        />
      </>
    ),
  };

  return inputs[type];
};

const mapStateToProps = (state) => {
  return {
    userData: state,
  };
};

export default connect(mapStateToProps)(EditEvent);
