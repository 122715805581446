import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, Link } from "react-router-dom";
import axios from "../../config/axios";
import formatTanggal from "../../helpers/formatTanggal";
import ICalendarLink from "react-icalendar-link";
import ShareLink, { dataToIcs } from "./video/ShareLink";
import dayjs from "dayjs";
import renderChecklist from "./detail-meeting/renderChecklist";
import FormForm from "./detail-meeting/FormForm";
import AddGoogleCalendar from "./detail-meeting/AddGoogleCalendar";
import AddOutlookCalendar from "./detail-meeting/AddOutlookCalendar";
import AddZimbraCalendar from "./detail-meeting/AddZimbraCalendar";
import { config } from "../../config";

const DetailMeeting = ({ userData, location }) => {
  const test = new URLSearchParams(location.search).get("test");
  const { roomName, scheduleId } = useParams(); // roomName diambil dr url
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const clearSuccess = () => setSuccess(false);
  const [meetingKeDepan, setMeetingKeDepan] = useState(false); // menentukan tombol ics & bagikan meeting ditampilkan ga
  const [icsDetail, setIcsDetail] = useState({}); // utk donlot ics file
  const [redirect, setRedirect] = useState(false); // redirect jika roomName tdk ditemukan di db
  const [loading, setLoading] = useState(true); // menampilkan loading saat data meeting sedang di fetch
  const [meeting, setMeeting] = useState({}); // meeting data
  const [meetingPassword, setMeetingPassword] = useState("");

  // mengelola data hosts & participants
  const [hp, setHp] = useState({
    hosts: [],
    participants: [],
  });

  useEffect(() => {
    // ubah title
    document.title = `${
      meeting.title ? meeting.title : "Detail Meeting"
    } - BPPT`;
  }, [meeting.title]);

  useEffect(() => {
    // fetch data meeting
    if (roomName) {
      axios
        .get("/meeting/" + roomName + "/schedule/" + scheduleId, {
          headers: {
            Authorization: "Bearer " + userData.data.jwtToken,
          },
        })
        .then((res) => {
          const data = res.data.data;
          console.log(data);
          setIcsDetail(dataToIcs(data));
          setMeeting(data);

          // ambil data dr meetingParticipant
          const hostAndParticipant = data.meetingParticipant;
          const hosts = hostAndParticipant.filter((host) => host.role === 0);
          const participants = hostAndParticipant.filter(
            (participant) => participant.role === 1
          );

          // isi state hosts & participants
          if (hostAndParticipant.length !== undefined) {
            setHp((prevHp) => {
              return {
                ...prevHp,
                hosts: hosts.map((host) => host.author.fullName),
                participants: participants.map(
                  (participant) => participant.author.fullName
                ),
              };
            });
          }

          // menentukan ini meeting yg telah lalu atau bkn
          const tglMeeting = dayjs(data.schedule.startDate)
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
            .toDate()
            .valueOf();
          const tglHariIni = dayjs()
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
            .toDate()
            .valueOf();
          setMeetingKeDepan(tglMeeting >= tglHariIni);

          // hilangkan loading indicator
          setLoading(false);
        })
        .catch((err) => {
          setRedirect(true);
          setLoading(false);
          window.scrollTo(0, 0);
          console.log(err);
          if (err.response) {
            err.response.data.message && setError(err.response.data.message);
            err.response.data.title && setError(err.response.data.title);
          } else {
            setError("Maaf terjadi kesalahan");
          }
        });
    }

    // fetch password meeting jika ada
    axios
      .get(`/meeting/${roomName}/password`, {
        headers: {
          Authorization: "Bearer " + userData.data.jwtToken,
        },
      })
      .then((res) => {
        setMeetingPassword(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // array yg akan di-looping utk menampilkan data meeting
  const fields = [
    { label: "Kode Meeting", value: roomName },
    { label: "Password", value: meetingPassword ? meetingPassword : "-" },
    { label: "Judul Meeting", value: meeting.title },
    { label: "Deskripsi", value: meeting.description },
    {
      label: "Tanggal",
      value: formatTanggal(meeting.schedule?.startDate),
    },
    {
      label: "Jam Mulai",
      value: meeting.schedule?.start + " WIB",
    },
    {
      label: "Jam Selesai",
      value: meeting.schedule?.end + " WIB",
    },
    // {
    //   label: "Video",
    //   value: [
    //     {
    //       type: meeting.isHostVideoAvailable,
    //       text: "Host",
    //     },
    //     {
    //       type: meeting.isParticipantVideoAvailable,
    //       text: "Partisipan",
    //     },
    //   ],
    //   type: "array",
    // },
    // {
    //   label: "Saat Meeting Dimulai",
    //   value: [
    //     {
    //       type: meeting.isMuteParticipantUponEntry,
    //       text: "Matikan suara saat partisipan masuk",
    //     },
    //   ],
    //   type: "array",
    // },
    {
      label: "Ada Persetujuan",
      value: [{ type: meeting.isAdmit, text: "Ya" }],
      type: "array",
    },
    {
      label: "Meeting Publik",
      value: [{ type: meeting.isPublic, text: "Ya" }],
      type: "array",
    },
    {
      label: "Meeting Berulang",
      value: [{ type: meeting.isRecuring, text: "Ya" }],
      type: "array",
    },
    {
      value: (
        <FormForm
          interval={meeting.interval}
          intervalValue={meeting.intervalValue}
          occurrenceValues={meeting.occurrenceValues}
          endType={meeting.endType}
          endValue={meeting.endValue}
        />
      ),
      type: "component",
    },
    {
      label: "Jumlah Partisipan Maksimal",
      value: meeting.maxParticipant ? meeting.maxParticipant : 100,
    },
    {
      label: "Host",
      value: hp.hosts.length > 0 ? hp.hosts : "Tidak ada host di meeting ini.",
      type: hp.hosts.length > 0 ? "array" : "text",
    },
    {
      label: "Partisipan",
      value:
        hp.participants.length > 0
          ? hp.participants
          : "Tidak ada partisipan di meeting ini.",
      type: hp.participants.length > 0 ? "array" : "text",
    },
  ];

  return (
    <>
      <div className="column p-5">
        {/* {roomNameProps && <p className="notification is-info">{children}</p>} */}
        {success && (
          <p className="notification is-info mb-3">
            Import ke Zimbra Calendar berhasil
            <br />
            <div className="buttons mt-3">
              <button
                className="button is-small is-link"
                onClick={clearSuccess}
              >
                Ok
              </button>
              <button
                className="button is-small is-danger"
                onClick={openZimbra}
              >
                Buka Zimbra
              </button>
            </div>
          </p>
        )}

        {roomName && loading ? (
          <progress className="progress is-small is-info" max="100"></progress>
        ) : redirect ? (
          <p className="has-text-centered has-text-grey-light">
            {error ? error : "Maaf terjadi kesalahan"}
          </p>
        ) : (
          <>
            {fields.map((field, i) => (
              <div className="field is-horizontal" key={i.toString()}>
                <div className="field-label is-normal">
                  <label className="label">{field.label}</label>
                </div>
                <div className="field-body">
                  <div className="field is-narrow">
                    {field.type === "array" ? (
                      <div className="control">
                        {field.value.map((v, j) => (
                          <p className="mt-2" key={j.toString()}>
                            {renderChecklist(v)}
                          </p>
                        ))}
                      </div>
                    ) : field.type === "component" ? (
                      <>{meeting.isRecuring && field.value}</>
                    ) : (
                      <div className="control">
                        <p className="mt-1">{field.value}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>

      {/* button utk share */}
      <div className="column is-2 p-5" style={{ minWidth: 220 }}>
        <div className="buttons">
          {meetingKeDepan && (
            <>
              {/* outlook calendar */}
              <AddOutlookCalendar
                pathname={location.pathname}
                subject={meeting.title}
                schedule={meeting.schedule}
              />

              {/* google calendar */}
              {test === "true" && (
                <AddGoogleCalendar
                  schedule={meeting.schedule}
                  summary={meeting.title}
                  description={meeting.description}
                />
              )}

              {/* zimbra */}
              {userData.data.source === "CAS" && (
                <AddZimbraCalendar
                  roomName={roomName}
                  schedule={meeting.schedule}
                  setSuccess={setSuccess}
                />
              )}

              {/* download ics file */}
              <ICalendarLink
                event={icsDetail}
                className="button is-fullwidth is-link"
                title="Download File ICS"
              >
                <i className="fas fa-calendar-alt mr-2"></i> Download File Ics
              </ICalendarLink>

              {/* share button */}
              <ShareLink
                meetingDetail={meeting}
                roomName={roomName}
                fullName={userData.data.fullName}
                passwordRoom={meetingPassword}
                shareDetail
              />

              {!meeting.isPublic &&
                (userData.data.role === "Admin" || meeting.isHost) && (
                  <Link
                    title="Ijinkan partisipan masuk"
                    to={`/meeting/ijin/${roomName}/jadwal/${meeting.schedule?.id}`}
                    className="button is-fullwidth is-warning"
                  >
                    <i className="fas fa-user-friends mr-2"></i> Ijinkan
                    Partisipan
                  </Link>
                )}
            </>
          )}

          {meeting && !loading && userData.data.role === "Admin" && (
            <>
              {/* statistik */}
              <Link
                to={`/meeting/statistik/${roomName}/jadwal/${meeting.schedule?.id}`}
                className="button is-fullwidth is-success"
              >
                <i className="fas fa-chart-line mr-2"></i> Statistik
              </Link>

              {/* rekaman / history */}
              <Link
                to={`/meeting/history/${roomName}/jadwal/${meeting.schedule?.id}`}
                className="button is-fullwidth is-danger"
              >
                <i className="fas fa-history mr-2"></i> History
              </Link>

              {/* upload ppt */}
              <Link
                to={`/meeting/file/${roomName}/jadwal/${meeting.schedule?.id}`}
                className="button is-fullwidth is-warning"
              >
                <i className="fas fa-file mr-2"></i> Upload File
              </Link>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const openZimbra = () => window.open(config.REACT_APP_BPPT_MAIL, "_blank");

const mapStateToProps = (state) => {
  return {
    userData: state,
  };
};

export default connect(mapStateToProps)(DetailMeeting);
