const cas = [
  {
    label: "Nama Lengkap",
    id: "fullName",
    type: "text",
    error: "FullName",
  },
  {
    label: "Jenis Kelamin",
    id: "title",
    type: "select",
    options: [
      { value: "Pak", label: "Laki-laki" },
      { value: "Bu", label: "Perempuan" },
    ],
    error: "Title",
  },
  {
    label: "Nomor Telepon",
    id: "phoneNumber",
    type: "text",
    error: "phoneNumber",
  },
  { label: "Email", id: "email", type: "email", error: "Email" },
];

const nonCas = [
  {
    label: "Nama Lengkap",
    id: "fullName",
    type: "text",
    error: "FullName",
  },
  {
    label: "Jenis Kelamin",
    id: "title",
    type: "select",
    options: [
      { value: "Pak", label: "Laki-laki" },
      { value: "Bu", label: "Perempuan" },
    ],
    error: "Title",
  },
  {
    label: "Nomor Telepon",
    id: "phoneNumber",
    type: "text",
    error: "phoneNumber",
  },
  { label: "Email", id: "email", type: "email", error: "Email" },
  {
    label: "Password",
    id: "password",
    type: "password",
    error: "Password",
  },
  {
    label: "Ulangi Password",
    id: "confirmPassword",
    type: "password",
    error: "ConfirmPassword",
  },
];

export { cas, nonCas };
