import React, { useState, useEffect, useReducer } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "../../config/axios";
import RenderMeetings from "./meetings/RenderMeetings";
import TabsMeeting from "./meetings/TabsMeeting";
import ErrorSuccess from "./meetings/ErrorSuccess";
import KotakCari from "./meetings/KotakCari";
import ModalEditMeeting from "./meetings/ModalEditMeeting";
import ReactPaginate from "react-paginate";

const Meetings = ({ location, endPoint, title }) => {
  const userData = useSelector((state) => state);
  const dispatchRedux = useDispatch();
  const history = useHistory(); // utk push halaman pagination di url
  const [query, setQuery] = useState("");
  const [statePesan, dispatchPesan] = useReducer(pesanReducer, initPesan); // atur tampil pesan
  const [meetingLoading, setMeetingLoading] = useState(true); // utk render loading bar
  const pageFromParams = new URLSearchParams(location.search).get("page");
  const [page, setPage] = useState(pageFromParams ? pageFromParams : 1);
  const onPageClick = (e) => {
    const halaman = e.selected + 1;
    history.push("?page=" + halaman);
    setPage(halaman);
    window.scrollTo(0, 0);
  };

  // utk menampilkan pesan add meeting yg sukses
  const clearSuccess = () => dispatchPesan({ type: "RESET_SUKSES" });
  const clearError = () => dispatchPesan({ type: "RESET_GAGAL" });

  // atur pencarian meeting
  const changeCari = (e) => {
    setQuery(e.target.value);
    setPage(1);
  };

  // atur tampil modal utk edit meeting
  const [modalEdit, setModalEdit] = useState({
    className: "modal",
    roomName: "",
    scheduleId: 0,
  });

  const modalEditOn = (e) => {
    setModalEdit({
      className: "modal is-active",
      roomName: e.target.title,
      scheduleId: e.target.id,
    });
  };

  const modalEditOff = () =>
    setModalEdit({
      className: "modal",
    });

  useEffect(() => {
    if (!meetingLoading) {
      setMeetingLoading(true);
      
      // hilangkan smua data meeting dlu (biar loading bar muncul)
      dispatchRedux({
        type: "MEETINGS",
        data: {
          meetings: [],
          totalPage: 0,
        },
      });

      // jika query pencarian berubah
      axios
        .get(
          endPoint ? endPoint + "?query=" + query : "/meeting?query=" + query,
          {
            headers: {
              Authorization: "Bearer " + userData.data.jwtToken,
            },
          }
        )
        .then((res) => {
          console.log(res.data.data);
          const meetings = res.data.data;
          const totalPage = res.data.totalPage;
          dispatchRedux({
            type: "MEETINGS",
            data: {
              meetings,
              totalPage,
            },
          });
          setMeetingLoading(false);
        })
        .catch((err) => {
          setMeetingLoading(false);
          window.scrollTo(0, 0);
          console.log(err);
          if (err.response) {
            err.response.data.message &&
              dispatchPesan({ type: "GAGAL", data: err.response.data.message });
            err.response.data.title &&
              dispatchPesan({ type: "GAGAL", data: err.response.data.title });
          } else {
            dispatchPesan({ type: "GAGAL", data: "Maaf terjadi kesalahan" });
          }
        });
    }
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // ubah title
    document.title = title ? title : "Daftar Meeting - BPPT";
  }, [title]);

  useEffect(() => {
    // hilangkan smua data meeting dlu (biar loading bar muncul)
    dispatchRedux({
      type: "MEETINGS",
      data: {
        meetings: [],
        totalPage: 0,
      },
    });

    // render meeting smua meeting / meeting personal
    axios
      .get(
        endPoint
          ? endPoint + "?page=" + page + "&query=" + query
          : "/meeting/personal?page=" + page + "&query=" + query,
        {
          headers: {
            Authorization: "Bearer " + userData.data.jwtToken,
          },
        }
      )
      .then((res) => {
        console.log("daftar meeting nih", res.data);
        const meetings = sortMeeting(res.data.data);
        const totalPage = res.data.totalPage;
        dispatchRedux({
          type: "MEETINGS",
          data: {
            meetings,
            totalPage,
          },
        });
        setMeetingLoading(false);
      })
      .catch((err) => {
        setMeetingLoading(false);
        window.scrollTo(0, 0);
        console.log(err);
        if (err.response) {
          err.response.data.message &&
            dispatchPesan({ type: "GAGAL", data: err.response.data.message });
          err.response.data.title &&
            dispatchPesan({ type: "GAGAL", data: err.response.data.title });
        } else {
          dispatchPesan({ type: "GAGAL", data: "Maaf terjadi kesalahan" });
        }
      });
  }, [page, statePesan.sukses]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // jika user telah berhasil registrasi
    dispatchPesan({ type: "SUKSES", data: location.state?.pesanSukses });
    window.scrollTo(0, 0);
  }, [location.state?.pesanSukses]);

  useEffect(() => {
    // jika user telah gagal masuk halaman edit krn lewat url lgsg
    dispatchPesan({ type: "GAGAL", data: location.state?.pesanGagal });
  }, [location.state?.pesanGagal]);

  return (
    <div className="column p-5">
      {/* tabs smua meeting & meeting personal */}
      <TabsMeeting
        personal={location.pathname.includes("personal")}
        pathname={location.pathname}
      />

      {/* peringatan error dan success */}
      <ErrorSuccess
        statePesan={statePesan}
        clearError={clearError}
        clearSuccess={clearSuccess}
      />

      {/* kotak pencarian utk mencari meetings */}
      <KotakCari changeCari={changeCari} placeholder="Cari judul meeting" />

      {/* meeting list */}
      {userData.meetingData?.meetings.length > 0 ? (
        <>
          <RenderMeetings
            meetings={userData.meetingData?.meetings}
            userData={userData}
            modalEditOn={modalEditOn}
            dispatchPesan={dispatchPesan}
          />

          <ReactPaginate
            initialPage={page - 1}
            pageCount={userData.meetingData?.totalPage}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            previousLabel="Sebelumnya"
            nextLabel="Selanjutnya"
            containerClassName="pagination is-rounded is-justify-content-start mt-6"
            pageLinkClassName="pagination-link"
            activeLinkClassName="pagination-link is-current"
            previousClassName="pagination-previous"
            nextClassName="pagination-next"
            breakLinkClassName="pagination-ellipsis"
            onPageChange={onPageClick}
            disableInitialCallback
          />
        </>
      ) : meetingLoading ? (
        <progress className="progress is-small is-info" max="100"></progress>
      ) : (
        <p className="has-text-grey-light my-2 mx-2">Belum ada meeting.</p>
      )}

      {/* konfirmasi edit meeting */}
      <ModalEditMeeting
        className={modalEdit.className}
        modalEditOff={modalEditOff}
        roomName={modalEdit.roomName}
        scheduleId={modalEdit.scheduleId}
      />
    </div>
  );
};

export const initPesan = {
  sukses: "",
  error: "",
};

export const pesanReducer = (state = initPesan, action) => {
  switch (action.type) {
    case "SUKSES":
      return {
        ...state,
        sukses: action.data,
      };

    case "GAGAL":
      return {
        ...state,
        error: action.data,
      };

    case "RESET_SUKSES":
      return {
        ...state,
        sukses: "",
      };

    case "RESET_GAGAL":
      return {
        ...state,
        error: "",
      };

    default:
      return state;
  }
};

// sort meeting berdasarkan yg terbaru
export const sortMeeting = (meetings) => {
  return meetings.sort(
    (a, b) => new Date(b.schedule.startDate) - new Date(a.schedule.startDate)
  );
};

export default Meetings;
