const userLinks = [
  {
    title: "Meeting",
    items: [
      {
        title: "Gabung Meeting",
        href: "/",
        icon: (
          <i className="fas fa-file-import mr-3" style={{ marginLeft: -3 }}></i>
        ),
      },
      {
        title: "Meeting Hari Ini",
        href: "/meeting/hari-ini",
        icon: <i className="fas fa-file-alt mr-4"></i>,
      },
      {
        title: "Daftar Meeting",
        href: "/meeting",
        icon: <i className="fas fa-file-alt mr-4"></i>,
      },
      {
        title: "Meeting ke Depan",
        href: "/meeting/besok",
        icon: <i className="fas fa-file-alt mr-4"></i>,
      },
      {
        title: "Meeting yang Lalu",
        href: "/meeting/kemarin",
        icon: <i className="fas fa-file-alt mr-4"></i>,
      },
    ],
  },
  {
    title: "Berita",
    items: [
      {
        title: "Daftar Berita",
        href: "/berita",
        icon: <i className="fas fa-edit mr-3"></i>,
      },
    ],
  },
  {
    title: "Acara",
    items: [
      {
        title: "Daftar Acara",
        href: "/acara",
        icon: <i className="fas fa-calendar-alt mr-4"></i>,
      },
    ],
  },
  {
    title: "Pengaturan",
    items: [
      {
        title: "Edit Profil",
        href: "/pengaturan/profil",
        icon: <i className="fas fa-user mr-4"></i>,
      },
      {
        title: "Edit Avatar",
        href: "/pengaturan/avatar",
        icon: <i className="fas fa-camera mr-4"></i>,
      },
    ],
  },
];

const adminLinks = [
  {
    title: "Meeting",
    items: [
      {
        title: "Gabung Meeting",
        href: "/",
        icon: (
          <i className="fas fa-file-import mr-4" style={{ marginLeft: -3 }}></i>
        ),
      },
      {
        title: "Meeting Hari Ini",
        href: "/meeting/hari-ini",
        icon: <i className="fas fa-file-alt mr-4"></i>,
      },
      {
        title: "Daftar Meeting",
        href: "/meeting",
        icon: <i className="fas fa-file-alt mr-4"></i>,
      },
      {
        title: "Meeting ke Depan",
        href: "/meeting/besok",
        icon: <i className="fas fa-file-alt mr-4"></i>,
      },
      {
        title: "Meeting yang Lalu",
        href: "/meeting/kemarin",
        icon: <i className="fas fa-file-alt mr-4"></i>,
      },
      {
        title: "Tambah Meeting",
        href: "/meeting/tambah",
        icon: <i className="fas fa-file-medical mr-4"></i>,
      },
      {
        title: "Atur Meeting",
        href: "/meeting/atur",
        icon: <i className="fas fa-file-signature mr-3"></i>,
      },
    ],
  },
  {
    title: "Berita",
    items: [
      {
        title: "Daftar Berita",
        href: "/berita",
        icon: <i className="fas fa-edit mr-3"></i>,
      },
      {
        title: "Tambah Berita",
        href: "/berita/tambah",
        icon: <i className="fas fa-plus mr-4"></i>,
      },
    ],
  },
  {
    title: "Acara",
    items: [
      {
        title: "Daftar Acara",
        href: "/acara",
        icon: <i className="fas fa-calendar-alt mr-4"></i>,
      },
      {
        title: "Tambah Acara",
        href: "/acara/tambah",
        icon: <i className="fas fa-calendar-plus mr-4"></i>,
      },
    ],
  },
  {
    title: "User",
    items: [
      {
        title: "Daftar User",
        href: "/user",
        icon: <i className="fas fa-user mr-4"></i>,
      },
      {
        title: "Tambah User",
        href: "/user/tambah",
        icon: <i className="fas fa-user-plus mr-3"></i>,
      },
    ],
  },
  {
    title: "Pengaturan",
    items: [
      {
        title: "Pengingat Email",
        href: "/pengaturan/pengingat",
        icon: <i className="fas fa-clock mr-4"></i>,
      },
      {
        title: "Edit Profil",
        href: "/pengaturan/profil",
        icon: <i className="fas fa-user-cog mr-3"></i>,
      },
      {
        title: "Edit Avatar",
        href: "/pengaturan/avatar",
        icon: <i className="fas fa-camera mr-4"></i>,
      },
    ],
  },
];

const superAdminLinks = [
  {
    title: "Meeting",
    items: [
      {
        title: "Gabung Meeting",
        href: "/",
        icon: (
          <i className="fas fa-file-import mr-4" style={{ marginLeft: -3 }}></i>
        ),
      },
      {
        title: "Semua Meeting",
        href: "/meeting/super-admin",
        icon: <i className="fas fa-file-alt mr-4"></i>,
      },
      {
        title: "Meeting Hari Ini",
        href: "/meeting/hari-ini",
        icon: <i className="fas fa-file-alt mr-4"></i>,
      },
      {
        title: "Daftar Meeting",
        href: "/meeting",
        icon: <i className="fas fa-file-alt mr-4"></i>,
      },
      {
        title: "Meeting ke Depan",
        href: "/meeting/besok",
        icon: <i className="fas fa-file-alt mr-4"></i>,
      },
      {
        title: "Meeting yang Lalu",
        href: "/meeting/kemarin",
        icon: <i className="fas fa-file-alt mr-4"></i>,
      },
      {
        title: "Tambah Meeting",
        href: "/meeting/tambah",
        icon: <i className="fas fa-file-medical mr-4"></i>,
      },
      {
        title: "Atur Meeting",
        href: "/meeting/atur",
        icon: <i className="fas fa-file-signature mr-3"></i>,
      },
    ],
  },
  {
    title: "Berita",
    items: [
      {
        title: "Daftar Berita",
        href: "/berita",
        icon: <i className="fas fa-edit mr-3"></i>,
      },
      {
        title: "Tambah Berita",
        href: "/berita/tambah",
        icon: <i className="fas fa-plus mr-4"></i>,
      },
    ],
  },
  {
    title: "Acara",
    items: [
      {
        title: "Daftar Acara",
        href: "/acara",
        icon: <i className="fas fa-calendar-alt mr-4"></i>,
      },
      {
        title: "Tambah Acara",
        href: "/acara/tambah",
        icon: <i className="fas fa-calendar-plus mr-4"></i>,
      },
    ],
  },
  {
    title: "User",
    items: [
      {
        title: "Daftar User",
        href: "/user",
        icon: <i className="fas fa-user mr-4"></i>,
      },
      {
        title: "Tambah User",
        href: "/user/tambah",
        icon: <i className="fas fa-user-plus mr-3"></i>,
      },
    ],
  },
  {
    title: "Pengaturan",
    items: [
      {
        title: "Pengingat Email",
        href: "/pengaturan/pengingat",
        icon: <i className="fas fa-clock mr-4"></i>,
      },
      {
        title: "Edit Profil",
        href: "/pengaturan/profil",
        icon: <i className="fas fa-user-cog mr-3"></i>,
      },
      {
        title: "Edit Avatar",
        href: "/pengaturan/avatar",
        icon: <i className="fas fa-camera mr-4"></i>,
      },
    ],
  },
];

export { userLinks, adminLinks, superAdminLinks };
