import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import axios from "../../config/axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import formatTanggal2 from "../../helpers/formatTanggal2";
import { sortData } from "../meeting/video/chat-panel/DaftarGambar";
import AddKategori from "../news/AddKategori";
import { useDropzone } from "react-dropzone";

const AddEvent = ({ userData }) => {
  // utk dragndrop
  const [files, setFiles] = useState([]);

  // looping fields
  const [fields, setFields] = useState([
    { label: "Judul", name: "Title", type: "text" },
    {
      label: "Status",
      name: "Status",
      type: "select",
      options: [
        { id: 0, title: "Tampilkan" },
        { id: 1, title: "Jangan tampilkan" },
      ],
    },
    { label: "Kategori", name: "CategoryId", type: "select", options: [] },
    { label: "Pilih gambar", name: "FeaturedImage", type: "file" },
    { label: "Dimulai", name: "StartDate", type: "date" },
    { label: "Berakhir", name: "EndDate", type: "date" },
  ]);
  const setOptions = (option) => {
    const data = fields.map((field) =>
      field.label === "Kategori"
        ? { ...field, options: field.options.concat(option) }
        : field
    );
    const sortedData = sortData(data);
    setFields(sortedData);
  };

  const [editorValue, setEditorValue] = useState("");
  const editorChange = (e, editor) => {
    setEditorValue(editor.getData());
    console.log(editor.getData());
  };

  // pesan error
  const [error, setError] = useState("");
  const [errorFields, setErrorFields] = useState({
    FeaturedImage: [],
  });

  // utk form
  const form = useRef(null);

  // redirect ke daftar berita jika berhasil tambah berita
  const [redirect, setRedirect] = useState(false);

  // disableBtn button & field saat fetch
  const [disableBtn, setDisableBtn] = useState(false);

  // POST ke /api/event
  const addEvent = (e) => {
    // spy tdk refresh halaman saat submit form
    e.preventDefault();

    if (!disableBtn) {
      // disableBtn button saat fetch
      setDisableBtn(true);

      // mulai fetch
      const data = new FormData(form.current);
      data.append("Content", editorValue);
      data.append("FeaturedImage", files[0]);
      axios
        .post("/event", data, {
          headers: {
            Authorization: "Bearer " + userData.data.jwtToken,
          },
        })
        .then(() => setRedirect(true))
        .catch((err) => {
          console.log(err);
          setDisableBtn(false);
          if (err.response) {
            // err title
            err.response.data.message && setError(err.response.data.message);
            err.response.data.title && setError(err.response.data.title);
            // err per field
            err.response.data.errors &&
              setErrorFields(err.response.data.errors);
          } else {
            setError("Maaf terjadi kesalahan");
          }
          window.scrollTo(0, 0);
        });
    }
  };

  useEffect(() => {
    // ubah title
    document.title = "Tambah Acara - BPPT";
  }, []);

  useEffect(() => {
    // get data event category
    axios
      .get("/event-category", {
        headers: {
          Authorization: "Bearer " + userData.data.jwtToken,
        },
      })
      .then((res) => {
        console.log("kategori event nih", res.data.data);
        setOptions(sortData(res.data.data));
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          err.response.data.message && setError(err.response.data.message);
          err.response.data.title && setError(err.response.data.title);
        } else {
          setError("Maaf terjadi kesalahan");
        }
        window.scrollTo(0, 0);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // pindah ke halaman login jika blm login
  if (userData.data.role !== "Admin") {
    return <Redirect to="/meeting/hari-ini" />;
  } else if (redirect) {
    return (
      <Redirect
        to={{
          pathname: "/acara",
          state: { pesanSukses: "Tambah acara berhasil" },
        }}
      />
    );
  }

  return (
    <form ref={form} className="column p-5" onSubmit={addEvent}>
      {/* peringatan error */}
      <div className="my-5">
        {error && <p className="notification is-danger">{error}</p>}
      </div>

      {/* judul */}
      <p className="is-size-3 mb-5">Tambah Acara</p>

      {fields.map((field, i) => (
        <div className="field is-horizontal" key={i.toString()}>
          <div className="field-label is-normal">
            <label className="label">{field.label}</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <RenderInput
                  field={field}
                  files={files}
                  setFiles={setFiles}
                  setErrorFields={setErrorFields}
                />
                {field.label === "Kategori" && (
                  <AddKategori
                    endpoint="/event-category"
                    jwtToken={userData.data.jwtToken}
                    setOptions={setOptions}
                  />
                )}
              </div>

              {/* error per field  */}
              {errorFields[field.name] &&
                errorFields[field.name].map((error, i) => (
                  <small className="help is-danger" key={i.toString()}>
                    {error}
                  </small>
                ))}
            </div>
          </div>
        </div>
      ))}

      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Konten</label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <CKEditor
                editor={ClassicEditor}
                onChange={editorChange}
                config={editorConfiguration}
              />
            </div>

            {/* error per field  */}
            {errorFields.Content &&
              errorFields.Content.map((error, i) => (
                <small className="help is-danger" key={i.toString()}>
                  {error}
                </small>
              ))}
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label">{/* kosong utk kasih jarak */}</div>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <button className="button is-link" disabled={disableBtn}>
                Simpan
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

const editorConfiguration = {
  toolbar: [
    "heading",
    "|",
    "bold",
    "italic",
    "link",
    "bulletedList",
    "numberedList",
    "|",
    "blockQuote",
    "insertTable",
    "undo",
    "redo",
  ],
};

const RenderInput = ({ field, files, setFiles, setErrorFields }) => {
  // utk drandrop
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );

      if (acceptedFiles[0].size > 5242880) {
        setErrorFields((prevErr) => {
          return {
            ...prevErr,
            FeaturedImage: ["Maksimal ukuran gambar 5MB"],
          };
        });
      } else {
        setErrorFields((prevErr) => {
          return {
            ...prevErr,
            FeaturedImage: [],
          };
        });
      }
    },
  });

  const [date, setDate] = useState("");
  const dateChange = (e) => {
    e.target.value
      ? setDate(formatTanggal2(e.target.value))
      : setDate("Belum diisi");
    console.log(e.target.value);
  };

  // destructuring prop field
  const { type, label, name, options } = field;

  // daftar jsx yg bs dipilih sesuai type
  const inputs = {
    text: (
      <input className="input" name={name} type="text" placeholder={label} />
    ),
    select: (
      <div className="select">
        <select name={name}>
          {options?.map((option) => (
            <option value={option.id} key={option.title}>
              {option.title}
            </option>
          ))}
        </select>
      </div>
    ),
    file: (
      <div
        {...getRootProps()}
        style={{
          height: 100,
          border: "2px dashed #ccc",
          color: "#ccc",
          padding: 10,
        }}
      >
        {files.length > 0 ? files[0].name : "Belum ada file"}
        <input {...getInputProps()} style={{ display: "none" }} />
      </div>
    ),
    date: (
      <>
        <label
          htmlFor={name}
          style={{
            position: "absolute",
            width: "80%",
            height: "100%",
            backgroundColor: "#fff",
            zIndex: 2,
            padding: "7px 10px",
            border: "1px solid lightgrey",
            borderRight: "none",
            borderRadius: 5,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          {date ? date : "Belum diisi"}
        </label>
        <input
          className="input"
          name={name}
          id={name}
          type="date"
          style={{
            border: "1px solid lightgrey",
          }}
          onChange={dateChange}
        />
      </>
    ),
  };

  return inputs[type];
};

const mapStateToProps = (state) => {
  return {
    userData: state,
  };
};

export default connect(mapStateToProps)(AddEvent);
