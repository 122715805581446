import React, { useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import SideLogin from "./user/login/SideLogin";
import { config } from "../config";
import { userLinks, adminLinks, superAdminLinks } from "./sidebar/links";

const Sidebar = ({ location, sidebarOn, setSidebarOn }) => {
  const pathname = location.pathname;
  const userData = useSelector((state) => state);
  const dispatch = useDispatch();
  const changeUserData = () => dispatch({ type: "LOGOUT" });

  // jika tombol logout ditekan
  const clearJwt = (e) => {
    e.preventDefault();
    localStorage.removeItem("reduxState");
    changeUserData();
    // jika login pk akun cas
    if (userData.data.source === "CAS") {
      // logout jg di auth.bppt.go.id
      window.location = config.REACT_APP_URL_LOGOUT_CAS;
    }
  };

  const links = userData.data.isSuperAdmin
    ? superAdminLinks
    : userData.data.role === "Admin"
    ? adminLinks
    : userLinks;
  const activeLink = (href) => {
    return href === pathname || href + "/personal" === pathname;
  };

  useEffect(() => {
    setSidebarOn(false);
  }, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {userData.data.jwtToken && (
        <Helmet>
          <link rel="stylesheet" href="/css/bg_default.css" />
        </Helmet>
      )}

      {userData.data.jwtToken &&
      !pathname.includes("/video/") &&
      !pathname.includes("/bels/") &&
      !pathname.includes("/login") ? (
        <div
          className="column is-one-fifth has-text-white sidebarku"
          style={{ ...styles.sidebar, display: sidebarOn ? "block" : "" }}
        >
          <Dots color="#fff" position={{ left: 15, top: 25 }} />

          <div
            className="columns is-centered mt-3"
            style={{ backgroundColor: "inherit" }}
          >
            {/* avatar */}
            <figure className="image is-128x128" style={{ margin: "0 auto" }}>
              <Link to="/pengaturan/profil">
                <img
                  className="is-rounded has-background-white step2"
                  src={
                    userData.data.avatar
                      ? config.REACT_APP_URL_GAMBAR +
                        userData.data.avatar.imageSmall
                      : "/img/placeholder/bppt120x120.png"
                  }
                  alt="user-avatar"
                  style={{ width: 120, height: 120 }}
                />
              </Link>

              <Link
                to="/pengaturan/avatar"
                className="button is-white is-rounded is-small step3"
                style={{ marginTop: -20, width: 30, height: 30 }}
              >
                <i className="fas fa-camera"></i>
              </Link>
            </figure>
          </div>

          <p className="pb-5 has-text-centered">
            Halo, {userData.data.fullName}
          </p>

          <aside className="menu step4">
            <ul className="menu-list">
              {/* looping links */}
              {links.map((link) => (
                <div key={link.title}>
                  <p className="menu-label mt-3 has-text-white">{link.title}</p>
                  {link.items.map((item) => (
                    <li key={item.title}>
                      <Link
                        to={item.href}
                        style={{
                          position: "relative",
                          width: 275,
                          color: activeLink(item.href) ? "#233176" : "#fff",
                          zIndex: 1,
                        }}
                        className={
                          activeLink(item.href)
                            ? "button is-white is-rounded has-text-left"
                            : "button is-link is-rounded has-text-left"
                        }
                      >
                        {activeLink(item.href) ? (
                          <span className="has-text-danger">{item.icon}</span>
                        ) : (
                          item.icon
                        )}{" "}
                        {item.title}
                      </Link>
                      {activeLink(item.href) && (
                        <div style={styles.linkRedBar}></div>
                      )}
                    </li>
                  ))}
                </div>
              ))}
            </ul>
          </aside>

          <aside className="menu">
            <ul className="menu-list">
              <li>
                {/* tombol logout */}
                <a
                  href="/#"
                  className="button is-link is-rounded has-text-left has-text-white"
                  style={{
                    position: "relative",
                    width: 275,
                    color: "#fff",
                    zIndex: 1,
                  }}
                  onClick={clearJwt}
                >
                  <i className="fas fa-sign-out-alt mr-4 has-text-danger"></i>{" "}
                  Logout
                </a>
              </li>
            </ul>
          </aside>

          <Dots color="#384583" position={{ right: 15, bottom: 350 }} />
          <Dots color="#384583" position={{ right: 35, bottom: 330 }} />
          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 290,
              width: 15,
              height: 15,
              backgroundColor: "red",
            }}
          ></div>
          <div
            style={{
              position: "absolute",
              right: 15,
              bottom: 305,
              width: 20,
              height: 20,
              backgroundColor: "#4a5cb6",
            }}
          ></div>
          <img
            src="/img/latest_news.png"
            alt="berita-bppt-terbaru"
            style={{
              position: "relative",
              width: "100%",
              top: 20,
              bottom: 0,
            }}
          />
        </div>
      ) : pathname.includes("/login") ? (
        <SideLogin />
      ) : (
        <div
          className="column is-one-third columns is-vcentered"
          style={{ ...styles.sidebar, margin: 0 }}
        >
          <div className="column">
            <img src="/img/logo_bppt_big.png" alt="logo-bppt-big" />
          </div>
        </div>
      )}
    </>
  );
};

const styles = {
  sidebar: {
    backgroundColor: "#233176",
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    paddingLeft: 20,
    overflow: "hidden",
    position: "relative",
    minWidth: 250,
  },
  linkRedBar: {
    width: 40,
    height: 5,
    backgroundColor: "red",
    marginLeft: "auto",
    marginRight: -12,
  },
};

const Dots = ({ color, position }) => {
  const dotStyles = {
    dot: {
      width: 10,
      height: 10,
      backgroundColor: color,
      borderRadius: "50%",
      marginBottom: 10,
    },
    dots: {
      position: "absolute",
      ...position,
    },
  };

  return (
    <div style={dotStyles.dots}>
      <div style={dotStyles.dot}></div>
      <div style={dotStyles.dot}></div>
      <div style={dotStyles.dot}></div>
      <div style={dotStyles.dot}></div>
      <div style={dotStyles.dot}></div>
      <div style={dotStyles.dot}></div>
    </div>
  );
};

export default withRouter(Sidebar);
