import React, { useEffect, useState } from "react";
import axios from "../../../config/axios";
import { useSelector } from "react-redux";
import formatTanggal from "../../../helpers/formatTanggal";

const InfoSchedule = ({ roomName }) => {
  const userData = useSelector((state) => state);
  const [meetingDetail, setMeetingDetail] = useState([]);

  useEffect(() => {
    axios
      .get("/meeting/" + roomName, {
        headers: {
          Authorization: "Bearer " + userData.data.jwtToken,
        },
      })
      .then((res) => {
        const data = res.data.data;
        console.log("meeting detail", data);
        setMeetingDetail([
          { title: "Tanggal", value: formatTanggal(data.schedule.startDate) },
          { title: "Jam Mulai", value: data.schedule.start },
          { title: "Jam Selesai", value: data.schedule.end },
          {
            title: "Daftar Host",
            value: data.meetingParticipant
              .filter((participant) => participant.role === 0)
              .map((host) => (
                <>
                  <i className="fas fa-user mr-2"></i> {host.author.fullName}
                  <br />
                </>
              )),
          },
        ]);
      })
      .catch((err) => console.log(err));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {meetingDetail.length > 0 && meetingDetail.map((m) => renderField(m))}

      <div className="columns has-text-centered mt-6">
        <div className="column">
          <img src="/img/logo_bppt_big.png" alt="Logo BPPT Besar" />
        </div>
      </div>
    </>
  );
};

const renderField = (obj) => {
  return (
    <div className="columns is-mobile" key={obj.title}>
      <div className="column has-text-right has-text-weight-bold">
        {obj.title}
      </div>
      <div className="column">{obj.value}</div>
    </div>
  );
};

export default InfoSchedule;
