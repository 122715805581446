import React from "react";
import { Link } from "react-router-dom";

const RenderAccount = ({
  title,
  email,
  role,
  img,
  id,
  isVerified,
  isActive,
  modalOn,
}) => {
  return (
    <div className="column p-5">
      <div className="columns p-2 card">
        <div className="column">
          <img
            src={img}
            alt={id}
            width="48px"
            className="is-pulled-left mr-2"
          />
          <p className="title is-4">
            <span className={`tag is-${role === "Admin" ? "link" : "success"}`}>
              {role}
            </span>{" "}
            {!isActive && <span className="tag is-danger">Banned</span>} {title}
          </p>
          <p className="subtitle is-6">
            {email}{" "}
            {isVerified ? (
              <i className="fas fa-check has-text-success mr-2"></i>
            ) : (
              <i className="fas fa-times has-text-danger mr-2"></i>
            )}
          </p>
        </div>

        <div className="column">
          <div className="columns is-mobile p-2">
            <div className="column">
              <Link
                to={"/user/edit/" + id}
                className="button is-link is-rounded is-fullwidth"
              >
                Edit
              </Link>
            </div>
            <div className="column">
              <button
                className="button is-danger is-rounded ml-2 is-fullwidth"
                id={id}
                onClick={modalOn}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenderAccount;
