import React, { useEffect, useState, useReducer } from "react";
import ReactDOM from "react-dom";
import { Redirect, useParams } from "react-router-dom";
import { connect } from "react-redux";
import JoinMeetingPassword from "./JoinMeetingPassword";
import ShareLink from "./video/ShareLink";
import Panels from "./video/Panels";
import { Helmet } from "react-helmet";
import tombolJitsi from "../../config/tombolJitsi";
import { initParticipants, participantsReducer } from "./video/reducer";
import { styles } from "./video/styles";
import Streaming from "./video/Streaming";
import postJoin from "./video/postJoin";
import initializeJitsiHelper from "./video/initializeJitsiHelper";

// render video ny
const VideoMeeting = ({ userData, password, location }) => {
  const { roomName, scheduleId } = useParams(); // dr params
  const [tileView, setTileView] = useState(false);
  const [redirectKick, setRedirectKick] = useState(false);
  const [meetingDetail, setMeetingDetail] = useState({}); // dipakai utk ShareLink
  const [error, setError] = useState(""); // tampilkan error saat mau update isStarted
  const [passwordRoom, setPasswordRoom] = useState(""); // password room
  const [jitsiId, setJitsiId] = useState(null); // jitsi Id
  const [jitsi, setJitsi] = useState(null);
  const [modalMeetingPassword, setModalMeetingPassword] = useState(false);
  const [stateParticipants, dispatchParticipants] = useReducer(
    participantsReducer,
    initParticipants
  ); // participant list yg akan ditampilkan di sebelah kanan

  const jitsiContainerId = "bppt-jitsi";

  // POST /join
  useEffect(() => {
    document.title = roomName + " - BPPT";

    postJoin(
      roomName,
      scheduleId,
      password,
      userData,
      setMeetingDetail,
      initializeJitsi,
      setModalMeetingPassword,
      setError
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // memanggil iframe
  const initializeJitsi = (meetingDetail) => {
    initializeJitsiHelper(
      meetingDetail,
      roomName,
      userData,
      setPasswordRoom,
      tombolJitsi,
      jitsiContainerId,
      dispatchParticipants,
      setJitsiId,
      meetingDetail.meetingType,
      setJitsi,
      setRedirectKick,
      setTileView
    );
  };

  if (error) {
    return (
      <Redirect
        to={{
          pathname: "/meeting/lobi-privat/" + roomName,
          state: { error: error },
        }}
      />
    );
  } else if (redirectKick) {
    return (
      <Redirect
        to={{
          pathname: "/meeting/hari-ini/",
          state: { pesanGagal: "Anda telah dikeluarkan dari meeting" },
        }}
      />
    );
  } else if (modalMeetingPassword) {
    return (
      <JoinMeetingPassword
        display={modalMeetingPassword}
        roomName={roomName}
        userData={userData}
        location={location}
      />
    );
  }

  return ReactDOM.createPortal(
    <>
      <Helmet>
        <link rel="stylesheet" href="/css/video.css" />
      </Helmet>

      {/* video conference */}
      {Object.keys(meetingDetail).length === 0 ? (
        <div id={jitsiContainerId} className="jitsi-div">
          {/* if meetingDetail is an empty object, render loading indicator */}
          <div className="ml-2 mr-4 my-2">
            <progress
              className="progress is-small is-info m-2"
              max="100"
            ></progress>
          </div>
        </div>
      ) : meetingDetail?.isSpeaker ? (
        <div id={jitsiContainerId} className="jitsi-div" />
      ) : (
        <Streaming roomName={roomName} />
      )}

      {/* participant list */}
      <div style={styles.panels} className="panels">
        {meetingDetail.schedule ? (
          <Panels
            stateParticipants={stateParticipants}
            dispatchParticipants={dispatchParticipants}
            roomName={roomName}
            scheduleId={scheduleId}
            meetingDetail={meetingDetail}
            jitsiId={jitsiId}
            jitsi={jitsi}
            tileView={tileView}
            footer={
              <ShareLink
                meetingDetail={meetingDetail}
                roomName={roomName}
                fullName={userData.data.fullName}
                passwordRoom={passwordRoom}
              />
            }
            setMeetingDetail={setMeetingDetail}
            kebutuhanInitializeJitsiHelper={{
              setPasswordRoom,
              jitsiContainerId,
              setJitsiId,
              setJitsi,
              setRedirectKick,
            }}
          />
        ) : (
          <div className="ml-2 mr-4 my-2">
            <progress
              className="progress is-small is-info m-2"
              max="100"
            ></progress>
          </div>
        )}
      </div>
    </>,
    document.getElementById("jitsi-root")
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state,
  };
};

export default connect(mapStateToProps)(VideoMeeting);
