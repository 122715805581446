import { createStore } from "redux";

// init state
const initUser = {
  // data user
  data: {},
  // data signalr chat
  signalRChat: null,
  // data meeting
  meetingData: {
    meetings: [],
    totalPage: 0,
  },
  // url yg dituju sblm login
  referer: "",
  // penentu disable btn di Streaming.js
  disableIjinBicara: false,
  presentationStarted: null,
};

// reducer
const userReducer = (state = initUser, action) => {
  switch (action.type) {
    case "CHANGE_REFERER":
      return { ...state, referer: action.data };

    case "CHANGE_USER_DATA":
      return { ...state, data: action.data };

    case "LOGOUT":
      return initUser;

    case "CHANGE_SIGNALRCHAT_DATA":
      return { ...state, signalRChat: action.data };

    case "MEETINGS":
      return { ...state, meetingData: action.data };

    case "DISABLE_IJIN_BICARA":
      return { ...state, disableIjinBicara: action.data };

    case "CHANGE_PRESENTATION_STATE":
      return { ...state, presentationStarted: action.data };

    default:
      return state;
  }
};

// cek apa user sdh login / blm
const persistedState = localStorage.getItem("reduxState")
  ? JSON.parse(localStorage.getItem("reduxState"))
  : initUser;

// store
const store = createStore(userReducer, persistedState);

// urus local storage
store.subscribe(() => {
  localStorage.setItem("reduxState", JSON.stringify(store.getState()));
});

export default store;
