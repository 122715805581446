import React, { useState, useEffect } from "react";
import VideoPlayer from "react-video-js-player";
import { useSelector, useDispatch } from "react-redux";

const Streaming = ({ roomName }) => {
  window.scrollTo(0, 0);
  const userData = useSelector((state) => state);

  const dispatch = useDispatch();
  const disableIjinBicara = (data) =>
    dispatch({
      type: "DISABLE_IJIN_BICARA",
      data,
    });

  const [onPlay, setOnPlay] = useState(false);

  const ijinBicara = () => {
    userData.signalRChat
      .invoke("RequestSpeaker", roomName, userData.data.id)
      .then(() => {
        console.log("RequestSpeaker succeed");
        disableIjinBicara(true);
      })
      .catch((err) => console.log("RequestSpeaker failed", err));
  };

  const onVideoPlay = () => setOnPlay(true);

  useEffect(() => {
    // wkt pertama x render, aktifkan btn Ijin Bicara
    disableIjinBicara(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="jitsi-div">
      <div
        className={
          onPlay
            ? "video-js vjs-default-skin vjs-16-9 has-background-black"
            : ""
        }
        style={
          onPlay
            ? { position: "absolute", top: 0, left: 0, zIndex: 1, opacity: 0 }
            : {}
        }
      ></div>

      <VideoPlayer
        className="video-js vjs-default-skin vjs-16-9"
        data-setup='{"fluid": true}'
        src={`https://record-conf.bppt.go.id:8081/hls/${roomName}.m3u8`}
        type="application/x-mpegURL"
        autoplay
        preload="auto"
        hideControls={[
          "play",
          "volume",
          "seekbar",
          "timer",
          "playbackrates",
          "fullscreen",
        ]}
        onPlay={onVideoPlay}
      />

      <div
        className="buttons is-justify-content-center"
        style={{
          position: "absolute",
          bottom: 20,
          left: 0,
          width: "100%",
        }}
      >
        <button
          title="Ijin bicara ke Admin / Host"
          className="button is-info is-rounded"
          onClick={ijinBicara}
          disabled={userData.disableIjinBicara}
        >
          <i className="fas fa-hand-paper mr-2"></i> Ijin Bicara
        </button>

        <button
          title="Matikan video call"
          className="button is-danger is-rounded"
          onClick={redirectMeeting}
        >
          <i className="fas fa-power-off mr-2"></i> Matikan
        </button>
      </div>
    </div>
  );
};

const redirectMeeting = () => (window.location = "/meeting/hari-ini");

export default Streaming;
