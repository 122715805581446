import React from "react";
import PanelsTabs from "./PanelsTabs";

const PanelsHeader = ({
  tabs,
  gantiTab,
  blmDibaca,
  meetingDetail,
  jumlahPartisipan,
  jumlahPembicara,
}) => {
  return (
    <div>
      <div className="panel-heading has-background-white">
        <div className="columns is-vcentered">
          <img
            src="/img/bppt42.jpg"
            alt="logo-hut-bppt-ke-42"
            width="70vh"
            style={{ margin: "0 auto" }}
          />
        </div>
      </div>

      {/* tabs */}
      <PanelsTabs
        tabs={tabs}
        gantiTab={gantiTab}
        blmDibaca={blmDibaca}
        meetingDetail={meetingDetail}
        jumlahPartisipan={jumlahPartisipan}
        jumlahPembicara={jumlahPembicara}
      />
    </div>
  );
};

export default PanelsHeader;
