import React, { useState, useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { config } from "../../config";

const BelsLobi = () => {
  const [error, setError] = useState("");
  const { roomName } = useParams();
  const [redirectJitsi, setRedirectJitsi] = useState(false);

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(
        config.REACT_APP_URL_SIGNALR +
          "/hub/external-waiting-room?roomName=" +
          roomName,
        {
          logger: LogLevel.Trace,
        }
      )
      .build();

    newConnection.serverTimeoutInMilliseconds = 1800000;

    newConnection.on("onError", function (message) {
      alert(message);
    });

    newConnection.on("onLog", function (message) {
      console.log(message);
    });

    newConnection.on("unAuthorized", function (message) {
      alert(message);
    });

    newConnection.on("startMeeting", (message) => {
      alert(message);
      setRedirectJitsi(true);
    });

    const callSignalR = () => {
      console.log("call signalr");

      newConnection
        .start()
        .then(() => {
          console.log("signalR chat started");
        })
        .catch((err) => {
          window.scrollTo(0, 0);
          console.log(err);
          if (err.response) {
            err.response.data.message && setError(err.response.data.message);
            err.response.data.title && setError(err.response.data.title);
          } else {
            setError("Maaf terjadi kesalahan");
          }
          setTimeout(callSignalR, 5000);
        });
    };

    const loop = () => {
      console.log("call loop");
      setTimeout(callSignalR, 5000);
    };

    newConnection.onclose(loop);

    callSignalR();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // cm user yg sdh login yg boleh akses halaman ini
  if (redirectJitsi) {
    return <Redirect to={"/bels/video/" + roomName} />;
  }

  return (
    <div className="column p-5">
      {error ? error : `Meeting ${roomName} belum dimulai, silahkan tunggu.`}
      <br />
      Pemberitahuan (popup alert) akan muncul jika meeting sudah dimulai.
    </div>
  );
};

export default BelsLobi;
