import React, { useEffect, useState } from "react";
import { useParams, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "../../config/axios";
import VideoHistory from "./history/VideoHistory";
import ImageHistory from "./history/ImageHistory";
import FileHistory from "./history/FileHistory";
import ChatHistory from "./history/ChatHistory";

const History = () => {
  const { roomName, scheduleId } = useParams();
  const userData = useSelector((state) => state);
  const [dataApi, setDataApi] = useState({
    chats: [],
    files: [],
    images: [],
    videos: [],
  });

  useEffect(() => {
    document.title = `History ${roomName} - BPPT`;
  }, [roomName]);

  useEffect(() => {
    axios
      .get(`/meeting/${roomName}/history/${scheduleId}`, {
        headers: {
          Authorization: "Bearer " + userData.data.jwtToken,
        },
      })
      .then((res) => {
        const data = res.data.data;
        console.log("fetch history succeed", data);
        const chats = data.chatLog;
        const files = data.attachment;
        const images = data.image;
        const recordings = data.recording;
        setDataApi({
          chats,
          files,
          images,
          videos:
            recordings.length > 0
              ? recordings.map((record) => {
                  return {
                    ...record,
                    fileName: record.urlPath.split("/").reverse()[0],
                  };
                })
              : [],
        });
      })
      .catch((err) => console.log("fetch history failed", err));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // pindah ke halaman login jika bkn admin
  if (userData.data.role !== "Admin") {
    return <Redirect to="/meeting?page=1" />;
  }

  return (
    <div className="column p-5">
      <p className="is-size-3 mb-5">History {roomName}</p>
      {dataApi.chats.length === 0 &&
        dataApi.files.length === 0 &&
        dataApi.images.length === 0 &&
        dataApi.videos.length === 0 && (
          <p className="has-text-grey-light">Belum ada history</p>
        )}

      {dataApi.videos.length > 0 &&
        dataApi.videos.map((video) => (
          <VideoHistory video={video} key={video.fileName} />
        ))}
      {dataApi.images.length > 0 && <ImageHistory images={dataApi.images} />}
      {dataApi.files.length > 0 && <FileHistory files={dataApi.files} />}
      {dataApi.chats.length > 0 && <ChatHistory chats={dataApi.chats} />}
    </div>
  );
};

export default History;
