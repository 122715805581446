import React from "react";
import formatTanggal from "../../../helpers/formatTanggal";
import FormFormHelper from "./FormFormHelper";

// form di dalam form
const FormForm = ({
  interval,
  intervalValue,
  occurrenceValues,
  endType,
  endValue,
}) => {
  const berulangBerdasar = ["Hari", "Minggu", "Bulan"];
  const days = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"];
  const haris =
    occurrenceValues &&
    occurrenceValues.split(",").map((hari) => days[parseInt(hari)]);

  return (
    <>
      {/* setiap */}
      {interval === 2 ? (
        <FormFormHelper label="Setiap Tanggal">
          <p className="mt-1">{intervalValue}</p>
        </FormFormHelper>
      ) : (
        <FormFormHelper label="Berulang Setiap">
          <p className="mt-1">
            {intervalValue} {berulangBerdasar[interval]}
          </p>
        </FormFormHelper>
      )}

      {/* tampilkan hari jika interval === 1 */}
      {interval === 1 && (
        <FormFormHelper label="Hari">
          {/* looping hari */}
          {haris ? (
            haris.map((hari) => (
              <span key={hari}>
                <i className="fas fa-check has-text-success mr-2"></i>
                <i className="mr-2">{hari}</i>
              </span>
            ))
          ) : (
            <p>-</p>
          )}
        </FormFormHelper>
      )}

      {/* berakhir pada / berakhir setelah */}
      <FormFormHelper
        label={endType === 0 ? "Berakhir pada" : "Berakhir setelah"}
      >
        <p className="mt-1">
          {endType === 0 ? formatTanggal(endValue) : endValue}{" "}
          {endType === 1 && "Pertemuan"}
        </p>
      </FormFormHelper>
    </>
  );
};

export default FormForm;
