import React, { useState } from "react";

const DotIcon = ({
  chatku,
  recipientId,
  recipientName,
  displayRecipientId,
  displayRecipientName,
}) => {
  const [chatMenuOn, setChatMenuOn] = useState(false);
  const toggleChatMenu = () => setChatMenuOn((prevChatMenu) => !prevChatMenu);
  const dropdownItemClick = (e) => {
    e.preventDefault();
    displayRecipientId(recipientId);
    displayRecipientName(recipientName);
    toggleChatMenu();

    console.log("recipientId", recipientId);
    console.log("recipientName", recipientName);
  };

  return (
    <div className={chatMenuOn ? "dropdown is-active" : "dropdown"}>
      <div className={chatku ? "is-hidden" : "dropdown-trigger"}>
        <span
          className="icon is-small is-clickable ml-2"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onClick={toggleChatMenu}
        >
          <i className="fas fa-ellipsis-v" aria-hidden="true"></i>
        </span>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div
          className="dropdown-content"
          style={{ position: "absolute", left: 10, top: 10, width: 200 }}
        >
          <a href="/#" className="dropdown-item" onClick={dropdownItemClick}>
            Kirim pesan privat
          </a>
        </div>
      </div>
    </div>
  );
};

export default DotIcon;
