import React from "react";

const ModalDelete = ({ display, disableModalBtn, modalOff, deleteUser }) => {
  return (
    <div className={display}>
      <div
        className="modal-background"
        onClick={modalOff}
        style={{ opacity: 0.6 }}
      ></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Info</p>
          <button
            className="delete"
            aria-label="close"
            onClick={modalOff}
            disabled={disableModalBtn}
          ></button>
        </header>
        <section className="modal-card-body">
          Anda yakin ingin menghapus user?
        </section>
        <footer className="modal-card-foot">
          <button
            className="button is-danger"
            onClick={deleteUser}
            disabled={disableModalBtn}
          >
            Hapus User
          </button>
          <button
            className="button"
            onClick={modalOff}
            disabled={disableModalBtn}
          >
            Batal
          </button>
        </footer>
      </div>
    </div>
  );
};

export default ModalDelete;
