import React from "react";
import Meetings from "./Meetings";

const MeetingHariIniPersonal = ({ location }) => {
  return (
    <Meetings
      location={location}
      endPoint="/meeting/today-personal"
      title="Meeting Hari Ini (Personal) - BPPT"
    />
  );
};

export default MeetingHariIniPersonal;
