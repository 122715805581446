import React from "react";
import axios from "../../../../config/axios";

const CustomToast = ({ roomName, participantId, userObj, jwtToken }) => {
  const allowParticipant = () => {
    axios
      .put(
        `/meeting/${roomName}/participant/${participantId}`,
        {
          id: participantId,
          userId: userObj.id,
          role: 1,
          isBanned: false,
          isPending: false,
        },
        {
          headers: {
            Authorization: "Bearer " + jwtToken,
          },
        }
      )
      .then((res) => console.log("allow participate succeed", res))
      .catch((err) => {
        console.log("allow participate failed", err);
        window.scrollTo(0, 0);
      });
  };

  const deleteParticipant = () => {
    axios
      .delete(`/meeting/${roomName}/participant/${participantId}`, {
        headers: {
          Authorization: "Bearer " + jwtToken,
        },
      })
      .then((res) => console.log("delete participate succeed", res))
      .catch((err) => {
        console.log("delete participate failed", err);
        window.scrollTo(0, 0);
      });
  };

  return (
    <div>
      <p className="mb-2">{userObj.fullName} meminta ijin masuk</p>
      <button
        className="button is-small is-link mr-2"
        type="button"
        onClick={allowParticipant}
      >
        Ijinkan
      </button>
      <button
        className="button is-small is-danger"
        type="button"
        onClick={deleteParticipant}
      >
        Tidak Ijinkan
      </button>
    </div>
  );
};

export default CustomToast;
