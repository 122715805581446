import React, { useState, useEffect } from "react";
import axios from "../../../config/axios";
import { Link } from "react-router-dom";
import { config } from "../../../config";
import dayjs from "dayjs";

// render button lain jika berhak
const RenderButtons = ({ meeting, userData, dispatchPesan }) => {
  const { isPublic, isHost, isParticipated, roomName, schedule } = meeting;
  const { role, jwtToken } = userData.data;

  // penentu render tombol mulai
  const [mulaiBtn, setMulaiBtn] = useState({
    isHost: false,
    isPublic: false,
    tampil: false,
  });

  // penentu render tombol daftar
  const [daftarBtn, setDaftarBtn] = useState(false);

  // penentu render tombol gabung
  const [gabungBtn, setGabungBtn] = useState({
    tampil: false,
    isHost: false,
    isPublic: false,
    isParticipated: false,
  });

  // penentu render tombol batal
  const [batalBtn, setBatalBtn] = useState(false);

  const isAdmin = role === "Admin";
  // menentukan tahun, bulan, tanggal
  // diambil dr date berbentuk ISOString yg jam & menitny blm akurat
  const tanggalMeeting = new Date(schedule.startDate);
  const tahun = tanggalMeeting.getFullYear();
  const bulan = tanggalMeeting.getMonth();
  const tanggal = tanggalMeeting.getDate();
  const tanggalTanpaJam = new Date(tahun, bulan, tanggal);

  // manipulasi jam & menit meeting spy tau kapan berlangsungnya
  const jamMenitEnd = schedule.end.split(":");
  const jamEnd = parseInt(jamMenitEnd[0]);
  const menitEnd = parseInt(jamMenitEnd[1]);

  const jamMenitStart = schedule.start.split(":");
  const jamStart = parseInt(jamMenitStart[0]);
  const menitStart = parseInt(jamMenitStart[1]);

  // 15 menit sblm meeting dimulai
  const isSoon =
    new Date() >
    new Date(tanggalTanpaJam).setHours(jamStart) + menitStart - 900000;

  // meeting sedang berlangsung
  const isHappening =
    new Date() < new Date(tanggalTanpaJam).setHours(jamEnd) + menitEnd * 60000;

  // meeting kmrn tdk boleh muncul button apapun
  const meetingKeDepan = isMeetingKeDepan(schedule);

  useEffect(() => {
    // render tombol Mulai jika user termasuk host / pembuat meeting
    if (isAdmin || isHost) {
      setMulaiBtn({
        tampil: true,
        isHost,
        isPublic,
      });
    }

    // render tombol Daftar jika bkn admin && bkn host && bkn participant
    if (!isAdmin && !isHost && !isParticipated) {
      setDaftarBtn(true);
    }

    // render tombol Gabung jika
    // termasuk dlm participant meeting / termasuk host meeting / jika room tsb public
    // dan 15 menit sblm meeting dimulai
    if (
      (isParticipated || isHost || isPublic) &&
      ((isSoon && isHappening) || schedule.isStarted)
    ) {
      setGabungBtn({
        tampil: true,
        isHost,
        isPublic,
      });
    }

    if (isParticipated && !isAdmin) {
      setBatalBtn(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // jika user klik tombol batal
  const unparticipateMeeting = () => {
    const btnConfig = {
      method: "delete",
      url: `${config.REACT_APP_URL_API}/meeting/${roomName}/participate`,
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    };

    axios(btnConfig)
      .then(() => {
        !isAdmin && setDaftarBtn(true);
        setBatalBtn(false);
        setMulaiBtn({
          tampil: false,
        });

        if (
          (isParticipated || isHost || isPublic) &&
          ((isSoon && isHappening) || schedule.isStarted)
        ) {
          setGabungBtn({
            tampil: true,
            isHost,
            isPublic,
          });
        } else {
          setGabungBtn({
            tampil: false,
            isHost,
            isPublic,
          });
        }
      })
      .catch((err) => {
        window.scrollTo(0, 0);
        console.log(err);
        if (err.response) {
          err.response.data.message &&
            dispatchPesan({ type: "GAGAL", data: err.response.data.message });
          err.response.data.title &&
            dispatchPesan({ type: "GAGAL", data: err.response.data.title });
        } else {
          dispatchPesan({ type: "GAGAL", data: "Maaf terjadi kesalahan" });
        }
      });
  };

  // jika user klik tombol daftar
  const participateMeeting = () => {
    axios
      .post(
        `${config.REACT_APP_URL_API}/meeting/${roomName}/participate`,
        null,
        {
          headers: {
            Authorization: "Bearer " + jwtToken,
          },
        }
      )
      .then((res) => {
        console.log("daftar diklik", res);
        setDaftarBtn(false);
        setBatalBtn(true);

        if (
          (isParticipated || isHost || isPublic) &&
          ((isSoon && isHappening) || schedule.isStarted)
        ) {
          setGabungBtn({
            tampil: true,
            isHost,
            isPublic,
          });
        }
      })
      .catch((err) => {
        window.scrollTo(0, 0);
        console.log(err);
        if (err.response) {
          err.response.data.message &&
            dispatchPesan({ type: "GAGAL", data: err.response.data.message });
          err.response.data.title &&
            dispatchPesan({ type: "GAGAL", data: err.response.data.title });
        } else {
          dispatchPesan({ type: "GAGAL", data: "Maaf terjadi kesalahan" });
        }
      });
  };

  const scrollTop = () => window.scrollTo(0, 0);

  return meetingKeDepan ? (
    <>
      {/* render tombol Mulai jika user termasuk host / pembuat meeting */}
      {mulaiBtn.tampil && (
        <div className="column">
          <Link
            to={"/meeting/video/" + roomName + "/jadwal/" + schedule.id}
            className="button is-danger is-rounded is-fullwidth is-outlined"
            onClick={scrollTop}
          >
            Mulai
          </Link>
        </div>
      )}

      {/* render tombol Daftar jika bkn admin && bkn host && bkn participant */}
      {daftarBtn && (
        <div className="column">
          <button
            className="button is-success is-rounded is-fullwidth"
            onClick={participateMeeting}
          >
            Daftar
          </button>
        </div>
      )}

      {/* render tombol Gabung jika berhak */}
      {gabungBtn.tampil && (
        <div className="column">
          <Link
            to={"/meeting/video/" + roomName + "/jadwal/" + schedule.id}
            className="button is-link is-rounded is-fullwidth"
            onClick={scrollTop}
          >
            Gabung
          </Link>
        </div>
      )}

      {/* render tombol Batal utk unsubscribe meeting */}
      {batalBtn && (
        <div className="column">
          <button
            className="button is-danger is-rounded is-fullwidth"
            onClick={unparticipateMeeting}
          >
            Batal
          </button>
        </div>
      )}
    </>
  ) : (
    <div className="has-background-white"></div>
  );
};

export const isMeetingKeDepan = (schedule) => {
  const tglMeeting = dayjs(schedule.startDate)
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)
    .toDate()
    .valueOf();

  const tglHariIni = dayjs()
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)
    .toDate()
    .valueOf();

  const meetingKeDepan = tglMeeting >= tglHariIni;
  return meetingKeDepan;
};

export default RenderButtons;
