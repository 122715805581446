import React from "react";
import { Link } from "react-router-dom";

const TabsMeeting = ({ personal, pathname }) => {
  return (
    <div
      className="tabs is-fullwidth columns"
      style={{
        backgroundColor: "inherit",
        marginTop: -75,
        marginRight: 5,
      }}
    >
      <div className="column"></div>
      <ul className="column is-half" style={{ borderBottom: "none" }}>
        {/* <li
          className={!personal ? "is-active" : ""}
          style={{
            backgroundColor: !personal ? "#fff" : "inherit",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          }}
        >
          <Link
            to={
              !personal ? pathname : pathname.substring(0, pathname.length - 9)
            }
            style={{ borderBottom: "none" }}
          >
            <i className="fas fa-file-alt mr-3 has-text-danger"></i> Daftar
            Meeting
          </Link>
        </li> */}
        <li
          className="is-active"
          style={{
            backgroundColor: "#fff",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          }}
        >
          <Link to={pathname} style={{ borderBottom: "none" }}>
            <i className="fas fa-user mr-3 has-text-danger"></i> Meeting Anda
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default TabsMeeting;
