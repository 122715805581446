import React from "react";
import Meetings from "./Meetings";

const MeetingBesokPersonal = ({ location }) => {
  return (
    <Meetings
      location={location}
      endPoint="/meeting/upcoming-personal"
      title="Meeting ke Depan (Personal) - BPPT"
    />
  );
};

export default MeetingBesokPersonal;
