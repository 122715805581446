import React, { useState, useEffect } from "react";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { connect } from "react-redux";
import { formatTglJam } from "../meeting/statistik/helper";
import { config } from "../../config";
import dayjs from "dayjs";

const Notif = ({ userData }) => {
  const [signalRConn, setSignalRConn] = useState(null);
  const [notifs, setNotifs] = useState([]);
  const [notifOn, setNotifOn] = useState(false);
  const [newNotif, setNewNotif] = useState(false);

  const notifClick = (e) => {
    e.preventDefault();
    setNotifOn((prevNotif) => !prevNotif);
    setNewNotif(false);
    signalRConn &&
      signalRConn
        .invoke("SetNotificationSeen")
        .then(() => console.log("user membaca notifikasi"))
        .catch((err) => console.log("user gagal membaca notifikasi", err));
  };

  useEffect(() => {
    let unmounted = 0;
    const connection = new HubConnectionBuilder()
      .withUrl(
        config.REACT_APP_URL_SIGNALR +
          "/hub/notification?access_token=" +
          userData.data.jwtToken,
        //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEiLCJyb29tIjoiKiIsIm5iZiI6MTYwNjM3OTAxMSwiZXhwIjoxNjA2OTgzODExLCJpYXQiOjE2MDYzNzkwMTF9.0JIyjGHeCIrRfSxoTXEnMGz6Yqrd23fHEw87FACWxjk",
        {
          logger: LogLevel.Trace,
        }
      )
      .build();

    connection.serverTimeoutInMilliseconds = 1800000;

    connection.on("onLog", function (message) {
      console.log(message);
    });

    connection.on("newNotification", function (message) {
      console.log("New notification!", message);

      if (message.length > 0) {
        // jika message = array
        setNotifs(message);
        if (message.find((m) => !m.seenAt)) {
          setNewNotif(true);
        }
      } else {
        // jika message = obj
        setNotifs((prevNotifs) => {
          const { created, ...propMessage } = message;
          return [
            {
              ...propMessage,
              created: dayjs(created).add(-7, "hour"),
            },
            ...prevNotifs,
          ];
        });
        if (!message.seenAt) {
          setNewNotif(true);
        }
      }
    });

    const callSignalR = () => {
      unmounted = 0;
      console.log("call signalr notif");

      connection
        .start()
        .then(() => {
          console.log("signalR notif started");
          setSignalRConn(connection);
        })
        .catch((err) => {
          console.log("signalR notif error", err);
          setTimeout(callSignalR, 30000);
        });
    };

    const loop = () => {
      console.log("call loop signalr notif");
      setTimeout(callSignalR, 30000);
    };

    connection.onclose(() => {
      if (unmounted === 0) {
        console.log("onclose signalr notif");
        loop();
      }
    });

    callSignalR();

    return () => {
      unmounted = 1;
      console.log("signalr notif unmounted");
      connection.stop();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={notifOn ? "dropdown is-active" : "dropdown"}>
      <div className="dropdown-trigger">
        <a
          href="/#"
          className="has-text-white mr-5 mb-3 step1"
          onClick={notifClick}
        >
          {/* tanda merah2 */}
          {newNotif && (
            <span className="icon has-text-danger">
              <i className="fas fa-info-circle"></i>
            </span>
          )}

          <i className="fas fa-bell"></i>
        </a>
      </div>
      <div className="dropdown-menu" id="dropdown-menu2" role="menu">
        <div
          className="dropdown-content"
          style={{
            width: 300,
            height: 500,
            overflowY: "scroll",
            position: "absolute",
            right: 10,
          }}
        >
          {notifs &&
            notifs.map((notif) => (
              <div key={notif.meetingRoom.roomName + notif.id}>
                <div className="dropdown-item">
                  <p>
                    {!notif.seenAt && (
                      <span className="tag is-danger mr-2">Belum dibaca</span>
                    )}
                    Meeting <strong>{notif.meetingRoom.title}</strong> sedang
                    berlangsung!
                    <br />
                    <i className="is-size-7 has-text-grey-dark">Kode :</i>
                    <br />
                    <code>{notif.meetingRoom.roomName}</code>
                  </p>
                  <p className="is-size-7 has-text-grey-light mt-2">
                    {formatTglJam(notif.created)}
                  </p>
                </div>
                <hr className="dropdown-divider" />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state,
  };
};

export default connect(mapStateToProps)(Notif);
