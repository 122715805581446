import React, { useEffect, useState, useReducer } from "react";
import { Redirect, useParams, Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "../../config/axios";
import formatTanggal from "../../helpers/formatTanggal";
import ReactHtmlParser from "react-html-parser";
import { config } from "../../config";

const DetailEvent = ({ userData }) => {
  const [error, setError] = useState("");

  // redirect ke /acara jika berhasil hapus acara
  const [redirectDelete, setRedirectDelete] = useState(false);

  // useReducer utk menangani modal
  const [stateModal, dispatchModal] = useReducer(modalReducer, initModal);

  // menampilkan modal konfirmasi hapus event
  const konfirmasiHapus = (e) => {
    e.preventDefault(); // spy ga pindah halaman
    dispatchModal({ type: "MODAL_AKTIF" });
  };

  // hapus Event
  const deleteEvent = () => {
    if (!stateModal.disableModalButton) {
      // disable modal button saat fetch
      dispatchModal({ type: "DISABLE_MODAL_BUTTON" });

      // mulai fetch
      axios
        .delete("/event/" + event.slug, {
          headers: {
            Authorization: "Bearer " + userData.data.jwtToken,
          },
        })
        .then(() => setRedirectDelete(true))
        .catch((err) => {
          console.log(err);
          dispatchModal({ type: "ENABLE_MODAL_BUTTON" });
          setRedirectSlug(true);
          if (err.response) {
            err.response.data.message && setError(err.response.data.message);
            err.response.data.title && setError(err.response.data.title);
          } else {
            setError("Maaf terjadi kesalahan");
          }
          window.scrollTo(0, 0);
        });
    }
  };

  // ambil slug dr param
  const { slug } = useParams();

  // redirect jika slug tdk ditemukan di db
  const [redirectSlug, setRedirectSlug] = useState(false);

  // menampilkan loading saat data meeting sedang di fetch
  const [loading, setLoading] = useState(true);

  // data event
  const [event, setEvent] = useState({});

  useEffect(() => {
    // ubah title dg judul event
    document.title = `${event.title ? event.title : "Detail Event"} - BPPT`;

    // fetch data event
    if (slug) {
      axios
        .get("/event/" + slug, {
          headers: {
            Authorization: "Bearer " + userData.data.jwtToken,
          },
        })
        .then((res) => {
          setEvent(res.data.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          // pindah halaman jika roomName tidak ada di db / error lainnya
          setRedirectSlug(true);
          setLoading(false);
          window.scrollTo(0, 0);
          if (err.response) {
            err.response.data.message && setError(err.response.data.message);
            err.response.data.title && setError(err.response.data.title);
          } else {
            setError("Maaf terjadi kesalahan");
          }
        });
    }
  }, [slug, userData.data.jwtToken, event.title]);

  // cm user yg sdh login yg boleh akses halaman ini
  if (redirectDelete) {
    return (
      <Redirect
        to={{
          pathname: "/acara",
          state: { pesanSukses: "Hapus acara berhasil" },
        }}
      />
    );
  }

  return (
    <>
      <div className="column">
        {slug && loading ? (
          <progress className="progress is-small is-info" max="100"></progress>
        ) : redirectSlug ? (
          <p className="has-text-centered has-text-grey-light">
            {error ? error : "Maaf terjadi kesalahan"}
          </p>
        ) : (
          <div className="card">
            <div className="card-image">
              <figure className="image">
                <img
                  src={
                    event.featuredImage
                      ? config.REACT_APP_URL_GAMBAR +
                        event.featuredImage.imageDefault
                      : "/img/placeholder/bppt800x800.png"
                  }
                  alt={event.slug}
                />
              </figure>
            </div>
            <div className="card-content">
              <div className="media">
                <div className="media-content">
                  <p className="title is-4">{event.title}</p>
                  <p className="subtitle is-6">{event.author.fullName}</p>
                </div>
              </div>

              <div className="content">
                {ReactHtmlParser(event.content)}
                <br />
                <p className="has-text-grey-light">
                  Dilaksanakan pada {formatTanggal(event.startDate)}{" "}
                  {event.endDate && "- " + formatTanggal(event.endDate)}
                </p>
              </div>

              {/* tampilkan tombol edit & delete jika admin */}
              {userData.data.role === "Admin" && (
                <footer className="card-footer">
                  <Link
                    to={"/acara/edit/" + event.slug}
                    className="card-footer-item"
                  >
                    Edit Acara
                  </Link>
                  <button
                    className="button is-white card-footer-item has-text-danger"
                    onClick={konfirmasiHapus}
                  >
                    Hapus Acara
                  </button>
                </footer>
              )}
            </div>
          </div>
        )}
      </div>

      {/* modal konfirmasi hapus event */}
      <ModalDelete
        deleteEvent={deleteEvent}
        stateModal={stateModal}
        dispatchModal={dispatchModal}
      />
    </>
  );
};

// utk useReducer
const initModal = {
  modalClass: "modal",
  disableModalButton: false,
};

// utk useReducer
const modalReducer = (state = initModal, action) => {
  switch (action.type) {
    case "MODAL_AKTIF":
      return {
        ...initModal,
        modalClass: "modal is-active",
      };

    case "MODAL_MATI":
      return {
        ...initModal,
        modalClass: "modal",
      };

    case "DISABLE_MODAL_BUTTON":
      return {
        ...initModal,
        disableModalButton: true,
      };

    case "ENABLE_MODAL_BUTTON":
      return {
        ...initModal,
        disableModalButton: false,
      };

    default:
      return state;
  }
};

// modal konfirmasi hapus event
const ModalDelete = ({ deleteEvent, stateModal, dispatchModal }) => {
  const matikanModal = () => dispatchModal({ type: "MODAL_MATI" });

  return (
    <div className={stateModal.modalClass}>
      <div
        className="modal-background"
        onClick={matikanModal}
        style={{ opacity: 0.6 }}
      ></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Info</p>
          <button
            className="delete"
            aria-label="close"
            onClick={matikanModal}
            disabled={stateModal.disableModalButton}
          ></button>
        </header>
        <section className="modal-card-body">
          Anda yakin ingin menghapus acara?
        </section>
        <footer className="modal-card-foot">
          <button
            className="button is-danger"
            onClick={deleteEvent}
            disabled={stateModal.disableModalButton}
          >
            Hapus Acara
          </button>
          <button
            className="button"
            onClick={matikanModal}
            disabled={stateModal.disableModalButton}
          >
            Batal
          </button>
        </footer>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state,
  };
};

export default connect(mapStateToProps)(DetailEvent);
