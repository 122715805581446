import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { config } from "../config";
import { loadJitsiScript } from "./meeting/video/loadJitsiScript";

const jitsiContainerId = "test-jitsi";

const TestMeeting0 = () => {
  useEffect(() => {
    initializeJitsi();
  }, []);

  return ReactDOM.createPortal(
    <div id={jitsiContainerId} style={styles.jitsiDiv} />,
    document.getElementById("jitsi-root")
  );
};

const styles = {
  jitsiDiv: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 99,
    backgroundColor: "darkgrey",
  },
};

const initializeJitsi = async () => {
  if (!window.JitsiMeetExternalAPI) {
    await loadJitsiScript();
  }

  return new window.JitsiMeetExternalAPI(config.REACT_APP_URL_JITSI_NO_HTTP, {
    roomName: "testmeet0",
    width: "100%",
    height: "100%",
    userInfo: {
      displayName: Math.random().toString(36).substring(7),
    },
    configOverwrite: {
      requireDisplayName: false,
      startWithVideoMuted: true,
      startWithAudioMuted: true,
    },
    parentNode: document.getElementById(jitsiContainerId),
  });
};

export default TestMeeting0;
