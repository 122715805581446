import React from "react";
import Meetings from "./Meetings";

const MeetingKemarin = ({ location }) => {
  return (
    <Meetings
      location={location}
      endPoint="/meeting/previous-personal"
      title="Meeting yang Lalu - BPPT"
    />
  );
};

export default MeetingKemarin;
