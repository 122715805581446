import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Tour from "reactour";
import axios from "./config/axios";

const InteractiveGuideku = () => {
  const userData = useSelector((state) => state);
  const dispatch = useDispatch();
  const changeUserData = () =>
    dispatch({
      type: "CHANGE_USER_DATA",
      data: {
        ...userData.data,
        isTutorialDone: true,
      },
    });

  const [isTourOpen, setIsTourOpen] = useState(false);
  const tourOn = () => setIsTourOpen(true);
  const tourOff = () => {
    setIsTourOpen(false);
    axios
      .post("/accounts/finish-tutorial", null, {
        headers: {
          Authorization: "Bearer " + userData.data.jwtToken,
        },
      })
      .then(() => {
        console.log("berhasil finish tutorial");
        changeUserData();
      })
      .catch(() => console.log("gagal finish tutorial"));
  };

  useEffect(() => {
    if (!userData.data.isTutorialDone && window.screen.width > 1023) {
      tourOn();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {/* <button onClick={tourOn}>Boom</button> */}
      <Tour steps={steps} isOpen={isTourOpen} onRequestClose={tourOff} />
    </div>
  );
};

const steps = [
  {
    selector: ".step1",
    content:
      "Ini tombol untuk notifikasi, berisi pemberitahuan meeting yang dimulai dan lainnya",
  },
  {
    selector: ".step2",
    content: "Edit profil disini",
  },
  {
    selector: ".step3",
    content: "Klik untuk ganti foto profil",
  },
  {
    selector: ".step4",
    content: "Daftar menu di Sidebar",
  },
];

export default InteractiveGuideku;
