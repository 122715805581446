const tombolJitsi = [
  "microphone",
  "camera",
  "closedcaptions",
  "desktop",
  // "embedmeeting",
  "fullscreen",
  "fodeviceselection",
  "hangup",
  "profile",
  "recording",
  "livestreaming",
  "etherpad",
  "sharedvideo",
  "settings",
  "raisehand",
  "videoquality",
  "filmstrip",
  // "invite",
  "feedback",
  "stats",
  "shortcuts",
  // "tileview",
  "videobackgroundblur",
  "download",
  "help",
  "mute-everyone",
  "security",
];

export default tombolJitsi;
