import React from "react";
import Streaming from "./Streaming";
import InfoDanDownload from "./InfoDanDownload";

const VideoHistory = ({ video }) => {
  return (
    <div className="columns is-vcentered card">
      <div className="column is-half">
        {/* gambar play button */}
        <img
          src="/img/placeholder/bppt120x120.png"
          alt="logo-bppt-kecil"
          className="is-pulled-left mr-4"
          width="48px"
        />

        {/* info video dan link download */}
        <InfoDanDownload video={video} />
      </div>

      {/* button utk streaming video & audio */}
      <Streaming urlPath={video.urlPath} />
    </div>
  );
};

export default VideoHistory;
