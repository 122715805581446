import React from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { styles } from "../styles";

const PanelsButton = ({
  children,
  signalRConn,
  roomName,
  isHost,
  scheduleId,
  jitsi,
  meetingType
}) => {
  console.log('meetingType from PanelsButton', meetingType);
  const userData = useSelector((state) => state);
  const history = useHistory();

  const endMeeting = () => {
    // disconnect smua user dr signalR & redirect user ke halaman lain
    signalRConn
      .invoke("TerminateMeeting", roomName)
      .then(() => {
        console.log("end meeting berhasil");
        history.push("/meeting/hari-ini");
      })
      .catch((err) => {
        console.log("end meeting gagal", err);
        history.push("/meeting/hari-ini");
      });
  };

  const forceRefresh = () => {
    // smua user refresh halaman video meeting
    signalRConn
      .invoke("ForceRefresh", roomName, userData.data.id)
      .then(() => {
        console.log("force refresh berhasil");
        // window.location.reload();
      })
      .catch((err) => {
        console.log("force refresh gagal", err);
        // window.location.reload();
      });
  };

  const allowParticipant = () => {
    const url = `/meeting/ijin/${roomName}/jadwal/${scheduleId}`;
    window.open(url, "_blank");
  };

  const liveStream = () => {
    const tgl = dayjs().format("YYYY_MM_DD_hh_mm");
    console.log("record roomName nih", roomName);
    console.log("record tgl nih", tgl);
    jitsi &&
      jitsi.executeCommand("startRecording", {
        mode: "stream",
        youtubeStreamKey: `participant_stream#norecord#${roomName}_${tgl}`,
        youtubeBroadcastID: `participant_stream#norecord#${roomName}_${tgl}`,
      });
  };

  return (
    <div
      className="panel-block has-background-white"
      style={{ position: "absolute", bottom: 0, right: 0, left: 0 }}
    >
      <div className="buttons">
        {(userData.data.role === "Admin" || isHost) && (
          <>
            <button
              title="Akhiri meeting"
              className="button is-danger"
              onClick={endMeeting}
              style={styles.button}
            >
              <i className="fas fa-power-off"></i>
            </button>

            <button
              title="Paksa refresh semua user"
              className="button is-info"
              onClick={forceRefresh}
              style={styles.button}
            >
              <i className="fas fa-undo"></i>
            </button>

            <button
              title="Ijinkan partisipan masuk"
              className="button is-warning"
              onClick={allowParticipant}
              style={styles.button}
            >
              <i className="fas fa-user-friends"></i>
            </button>

            {meetingType === 1 && (
              <button
                title="Livestream"
                className="button is-danger"
                onClick={liveStream}
                style={styles.button}
              >
                <i className="fas fa-record-vinyl"></i>
              </button>
            )}
          </>
        )}

        {children}
      </div>
    </div>
  );
};

export default PanelsButton;
