import React, { useEffect } from "react";

const NotFound = () => {
  useEffect(() => {
    // ubah title
    document.title = "Halaman tidak ditemukan - BPPT";
  }, []);

  return <p className="column p-6">Halaman tidak ditemukan.</p>;
};

export default NotFound;
