import React from "react";
import dayjs from "dayjs";
import "dayjs/locale/id";
dayjs.locale("id");

const RenderChatHistory = ({ chat }) => {
  return (
    <>
      {/* icon org & nama user */}
      <p>
        <i className="fas fa-user mr-2"></i>
        <small>{chat.fullName}</small>
      </p>

      {/* isi chat */}
      <p
        className="has-background-light p-2"
        style={{ borderRadius: 3, wordWrap: "break-word" }}
      >
        {chat.content}

        {/* waktu */}
        <small className="ml-2 is-size-7">
          {dayjs(chat.created).add(7, "hour").format("D MMMM YYYY, HH:mm")}
        </small>
      </p>
    </>
  );
};

export default RenderChatHistory;
