import React from "react";
import axios from "../../../../config/axios";
import { useSelector } from "react-redux";
import RenderParticipant from "./RenderParticipant";
import CariPartisipan from "./CariPartisipan";

const ParticipantPanel = ({
  show,
  roomName,
  stateParticipants,
  dispatchParticipants,
  setTabs,
  setRecipentProps,
  signalRConn,
  isHost,
  jitsi,
  meetingType,
}) => {
  const userData = useSelector((state) => state);

  // cari partisipan
  const searchParticipant = (e) => {
    axios
      .get(
        `/meeting/${roomName}/online-participant?query=${e.target.value.toLowerCase()}`,
        {
          headers: {
            Authorization: "Bearer " + userData.data.jwtToken,
          },
        }
      )
      .then((res) => {
        console.log("success cari partisipan", res.data.data);
        dispatchParticipants({
          type: "CHANGE_PARTICIPANTS",
          data: res.data.data.map((d) => {
            const { author, joined, jitsiId, isSpeaker } = d;
            return {
              id: author.id,
              displayName: author.fullName,
              joinTime: joined,
              avatar: author.avatar && author.avatar.imageSmall,
              jitsiId,
              isSpeaker,
            };
          }),
        });
      })
      .catch((err) => {
        console.log("error cari partisipan", err);
      });
  };

  return (
    <div className={show ? "" : "is-hidden"}>
      {/* cari partisipan */}
      <div className="panel-block">
        <CariPartisipan searchParticipant={searchParticipant} />
      </div>

      <div
        style={{
          overflowY: "scroll",
          overflowX: "hidden",
          position: "absolute",
          left: 0,
          right: 0,
          top: 140,
          bottom: 50,
        }}
      >
        {/* daftar partisipan */}
        {stateParticipants.participants.length > 0 ? (
          stateParticipants.participants.map((participant) => (
            <RenderParticipant
              key={"participant" + participant.id}
              participant={participant}
              setTabs={setTabs}
              setRecipentProps={setRecipentProps}
              signalRConn={signalRConn}
              roomName={roomName}
              isHost={isHost}
              jitsi={jitsi}
              meetingType={meetingType}
              dispatchParticipants={dispatchParticipants}
            />
          ))
        ) : (
          <div className="panel-block">
            <small>Tidak ada partisipan? Silahkan refresh halaman.</small>
            <br />
            <button className="button is-small is-link" onClick={refreshPage}>
              Refresh Halaman
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const refreshPage = () => window.location.reload();

export default ParticipantPanel;
