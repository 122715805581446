import React from "react";
import formatTanggal from "../../../helpers/formatTanggal";
import { Link } from "react-router-dom";

const RenderPerMeeting = ({ meeting, i, modalEditOn }) => {
  return (
    <div className="card" key={meeting.roomName + i}>
      <div className="card-content columns is-vcentered">
        <div className="column">
          <Link
            to={
              "/meeting/" + meeting.roomName + "/jadwal/" + meeting.schedule.id
            }
            className="is-size-5 has-text-danger"
          >
            {meeting.title}
            <br />
            {meeting.schedule.isStarted && (
              <span className="tag is-danger">Sedang Berlangsung</span>
            )}
            {!meeting.isPublic && <span className="tag mb-1">Privat</span>}
            {meeting.hasPassword && (
              <span className="tag is-danger mb-1">
                <i className="fas fa-key"></i>
              </span>
            )}
          </Link>
          <p>
            Kode:{" "}
            <span className="has-text-weight-bold">{meeting.roomName}</span>
          </p>
        </div>
        <div className="column has-text-centered">
          <p className="has-text-danger">
            {meeting.schedule.start} - {meeting.schedule.end}
          </p>
          <p> {formatTanggal(meeting.schedule.startDate)} </p>
        </div>
        <div className="column">
          <div className="columns">
            {meeting.isRecuring ? (
              <div className="column">
                {/* tombol edit meeting recuring */}
                <button
                  title={meeting.roomName}
                  id={meeting.schedule.id}
                  onClick={modalEditOn}
                  className="button is-link is-rounded is-fullwidth is-outlined"
                >
                  Edit
                </button>
              </div>
            ) : (
              <div className="column">
                {/* tombol edit meeting non recuring */}
                <Link
                  to={{
                    pathname: "/meeting/edit/" + meeting.roomName,
                    state: {
                      isHost: true,
                    },
                  }}
                  className="button is-link is-rounded is-fullwidth is-outlined"
                >
                  Edit
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenderPerMeeting;
