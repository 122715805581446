import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import axios from "../../config/axios";
import formatDate from "../../helpers/formatDate";
import HapusMeeting from "./edit/HapusMeeting";

const EditMeetingSchedule = ({ userData, location }) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  // disable btn saat fetch
  const [disableBtn, setDisableBtn] = useState(false);

  // jika durasi ny 0 jam dan 0 menit
  const [durasiError, setDurasiError] = useState(false);

  const { roomName, scheduleId } = useParams();
  const [submit, setSubmit] = useState({});

  useEffect(() => {
    // ubah title saat render
    document.title = "Edit Jadwal Meeting " + submit.title + " - BPPT";
  }, [submit.title]);

  useEffect(() => {
    if (roomName) {
      axios
        .get(`/meeting/${roomName}/schedule/${scheduleId}`, {
          headers: {
            Authorization: "Bearer " + userData.data.jwtToken,
          },
        })
        .then((res) => {
          // manipulasi jam utk ditampilkan di halaman edit
          const schedule = res.data.data.schedule;
          const startDate = schedule.startDate;
          const start = schedule.start.split(":");
          const end = schedule.end.split(":");
          const startDateJam = parseInt(start[0]);
          const startDateMenit = parseInt(start[1]);
          const endMinusStart =
            parseInt(end[0]) * 60 +
            parseInt(end[1]) -
            startDateJam * 60 +
            startDateMenit;
          const durationJam = Math.floor(endMinusStart / 60);
          const durationMenit = endMinusStart % 60;

          // generate default value setiap field kecuali schedule
          setSubmit({
            title: res.data.data.title,
            startDate: formatDate(startDate),
            startDateJam,
            startDateMenit,
            durationJam,
            durationMenit,
          });
        })
        .catch((err) => {
          window.scrollTo(0, 0);
          console.log(err);
          if (err.response) {
            err.response.data.message && setError(err.response.data.message);
            err.response.data.title && setError(err.response.data.title);
          } else {
            setError("Maaf terjadi kesalahan");
          }
        });
    }
  }, [roomName]); // eslint-disable-line react-hooks/exhaustive-deps

  const editMeetingSchedule = (e) => {
    e.preventDefault();
    if (
      submit.durationJam.toString() === "0" &&
      submit.durationMenit.toString() === "0"
    ) {
      setDurasiError(true);
    } else {
      if (!disableBtn) {
        setDisableBtn(true);

        const {
          startDate,
          startDateJam,
          startDateMenit,
          durationJam,
          durationMenit,
        } = submit;

        // tambahkan angka nol jika angka krg dr 10
        const formatNol = (angka) => {
          return angka < 10 ? "0" + angka : angka;
        };

        // manipulasi jam utk mengisi field start & end di db
        const jamMulai = parseInt(startDateJam) * 60 + parseInt(startDateMenit);
        const durasi = parseInt(durationJam) * 60 + parseInt(durationMenit);
        const endJam = Math.floor((jamMulai + durasi) / 60);
        const endMenit = (jamMulai + durasi) % 60;
        const end = `${formatNol(endJam)}:${formatNol(endMenit)}`;
        const start = `${formatNol(startDateJam)}:${formatNol(startDateMenit)}`;

        axios
          .put(
            `/meeting/${roomName}/schedule/${scheduleId}`,
            {
              id: parseInt(scheduleId),
              startDate,
              start,
              end,
            },
            {
              headers: {
                Authorization: "Bearer " + userData.data.jwtToken,
              },
            }
          )
          .then((res) => {
            console.log(res);
            setSuccess(true);
            setDisableBtn(false);
            window.scrollTo(0, 0);
          })
          .catch((err) => {
            setDisableBtn(false);
            window.scrollTo(0, 0);
            console.log(err);
            if (err.response) {
              err.response.data.message && setError(err.response.data.message);
              err.response.data.title && setError(err.response.data.title);
            } else {
              setError("Maaf terjadi kesalahan");
            }
          });
      }
    }
  };

  // onchange handler utk setiap input kecuali select host & participant
  const handleChange = (e) => {
    setSubmit({
      ...submit,
      [e.target.id]: e.target.value,
    });
  };

  if (!location.state?.scheduleId) {
    <Redirect
      to={
        userData.data.role === "Admin" ? "/meeting/atur" : "/meeting/hari-ini"
      }
    />;
  }

  return (
    <form className="column p-5" onSubmit={editMeetingSchedule}>
      {/* peringatan success */}
      <div className="my-5">
        {success && (
          <p className="notification is-info">Edit jadwal berhasil</p>
        )}
        {error && <p className="notification is-danger">{error}</p>}
      </div>

      {!submit ? (
        <progress className="progress is-small is-info" max="100"></progress>
      ) : (
        <>
          <p className="is-size-3 mb-5">Edit Jadwal {submit.title} - BPPT</p>

          {/* start, tgl & jam */}
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Mulai Meeting</label>
            </div>
            <div className="field-body">
              {/* tanggal */}
              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    type="date"
                    id="startDate"
                    onChange={handleChange}
                    defaultValue={
                      submit.startDate && formatDate(submit.startDate)
                    }
                    disabled
                  />
                </div>
              </div>

              {/* jam */}
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Jam</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select id="startDateJam" onChange={handleChange}>
                          <option value={submit.startDateJam}>
                            {submit.startDateJam}
                          </option>

                          {hours.map((hour, i) => (
                            <option value={hour} key={i.toString()}>
                              {hour}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* menit */}
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Menit</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select id="startDateMenit" onChange={handleChange}>
                          <option value={submit.startDateMenit}>
                            {submit.startDateMenit}
                          </option>

                          <option value={0}>0</option>
                          <option value={15}>15</option>
                          <option value={30}>30</option>
                          <option value={45}>45</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* duration */}
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Durasi</label>
            </div>
            <div className="field-body">
              {/* jam */}
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Jam</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select id="durationJam" onChange={handleChange}>
                          <option value={submit.durationJam}>
                            {submit.durationJam}
                          </option>

                          <option value={0}>0</option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* menit */}
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Menit</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select id="durationMenit" onChange={handleChange}>
                          <option value={submit.durationMenit}>
                            {submit.durationMenit}
                          </option>

                          <option value={0}>0</option>
                          <option value={15}>15</option>
                          <option value={30}>30</option>
                          <option value={45}>45</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                {durasiError && (
                  <small className="help is-danger mt-2 ml-3">
                    Durasi tidak boleh 0
                  </small>
                )}
              </div>
            </div>
          </div>

          {/* tombol */}
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">{/* ksg utk beri jarak */}</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <button className="button is-link" disabled={disableBtn}>
                    Simpan
                  </button>

                  {userData.data.role === "Admin" && (
                    <HapusMeeting
                      roomName={roomName}
                      isRecuring={false}
                      jwtToken={userData.data.jwtToken}
                      scheduleId={scheduleId}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </form>
  );
};

// utk Duration
let hours = [];
for (let i = 0; i < 24; i++) {
  hours.push(i);
}

const mapStateToProps = (state) => {
  return {
    userData: state,
  };
};

export default connect(mapStateToProps)(EditMeetingSchedule);
