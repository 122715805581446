import React, { useState } from "react";
import RenderImageHistory from "./RenderImageHistory";

const ImageHistory = ({ images }) => {
  const [imageOn, setImageOn] = useState(false);
  const toggleImage = () => setImageOn((prevImage) => !prevImage);

  return (
    <div className="card">
      {/* toggle image history */}
      <div className="card-header is-clickable" onClick={toggleImage}>
        <p className="card-header-title">Image History</p>
        <p className="card-header-icon" aria-label="more options">
          <span className="icon">
            <i className="fas fa-angle-down" aria-hidden="true"></i>
          </span>
        </p>
      </div>

      {/* image list */}
      <div className={imageOn ? "card-content" : "card-content is-hidden"}>
        <div className="content" style={{ width: 180 }}>
          {images.length > 0 &&
            images.map((image) => (
              <RenderImageHistory image={image} key={"image" + image.id} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default ImageHistory;
