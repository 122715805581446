import jitsiConfig from "./jitsiConfig";
import { loadJitsiScript } from "./loadJitsiScript";

const initializeJitsiHelper = async (
  meetingDetail,
  roomName,
  userData,
  setPasswordRoom,
  tombolJitsi,
  jitsiContainerId,
  dispatchParticipants,
  setJitsiId,
  meetingType,
  setJitsi,
  setRedirectKick,
  setTileView
) => {
  console.log("run initializeJitsiHelper");
  console.log("meetingDetail.isSpeaker", meetingDetail.isSpeaker);
  console.log("meetingType === 0", meetingType === 0);

  if (!window.JitsiMeetExternalAPI) {
    await loadJitsiScript();
  }

  if (meetingDetail.isSpeaker || meetingType === 0) {
    console.log("run jitsiConfig");

    jitsiConfig(
      roomName,
      userData,
      meetingDetail,
      setPasswordRoom,
      tombolJitsi,
      jitsiContainerId,
      dispatchParticipants,
      setJitsiId,
      meetingType,
      setJitsi,
      setRedirectKick,
      setTileView
    );
  }
};

export default initializeJitsiHelper;
