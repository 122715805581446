import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { HubConnectionBuilder } from "@microsoft/signalr";
import InfoSchedule from "./lobi/InfoSchedule";
import { config } from "../../config";

const Lobby = ({ userData }) => {
  const { roomName } = useParams();
  const [error, setError] = useState("");

  useEffect(() => {
    var retryCount = 0;
    var retryDelay = [5000, 10000, 15000];
    let unmounted = 0;
    const newConnection = new HubConnectionBuilder()
      .withUrl(
        config.REACT_APP_URL_SIGNALR +
          "/hub/waiting-room?roomName=" +
          roomName +
          "&access_token=" +
          userData.data.jwtToken
      )
      .build();

    newConnection.serverTimeoutInMilliseconds = 1800000;

    newConnection.on("onError", function (message) {
      console.log("error signalr lobi", message);
      setError(
        "Maaf anda belum terdaftar sebagai partisipan atau terjadi kesalahan lain"
      );
    });

    newConnection.on("onLog", function (message) {
      console.log(message);
    });

    newConnection.on("unAuthorized", function (message) {
      alert(message);
    });

    newConnection.on("startMeeting", (message) => {
      alert(message);
      window.location = "/meeting/video/" + roomName;
    });

    const callSignalR = () => {
      unmounted = 0;
      console.log("call signalr");

      newConnection
        .start()
        .then(() => {
          console.log("signalR lobby started");
          setError("");
        })
        .catch((err) => {
          window.scrollTo(0, 0);
          console.log(err);
          if (err.response) {
            err.response.data.message && setError(err.response.data.message);
            err.response.data.title && setError(err.response.data.title);
          } else {
            setError(
              "Maaf terjadi kesalahan, silahkan cek koneksi internet anda"
            );
          }
          newConnection.stop();
          setTimeout(callSignalR, 5000);
        });
    };

    const loop = () => {
      if (retryCount === retryDelay.length) {
        alert("Gagal terhubung dengan server, harap refresh halaman");
        window.location.reload();
      }

      console.log("call loop");
      setTimeout(callSignalR, retryDelay[retryCount]);
      retryCount++;
    };

    newConnection.onclose(() => {
      if (unmounted === 0) {
        console.log("onclose signalr lobby");
        loop();
      }
    });

    callSignalR();

    return () => {
      unmounted = 1;
      console.log("stop signalr lobby");
      newConnection.stop();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // admin tdk blh akses halaman ini
  if (userData.data.role === "Admin") {
    return <Redirect to="/meeting/hari-ini" />;
  }

  return (
    <div className="column p-5">
      {error ? (
        <p className="notification is-danger">{error}</p>
      ) : (
        <div className="notification is-info">
          Meeting {roomName} belum dimulai, silahkan tunggu.
          <br />
          Pemberitahuan (popup alert) akan muncul jika meeting sudah dimulai.
        </div>
      )}

      <InfoSchedule roomName={roomName} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state,
  };
};

export default connect(mapStateToProps)(Lobby);
