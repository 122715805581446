import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "../../config/axios";
import ErrorSuccess from "../user/login/ErrorSuccess";

const IjinMeeting = () => {
  const { roomName, scheduleId } = useParams();
  const userData = useSelector((state) => state);
  const [pendingParticipants, setPendingParticipants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);

  const allowParticipate = (e) => {
    console.log("dataset nih", e.target.dataset);
    const { id, userid, role, isbanned } = e.target.dataset;
    if (!disableBtn) {
      setDisableBtn(true);
      axios
        .put(
          `/meeting/${roomName}/participant/${id}`,
          {
            id: parseInt(id),
            userId: parseInt(userid),
            role: parseInt(role),
            isBanned: isbanned === "false" ? false : true,
            isPending: false,
          },
          {
            headers: {
              Authorization: "Bearer " + userData.data.jwtToken,
            },
          }
        )
        .then((res) => {
          setDisableBtn(false);
          setError("");
          console.log("allow participate succeed", res);
          setPendingParticipants((prevParticipants) =>
            prevParticipants.filter(
              (participant) => participant.id !== parseInt(id)
            )
          );
        })
        .catch((err) => {
          setDisableBtn(false);
          console.log("allow participate failed", err);
          window.scrollTo(0, 0);
          if (err.response) {
            err.response.data.message && setError(err.response.data.message);
            err.response.data.title && setError(err.response.data.title);
          } else {
            setError("Maaf terjadi kesalahan");
          }
        });
    }
  };

  const deleteParticipant = (e) => {
    console.log("dataset nih", e.target.dataset);
    const { id } = e.target.dataset;
    if (!disableBtn) {
      setDisableBtn(true);
      axios
        .delete(`/meeting/${roomName}/participant/${id}`, {
          headers: {
            Authorization: "Bearer " + userData.data.jwtToken,
          },
        })
        .then((res) => {
          setDisableBtn(false);
          setError("");
          console.log("delete participate succeed", res);
          setPendingParticipants((prevParticipants) =>
            prevParticipants.filter(
              (participant) => participant.id !== parseInt(id)
            )
          );
        })
        .catch((err) => {
          setDisableBtn(false);
          console.log("delete participate failed", err);
          window.scrollTo(0, 0);
          if (err.response) {
            err.response.data.message && setError(err.response.data.message);
            err.response.data.title && setError(err.response.data.title);
          } else {
            setError("Maaf terjadi kesalahan");
          }
        });
    }
  };

  useEffect(() => {
    document.title = `Ijin Meeting ${roomName} - BPPT`;
  }, [roomName]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchData = () => {
      axios
        .get(`/meeting/${roomName}/schedule/${scheduleId}`, {
          headers: {
            Authorization: "Bearer " + userData.data.jwtToken,
          },
        })
        .then((res) => {
          setLoading(false);
          console.log("fetch ijin berhasil", res.data.data.pendingParticipant);
          setPendingParticipants(res.data.data.pendingParticipant);
        })
        .catch((err) => {
          setLoading(false);
          console.log("fetch ijin gagal", err);
          window.scrollTo(0, 0);
          if (err.response) {
            err.response.data.message && setError(err.response.data.message);
            err.response.data.title && setError(err.response.data.title);
          } else {
            setError("Maaf terjadi kesalahan");
          }
        });
    };

    const loop = setInterval(fetchData, 60000);

    // run lgsg tanpa nunggu 1 menit (ini yg pertama x di run)
    fetchData();

    return () => clearInterval(loop);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="column p-5">
      <small className="has-text-grey">
        Halaman ini akan refresh otomatis setiap 1 menit
      </small>

      <p className="is-size-3">Ijin Meeting {roomName}</p>

      <p className="has-text-grey">
        Daftar partisipan yang menunggu diijinkan masuk :
      </p>

      <ErrorSuccess error={error} success="" />

      {loading ? (
        <progress className="progress is-small is-info" max="100"></progress>
      ) : pendingParticipants.length > 0 ? (
        <div className="mt-3">
          {pendingParticipants.map((participant) => (
            <p className="my-2" key={"participant" + participant.id}>
              <span className="mr-3">{participant.author.fullName}</span>
              <button
                data-id={participant.id}
                data-userid={participant.author.id}
                data-role={participant.role}
                data-isbanned={participant.isBanned}
                disabled={disableBtn}
                className="button is-small is-link"
                onClick={allowParticipate}
              >
                Ijinkan
              </button>
              <button
                data-id={participant.id}
                data-userid={participant.author.id}
                data-role={participant.role}
                data-isbanned={participant.isBanned}
                disabled={disableBtn}
                className="button is-small is-danger ml-1"
                onClick={deleteParticipant}
              >
                Tidak Ijinkan
              </button>
            </p>
          ))}
        </div>
      ) : (
        <div className="mt-3">Belum ada partisipan pending</div>
      )}
    </div>
  );
};

export default IjinMeeting;
