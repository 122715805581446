import React from "react";
import { Link, withRouter } from "react-router-dom";
import { styles } from "./Nav";

const Footer = ({ location }) => {
  return !location.pathname.includes("/bels/") ? (
    <footer
      className="footer"
      style={{
        paddingTop: 10,
        paddingBottom: 10,
        ...styles.nav,
      }}
    >
      <div className="content has-text-centered has-text-white">
        <span>
          <Link to="/privacy-policy" className="has-text-white">
            Privacy Policy
          </Link>
        </span>{" "}
        &middot;{" "}
        <span>
          <Link to="/term-of-service" className="has-text-white">
            Term of Service
          </Link>
        </span>
      </div>
    </footer>
  ) : (
    <div className="has-background-white"></div>
  );
};

export default withRouter(Footer);
