import dayjs from "dayjs";
import { config } from "../../../config";
import axios from "../../../config/axios";

const jitsiConfig = (
  roomName,
  userData,
  meetingDetail,
  setPasswordRoom,
  tombolJitsi,
  jitsiContainerId,
  dispatchParticipants,
  setJitsiId,
  meetingType,
  setJitsi,
  setRedirectKick,
  setTileView
) => {
  // GET password room
  axios
    .get(`/meeting/${roomName}/password`, {
      headers: {
        Authorization: "Bearer " + userData.data.jwtToken,
      },
    })
    .then((res) => {
      console.log(res);
      res.data.data && setPasswordRoom(res.data.data);
    })
    .catch((err) => console.log(err));

  const _jitsi = new window.JitsiMeetExternalAPI(
    config.REACT_APP_URL_JITSI_NO_HTTP,
    {
      roomName,
      width: "100%",
      height: "100%",
      // jwt: meetingDetail.jitsiToken,
      userInfo: {
        displayName: userData.data.fullName,
        email: userData.data.email,
        userId: userData.data.id,
        jwtBppt: userData.data.jwtToken,
        urlSlide: config.REACT_APP_URL_SLIDE.slice(
          0,
          config.REACT_APP_URL_SLIDE.length - 1
        ).replace("https://", ""),
        urlSignalR: config.REACT_APP_URL_SIGNALR.replace("https://", ""),
        scheduleId: meetingDetail.schedule.id.toString(),
      },
      configOverwrite: {
        requireDisplayName: false,
        startAudioMuted: meetingDetail.isMuteParticipantUponEntry ? 1 : 999,
        startWithVideoMuted: true,
        disableAudioLevels: true,
      },
      interfaceConfigOverwrite: {
        TOOLBAR_BUTTONS: tombolJitsi,
        DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
        DISABLE_FOCUS_INDICATOR: true,
      },
      parentNode: document.getElementById(jitsiContainerId),
    }
  );

  _jitsi.on("videoConferenceJoined", (obj) => {
    console.log("user join jitsi", obj);
    setJitsiId(obj.id);

    // jika admin / host
    if (meetingDetail.isSpeaker && meetingType === 1) {
      setTimeout(() => {
        const tgl = dayjs().format("YYYY_MM_DD_hh_mm");
        _jitsi.executeCommand("startRecording", {
          mode: "stream",
          youtubeStreamKey: `participant_stream#norecord#${roomName}_${tgl}`,
          youtubeBroadcastID: `participant_stream#norecord#${roomName}_${tgl}`,
        });
      }, 5000);
    }
  });

  // jika user keluar dr video meeting
  _jitsi.on("participantKickedOut", (obj) => {
    console.log("user dikick nich", obj.kicked.local);
    if (obj.kicked.local) {
      setRedirectKick(true);
    }
  });

  // jika ada org yg ngmg, dikasih flag
  _jitsi.on("dominantSpeakerChanged", (obj) => {
    console.log("speaker flag on");
    dispatchParticipants({ type: "SPEAKER_FLAG", data: obj.id });
    // _jitsi.setLargeVideoParticipant(obj.id);
  });

  // set new password for channel & update status (Moderator) di participant list
  _jitsi.on("participantRoleChanged", function (obj) {
    if (obj.role === "moderator") {
      meetingDetail.isAdmit && _jitsi.executeCommand("toggleLobby", true);
    }
  });

  // jika kondisi tile view berubah
  _jitsi.on("tileViewChanged", (obj) => {
    console.log("tile view changed", obj.enabled);
    setTileView(obj.enabled);

    if (obj.enabled && userData.presentationStarted) {
      _jitsi.executeCommand("toggleTileView");
    }
  });

  // klo udh hangup, redirect ke home
  _jitsi.on("readyToClose", () => {
    window.location = "/meeting/hari-ini";
  });

  setJitsi(_jitsi);
};

export default jitsiConfig;
