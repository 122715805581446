import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import { config } from "../../../../config";
import axios from "../../../../config/axios";

const DaftarGambar = ({
  jumlahGambar,
  setJumlahGambar,
  roomName,
  scheduleId,
  jwtToken,
}) => {
  const [error, setError] = useState("");
  const [gambars, setGambars] = useState([]);
  const [imageModal, setImageModal] = useState({
    className: "modal",
    imgSrc: "",
    imgAlt: "",
  });

  const imageModalOn = (e) => {
    setImageModal({
      className: "modal is-active",
      imgSrc: e.target.id,
      alt: e.target.alt,
    });
  };

  const imageModalOff = () => setImageModal({ className: "modal" });

  useEffect(() => {
    axios
      .get(`/meeting/${roomName}/gallery/${scheduleId}`, {
        headers: {
          Authorization: "Bearer " + jwtToken,
        },
      })
      .then((res) => {
        console.log("fetch gallery succeed", res);
        const data = res.data.data;
        setGambars(sortData(data));
        data.length > 0 && jumlahGambar < 1 && setJumlahGambar(data.length);
      })
      .catch((err) => {
        console.log("fetch gallery failed", err);
        if (err.response) {
          err.response.data.message && setError(err.response.data.message);
          err.response.data.title && setError(err.response.data.title);
        } else {
          setError("Maaf terjadi kesalahan saat menampilkan daftar file");
        }
      });
  }, [jumlahGambar]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div
        style={{
          width: 300,
          height: "35px",
          backgroundColor: jumlahGambar < 1 ? "inherit" : "#eee",
          position: jumlahGambar < 1 ? "absolute" : "static",
        }}
      >
        <Swiper spaceBetween={5} slidesPerView={5}>
          {gambars.length > 0 ? (
            gambars.map((gambar) => (
              <SwiperSlide
                key={gambar.id}
                style={{ width: "35px", flexShrink: "inherit" }}
              >
                <img
                  key={"gambar" + gambar.id}
                  src={config.REACT_APP_URL_GAMBAR + gambar.imageSmall}
                  alt={`Gambar ${gambar.id} dari ${gambar.author.fullName}`}
                  id={config.REACT_APP_URL_GAMBAR + gambar.imageDefault}
                  width="35px"
                  className="is-clickable"
                  onClick={imageModalOn}
                />
              </SwiperSlide>
            ))
          ) : (
            <p className="has-text-grey-light pl-2">
              {error ? error : "Belum ada gambar"}
            </p>
          )}
        </Swiper>
      </div>

      {/* image modal */}
      <div className={imageModal.className}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <p className="image">
            <img src={imageModal.imgSrc} alt={imageModal.imgAlt} />
          </p>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={imageModalOff}
        ></button>
      </div>
    </>
  );
};

export const sortData = (gambars) => {
  return gambars.sort((a, b) => b.id - a.id);
};

export default DaftarGambar;
