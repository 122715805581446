import React from "react";
import Sidebar from "./components/Sidebar";
import ProtectedRoute from "./components/ProtectedRoute";

import Dashboard from "./components/Dashboard";
import TestMeeting0 from "./components/TestMeeting0";
import TestMeeting1 from "./components/TestMeeting1";

// component user
import Login from "./components/user/Login";
import Register from "./components/user/Register";
import VerifyEmail from "./components/user/VerifyEmail";
import ForgotPassword from "./components/user/ForgotPassword";
import ResetPassword from "./components/user/ResetPassword";
import AuthenticateCas from "./components/user/AuthenticateCas";

// component meeting
import Meetings from "./components/meeting/Meetings";
import MeetingSuperAdmin from "./components/meeting/MeetingSuperAdmin";
import MeetingHariIni from "./components/meeting/MeetingHariIni";
import MeetingHariIniPersonal from "./components/meeting/MeetingHariIniPersonal";
import MeetingsPersonal from "./components/meeting/MeetingsPersonal";
import MeetingBesok from "./components/meeting/MeetingBesok";
import MeetingBesokPersonal from "./components/meeting/MeetingBesokPersonal";
import MeetingKemarin from "./components/meeting/MeetingKemarin";
import MeetingKemarinPersonal from "./components/meeting/MeetingKemarinPersonal";
import AddMeeting from "./components/meeting/AddMeeting";
import AturMeeting from "./components/meeting/AturMeeting";
import EditMeeting from "./components/meeting/EditMeeting";
import EditMeetingSchedule from "./components/meeting/EditMeetingSchedule";
import DetailMeeting from "./components/meeting/DetailMeeting";
import Lobby from "./components/meeting/Lobby";
import LobbyPrivate from "./components/meeting/video/LobbyPrivate";
import VideoMeeting from "./components/meeting/VideoMeeting";
import Statistik from "./components/meeting/Statistik";
import History from "./components/meeting/History";
import FilePresentasi from "./components/meeting/FilePresentasi";
import IjinMeeting from "./components/meeting/IjinMeeting";

// component news
import News from "./components/news/News";
import AddNews from "./components/news/AddNews";
import EditNews from "./components/news/EditNews";
import DetailNews from "./components/news/DetailNews";

// component events
import Events from "./components/event/Events";
import AddEvent from "./components/event/AddEvent";
import EditEvent from "./components/event/EditEvent";
import DetailEvent from "./components/event/DetailEvent";

// component users
import Users from "./components/user/Users";
import AddUser from "./components/user/AddUser";
import EditUser from "./components/user/EditUser";

// component pengaturan
import Pengingat from "./components/pengaturan/Pengingat";
import EditProfil from "./components/pengaturan/EditProfil";
import EditAvatar from "./components/pengaturan/EditAvatar";

// bels
import BelsVideo from "./components/bels/BelsVideo";
import BelsLobi from "./components/bels/BelsLobi";
import BelsSelesai from "./components/bels/BelsSelesai";

// handle page not found
import NotFound from "./components/NotFound";
import TextAja from "./components/TextAja";

// library
import { Switch, Route, withRouter } from "react-router-dom";

const Routeku = ({ sidebarOn, setSidebarOn, location }) => {
  const pathname = location.pathname;

  return (
    <div
      className={
        pathname.includes("/login")
          ? "columns is-centered"
          : "columns has-background-white"
      }
      style={{
        margin: "63px 0",
        borderRadius: 10,
      }}
    >
      <Sidebar sidebarOn={sidebarOn} setSidebarOn={setSidebarOn} />
      <Switch>
        {/* halaman yg bs diakses klo sblm login */}
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/account/verify-email" component={VerifyEmail} />
        <Route path="/account/forgot-password" component={ForgotPassword} />
        <Route path="/account/reset-password" component={ResetPassword} />
        <Route path="/authenticate-cas" component={AuthenticateCas} />

        <ProtectedRoute exact path="/" component={Dashboard} />
        <Route exact path="/testing/testmeeting0" component={TestMeeting0} />
        <Route
          exact
          path="/testing/testmeeting1/:roomName"
          component={TestMeeting1}
        />

        {/* meeting */}
        <ProtectedRoute exact path="/meeting" component={Meetings} />
        <ProtectedRoute
          path="/meeting/super-admin"
          component={MeetingSuperAdmin}
        />
        <ProtectedRoute path="/meeting/hari-ini" component={MeetingHariIni} />
        <ProtectedRoute
          path="/meeting/hari-ini/personal"
          component={MeetingHariIniPersonal}
        />
        <ProtectedRoute path="/meeting/personal" component={MeetingsPersonal} />
        <ProtectedRoute exact path="/meeting/besok" component={MeetingBesok} />
        <ProtectedRoute
          path="/meeting/besok/personal"
          component={MeetingBesokPersonal}
        />
        <ProtectedRoute
          exact
          path="/meeting/kemarin"
          component={MeetingKemarin}
        />
        <ProtectedRoute
          path="/meeting/kemarin/personal"
          component={MeetingKemarinPersonal}
        />
        <ProtectedRoute path="/meeting/tambah" component={AddMeeting} />
        <ProtectedRoute path="/meeting/atur" component={AturMeeting} />
        <ProtectedRoute
          exact
          path="/meeting/edit/:roomName"
          component={EditMeeting}
        />
        <ProtectedRoute
          path="/meeting/edit/:roomName/jadwal/:scheduleId"
          component={EditMeetingSchedule}
        />
        <ProtectedRoute path="/meeting/lobi/:roomName" component={Lobby} />
        <ProtectedRoute
          path="/meeting/lobi-privat/:roomName"
          component={LobbyPrivate}
        />
        <ProtectedRoute
          path="/meeting/video/:roomName/jadwal/:scheduleId"
          component={VideoMeeting}
        />
        <ProtectedRoute
          path="/meeting/statistik/:roomName/jadwal/:scheduleId"
          component={Statistik}
        />
        <ProtectedRoute
          path="/meeting/history/:roomName/jadwal/:scheduleId"
          component={History}
        />
        <ProtectedRoute
          path="/meeting/file/:roomName/jadwal/:scheduleId"
          component={FilePresentasi}
        />
        <ProtectedRoute
          path="/meeting/ijin/:roomName/jadwal/:scheduleId"
          component={IjinMeeting}
        />
        <ProtectedRoute
          path="/meeting/:roomName/jadwal/:scheduleId"
          component={DetailMeeting}
        />

        {/* berita */}
        <ProtectedRoute exact path="/berita" component={News} />
        <ProtectedRoute path="/berita/tambah" component={AddNews} />
        <ProtectedRoute path="/berita/edit/:slug" component={EditNews} />
        <ProtectedRoute path="/berita/:slug" component={DetailNews} />

        {/* acara */}
        <ProtectedRoute exact path="/acara" component={Events} />
        <ProtectedRoute path="/acara/tambah" component={AddEvent} />
        <ProtectedRoute path="/acara/edit/:slug" component={EditEvent} />
        <ProtectedRoute path="/acara/:slug" component={DetailEvent} />

        {/* users */}
        <ProtectedRoute exact path="/user" component={Users} />
        <ProtectedRoute path="/user/tambah" component={AddUser} />
        <ProtectedRoute path="/user/edit/:id" component={EditUser} />

        {/* pengaturan */}
        <ProtectedRoute path="/pengaturan/pengingat" component={Pengingat} />
        <ProtectedRoute path="/pengaturan/profil" component={EditProfil} />
        <ProtectedRoute path="/pengaturan/avatar" component={EditAvatar} />

        {/* bels */}
        <Route path="/bels/video/:roomName" component={BelsVideo} />
        <Route path="/bels/lobi/:roomName" component={BelsLobi} />
        <Route path="/bels/selesai" component={BelsSelesai} />

        {/* policy & tos */}
        <Route path="/privacy-policy" component={TextAja} />
        <Route path="/term-of-service" component={TextAja} />

        {/* handle page not found */}
        <Route component={NotFound} />
      </Switch>
    </div>
  );
};

export default withRouter(Routeku);
