import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useHistory, useParams } from "react-router-dom";
import axios from "../../config/axios";
import { loadJitsiScript } from "../meeting/video/loadJitsiScript";
import { config } from "../../config";

const BelsVideo = ({ location }) => {
  const displayName = new URLSearchParams(location.search).get("displayName"); // ambil url search params
  const hostKey = new URLSearchParams(location.search).get("hostKey"); // ambil url search params
  const history = useHistory(); // utk redirect ke /meeting setelah hangup
  const { roomName } = useParams(); // dr params
  const [error, setError] = useState("");
  const [renderJitsi, setRenderJitsi] = useState(false); // jika roomName ditemukan di db, render jitsi
  const jitsiOn = () => {
    setRenderJitsi(true);
    initializeJitsi();
  };
  const jitsiContainerId = "bels-jitsi";

  const initializeJitsi = async () => {
    if (!window.JitsiMeetExternalAPI) {
      await loadJitsiScript();
    }

    const _jitsi = new window.JitsiMeetExternalAPI(
      config.REACT_APP_URL_JITSI_NO_HTTP,
      {
        roomName,
        width: "100%",
        height: "100%",
        userInfo: {
          displayName,
        },
        configOverwrite: {
          requireDisplayName: false,
        },
        parentNode: document.getElementById(jitsiContainerId),
      }
    );

    // klo udh hangup, redirect ke home
    _jitsi.on("readyToClose", () => {
      history.push("/bels/selesai");
    });
  };

  useEffect(() => {
    const apiEndpoint = hostKey
      ? "/internal/meeting/" + roomName + "?hostKey=" + hostKey
      : "/internal/meeting/" + roomName;

    axios
      .get(apiEndpoint)
      .then((res) => {
        console.log(res);
        const meeting = res.data.data;
        if (meeting.schedule.isStarted) {
          jitsiOn();
        } else {
          history.push("/bels/lobi/" + roomName);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          err.response.data.message && setError(err.response.data.message);
          err.response.data.title && setError(err.response.data.title);
        } else {
          setError("Maaf terjadi kesalahan");
        }
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (renderJitsi) {
    return ReactDOM.createPortal(
      <div id={jitsiContainerId} style={styles.jitsiDiv} />,
      document.getElementById("jitsi-root")
    );
  }

  return <div className="column p-5">{error ? error : "Loading ..."}</div>;
};

const styles = {
  jitsiDiv: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 99,
    backgroundColor: "darkgrey",
  },
};

export default BelsVideo;
