import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import axios from "../../../config/axios";

const HapusMeeting = ({ roomName, isRecuring, jwtToken, scheduleId }) => {
  const [redirectAturSukses, setRedirectAturSukses] = useState(false);
  const [redirectAturGagal, setRedirectAturGagal] = useState(false);
  const [disableModalBtn, setDisableModalBtn] = useState(false);
  const [modal, setModal] = useState("modal");
  const delModalOn = () => setModal("modal is-active");
  const delModalOff = () => setModal("modal");

  // hapus meeting
  const deleteMeeting = () => {
    if (!disableModalBtn) {
      // disable button saat fetch
      setDisableModalBtn(true);

      // mulai fetch
      axios
        .delete(
          scheduleId
            ? "/meeting/" + roomName + "/schedule/" + scheduleId
            : "/meeting/" + roomName,
          {
            headers: {
              Authorization: "Bearer " + jwtToken,
            },
          }
        )
        .then(() => {
          // redirect ke /meeting/atur & tampilkan pesan sukses
          setRedirectAturSukses(true);
        })
        .catch(() => {
          // redirect ke /meeting/atur & tampilkan pesan error
          setRedirectAturGagal(true);
        });
    }
  };

  if (redirectAturSukses) {
    return (
      <Redirect
        to={{
          pathname: "/meeting/atur",
          state: {
            pesanSukses: "Hapus meeting berhasil",
          },
        }}
      />
    );
  } else if (redirectAturGagal) {
    return (
      <Redirect
        to={{
          pathname: "/meeting/atur",
          state: {
            pesanGagal: "Hapus meeting gagal",
          },
        }}
      />
    );
  }

  return (
    <>
      <button
        className="button is-danger is-pulled-right"
        onClick={delModalOn}
        type="button"
      >
        {isRecuring ? "Hapus Semua Jadwal" : "Hapus Meeting"}
      </button>

      {/* konfirmasi hps meeting */}
      <div className={modal}>
        <div
          className="modal-background"
          onClick={delModalOff}
          style={{ opacity: 0.6 }}
        ></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Info</p>
            <button
              type="button"
              className="delete"
              aria-label="close"
              onClick={delModalOff}
              disabled={disableModalBtn}
            ></button>
          </header>
          <section className="modal-card-body">
            Anda yakin ingin menghapus meeting?
          </section>
          <footer className="modal-card-foot">
            <button
              type="button"
              className="button is-danger"
              onClick={deleteMeeting}
              disabled={disableModalBtn}
            >
              {isRecuring ? "Hapus Semua Jadwal" : "Hapus Meeting"}
            </button>
            <button
              type="button"
              className="button"
              onClick={delModalOff}
              disabled={disableModalBtn}
            >
              Batal
            </button>
          </footer>
        </div>
      </div>
    </>
  );
};

export default HapusMeeting;
