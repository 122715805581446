import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import axios from "../../config/axios";
import { connect } from "react-redux";

const ResetPassword = ({ userData, location }) => {
  // ubah title
  useEffect(() => {
    document.title = "Reset Password - BPPT";
  }, []);

  // ambil url search params
  const token = new URLSearchParams(location.search).get("token");

  // tampilkan pesan error / sukses
  const [error, setError] = useState(""); // error title
  const [errorFields, setErrorFields] = useState({}); // error per-fields
  const [success, setSuccess] = useState("");

  // atur perubahan status button jika fetch data
  const [button, setButton] = useState(false);

  // form
  const [submit, setSubmit] = useState({
    password: "",
    confirmPassword: "",
  });

  // atur perubahan isi field
  const updateSubmit = (e) => {
    setSubmit({
      ...submit,
      [e.target.id]: e.target.value,
    });
  };

  // jika tombol submit diklik
  const handleSubmit = () => {
    if (!button) {
      setButton(true);

      axios
        .post("/accounts/reset-password", {
          token,
          ...submit,
        })
        .then((res) => {
          setError("");
          setSuccess(res.data.message);
          setButton(false);
          window.scrollTo(0, 0);
        })
        .catch((err) => {
          setSuccess("");
          setButton(false);
          console.log(err);
          window.scrollTo(0, 0);
          if (err.response) {
            err.response.data.message && setError(err.response.data.message);
            err.response.data.title && setError(err.response.data.title);
            err.response.data.errors &&
              setErrorFields(err.response.data.errors);
          } else {
            setError("Maaf terjadi kesalahan");
          }
        });
    }
  };

  // pindah ke halaman dashboard jika sdh login
  if (userData.data.jwtToken) {
    return <Redirect to="/meeting/hari-ini" />;
  }

  return (
    <div className="column p-6">
      {/* peringatan error dan sukses */}
      <div className="my-5">
        {error && <p className="notification is-danger">{error}</p>}
        {success && <p className="notification is-info">{success}</p>}
      </div>

      <p className="is-size-3 mb-5">Reset Password</p>

      {fields.map((field, i) => (
        <div className="field" key={i.toString()}>
          <label className="label">{field.label}</label>
          <div className="control">
            <input
              className="input"
              type="password"
              id={field.id}
              placeholder={field.label}
              onChange={updateSubmit}
              disabled={button}
            />
          </div>
          {errorFields[field.error] &&
            errorFields[field.error].map((error, i) => (
              <small className="help is-danger" key={i.toString()}>
                {error}
              </small>
            ))}
        </div>
      ))}

      {/* button */}
      <button
        className="button is-link"
        disabled={button}
        onClick={handleSubmit}
      >
        Simpan
      </button>
    </div>
  );
};

const fields = [
  { label: "Password", id: "password", error: "Password" },
  {
    label: "Confirm Password",
    id: "confirmPassword",
    error: "ConfirmPassword",
  },
];

const mapStateToProps = (state) => {
  return {
    userData: state,
  };
};

export default connect(mapStateToProps)(ResetPassword);
