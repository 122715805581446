import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { HubConnectionBuilder } from "@microsoft/signalr";
import InfoSchedule from "../lobi/InfoSchedule";
import { config } from "../../../config";

const LobbyPrivate = ({ userData, location }) => {
  const { roomName } = useParams();
  const [redirectEndMeeting, setRedirectEndMeeting] = useState(false);
  const [redirectJitsi, setRedirectJitsi] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    var retryCount = 0;
    var retryDelay = [5000, 10000, 15000];
    const newConnection = new HubConnectionBuilder()
      .withUrl(
        config.REACT_APP_URL_SIGNALR +
          "/hub/waiting-room?roomName=" +
          roomName +
          "&access_token=" +
          userData.data.jwtToken
      )
      .build();

    newConnection.serverTimeoutInMilliseconds = 1800000;

    newConnection.on("onError", function (message) {
      console.log("error signalr lobi privat", message);
      setError(
        "Maaf anda belum terdaftar sebagai partisipan atau terjadi kesalahan lain"
      );
    });

    newConnection.on("onLog", function (message) {
      console.log(message);
    });

    newConnection.on("unAuthorized", function (message) {
      alert(message);
    });

    newConnection.on("updatePending", (userId, diijinkan) => {
      console.log("updatePending");
      if (userId === userData.data.id) {
        if (diijinkan) {
          alert("Anda telah diijinkan masuk meeting");
          setRedirectJitsi(true);
        } else {
          setRedirectEndMeeting(true);
        }
      }
    });

    const callSignalR = () => {
      console.log("call signalr");

      newConnection
        .start()
        .then(() => console.log("signalR lobi privat started"))
        .catch((err) => {
          window.scrollTo(0, 0);
          console.log(err);
          if (err.response) {
            err.response.data.message && setError(err.response.data.message);
            err.response.data.title && setError(err.response.data.title);
          } else {
            setError("Maaf terjadi kesalahan");
          }
          newConnection.stop();
          setTimeout(callSignalR, 5000);
        });
    };

    const loop = () => {
      if (retryCount === retryDelay.length) {
        alert("Gagal terhubung dengan server, harap refresh halaman");
        window.location.reload();
      }

      console.log("call loop");
      setTimeout(callSignalR, retryDelay[retryCount]);
      retryCount++;
    };

    newConnection.onclose(loop);

    callSignalR();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // cm user yg sdh login yg boleh akses halaman ini
  if (redirectJitsi) {
    return <Redirect to={"/meeting/video/" + roomName} />;
  } else if (redirectEndMeeting) {
    return (
      <Redirect
        to={{
          pathname: "/meeting/hari-ini",
          state: { pesanGagal: "Maaf, anda tidak diijinkan masuk ke meeting" },
        }}
      />
    );
  }

  return (
    <div className="column p-5">
      <div className="notification is-danger">
        {error ? (
          error
        ) : location.state?.error ? (
          location.state.error
        ) : (
          <>
            Maaf anda belum diijinkan untuk masuk ke meeting {roomName}, atau
            terjadi kesalahan lain.
            <br />
            Pemberitahuan <i>(popup alert)</i> akan muncul jika anda sudah
            diijinkan masuk meeting.
          </>
        )}
      </div>

      <InfoSchedule roomName={roomName} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state,
  };
};

export default connect(mapStateToProps)(LobbyPrivate);
