import React, { useEffect, useState } from "react";
import { config } from "../../../../../config";
import axios from "../../../../../config/axios";
import { sortData } from "../DaftarGambar";
import { downloadFile } from "../RenderMessage";

const DaftarFile = ({
  jumlahFile,
  // setJumlahFile,
  roomName,
  scheduleId,
  jwtToken,
  toggleShowFile,
}) => {
  const [error, setError] = useState("");
  const [files, setFiles] = useState([]);

  useEffect(() => {
    axios
      .get(`/meeting/${roomName}/attachment/${scheduleId}`, {
        headers: {
          Authorization: "Bearer " + jwtToken,
        },
      })
      .then((res) => {
        const data = res.data.data;
        console.log("daftar files", data);
        setFiles(sortData(data));
        // setJumlahFile((prevJumlahFile) => !prevJumlahFile);
      })
      .catch((err) => {
        console.log("daftar files error", err);
        if (err.response) {
          err.response.data.message && setError(err.response.data.message);
          err.response.data.title && setError(err.response.data.title);
        } else {
          setError("Maaf terjadi kesalahan saat menampilkan daftar file");
        }
      });
  }, [jumlahFile]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {/* tombol close */}
      <i
        className="fas fa-times is-size-3 is-pulled-right has-text-danger is-clickable"
        aria-hidden="true"
        onClick={toggleShowFile}
        style={{ position: "absolute", right: 5 }}
      ></i>

      {/* daftar file */}
      {files.length > 0 ? (
        files.map((file) => (
          <div key={"file" + file.id}>
            <small>Dikirim oleh {file.author.fullName}</small>
            <div className="panel-block">
              <a
                href={config.REACT_APP_URL_GAMBAR + file.downloadUri}
                onClick={downloadFile}
                className="is-size-6"
              >
                <span className="panel-icon">
                  <i className="fas fa-paperclip" aria-hidden="true"></i>
                </span>
                {file.fileName}
              </a>
            </div>
          </div>
        ))
      ) : (
        <p className="has-text-grey-light p-2">
          {/* pesan error */}
          {error ? error : "Belum ada file"}
        </p>
      )}
    </>
  );
};

export default DaftarFile;
