import React from "react";
import dayjs from "dayjs";
import RenderMessage from "./RenderMessage";
import DotIcon from "./DotIcon";

const RenderChat = ({
  chatku,
  message,
  displayRecipientId,
  displayRecipientName,
}) => {
  let classNames = {};
  if (chatku) {
    classNames = {
      namaUser: "mb-1",
      iconUser: "fas fa-user is-pulled-right",
      posisiNamaUser: "mr-2 is-pulled-right",
      isiChatParent: "is-pulled-right",
      isiChat: "has-background-link has-text-white mr-2 is-pulled-right p-2",
      waktu: "has-background-light mr-2 is-pulled-right p-1 is-size-7",
      privat:
        "has-background-danger has-text-white mr-2 is-pulled-right p-1 is-size-7",
    };
  } else {
    classNames = {
      namaUser: "mb-2",
      iconUser: "fas fa-user",
      posisiNamaUser: "ml-2",
      isiChatParent: "",
      isiChat: "has-background-light ml-2 p-2 is-pulled-left",
      waktu: "has-background-light ml-2 p-1 is-size-7",
      privat: "has-background-danger has-text-white ml-2 p-1 is-size-7",
    };
  }

  return (
    <div className="column">
      <p className={classNames.namaUser}>
        {displayRecipientId &&
          displayRecipientName &&
          message.author.id !== 0 && (
            <DotIcon
              chatku={chatku}
              recipientId={message.author.id}
              recipientName={message.author.fullName}
              displayRecipientId={displayRecipientId}
              displayRecipientName={displayRecipientName}
            />
          )}

        {/* icon */}
        <i className={classNames.iconUser}></i>

        {/* nama */}
        <small className={classNames.posisiNamaUser}>
          {message.author.fullName}
        </small>
      </p>

      <p>
        {/* isi chat */}
        <small
          className={classNames.isiChat}
          style={{
            borderRadius: 3,
            maxWidth: 200,
            wordWrap: "break-word",
          }}
        >
          <RenderMessage message={message} chatku={chatku} />
        </small>

        {/* waktu */}
        <small className={classNames.waktu}>
          {dayjs(message.created).format("HH:mm")}
        </small>

        {message.private && <small className={classNames.privat}>Privat</small>}
      </p>
    </div>
  );
};

export default RenderChat;
