import React from "react";
import { config } from "../../../config";

const Modal = ({ modal, setModal }) => {
  const closeModal = () =>
    setModal((prevModal) => {
      return {
        ...prevModal,
        tampil: false,
        presentasi: null,
      };
    });

  const prev = () =>
    setModal((prevModal) => {
      return {
        ...prevModal,
        slideNumber: prevModal.slideNumber - 1,
      };
    });

  const next = () =>
    setModal((prevModal) => {
      return {
        ...prevModal,
        slideNumber: prevModal.slideNumber + 1,
      };
    });

  return (
    <div className={modal.tampil ? "modal is-active" : "modal"}>
      <div className="modal-background" onClick={closeModal}></div>

      {modal.presentasi && (
        <div style={{ display: "flex", alignItems: "center" }}>
          {modal.slideNumber > 0 && (
            <i
              className="fas fa-arrow-left has-text-link is-size-2 is-clickable"
              style={{ zIndex: 1 }}
              onClick={prev}
            ></i>
          )}

          <div className="modal-content mx-5">
            <div className="flex">
              {modal.presentasi.slideList.map((slide, i) => (
                <img
                  key={"slide" + i}
                  src={config.REACT_APP_URL_SLIDE + slide.uri}
                  alt={"slide" + i}
                  style={{
                    display: i === modal.slideNumber ? "block" : "none",
                  }}
                />
              ))}
            </div>
          </div>

          {modal.slideNumber < modal.presentasi.slideCount - 1 && (
            <i
              className="fas fa-arrow-right has-text-link is-size-2 is-clickable"
              style={{ zIndex: 1 }}
              onClick={next}
            ></i>
          )}
        </div>
      )}

      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={closeModal}
      ></button>
    </div>
  );
};

export default Modal;
