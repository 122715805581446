import React, { useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { config } from "../../../config";

// tombol share link
const ShareLink = ({
  meetingDetail,
  roomName,
  fullName,
  passwordRoom,
  shareDetail,
}) => {
  // modal
  const [modal, setModal] = useState(false);
  const modalOn = () => setModal(true);
  const modalOff = () => setModal(false);

  // copy link undangan
  const linkRef = useRef(null);
  const copyLink = () => {
    linkRef.current.select();
    document.execCommand("copy");
  };

  // copy iframe
  const iframeRef = useRef(null);
  const copyIframe = () => {
    iframeRef.current.select();
    document.execCommand("copy");
  };

  return (
    <>
      <Helmet>
        <meta name="description" content={meetingDetail.description} />
      </Helmet>

      {/* tombol */}
      {shareDetail ? (
        <button
          title="Bagikan Meeting"
          className="button is-fullwidth is-info"
          onClick={modalOn}
        >
          <i className="fas fa-share-alt mr-2"></i> Bagikan Meeting
        </button>
      ) : (
        <button
          title="Bagikan Meeting"
          className="button is-link"
          onClick={modalOn}
          style={styles.button}
        >
          <i className="fas fa-share-alt"></i>
        </button>
      )}

      {/* modal */}
      <div className={modal ? "modal is-active" : "modal"}>
        <div
          className="modal-background"
          onClick={modalOff}
          style={{ opacity: 0.6 }}
        ></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Bagikan Meeting</p>
            <button
              className="delete"
              aria-label="close"
              onClick={modalOff}
            ></button>
          </header>
          <section className="modal-card-body">
            {/* kode meeting */}
            <div className="field">
              <label className="label">Kode Meeting</label>
              <div className="control has-icons-left">
                <input
                  className="input is-link"
                  type="text"
                  defaultValue={roomName}
                  readOnly
                />
                <span className="icon is-left">
                  <i className="fas fa-user-friends"></i>
                </span>
              </div>
            </div>

            {/* invite link */}
            <div className="field">
              <label className="label">Link Undangan</label>
              <div className="control has-icons-left">
                <input
                  ref={linkRef}
                  className="input is-link"
                  type="text"
                  defaultValue={
                    config.REACT_APP_URL_UTAMA +
                    "/meeting/video/" +
                    roomName +
                    "/jadwal/" +
                    meetingDetail.schedule.id
                  }
                  readOnly
                />
                <span className="icon is-left">
                  <i className="fas fa-link"></i>
                </span>
              </div>
            </div>

            {/* password */}
            {passwordRoom && (
              <div className="field">
                <label className="label">Password</label>
                <div className="control has-icons-left">
                  <input
                    className="input is-link"
                    type="text"
                    defaultValue={passwordRoom}
                    readOnly
                  />
                  <span className="icon is-left">
                    <i className="fas fa-key"></i>
                  </span>
                </div>
              </div>
            )}

            {/* host */}
            {/* {roomDetail.hosts.length > 0 && (
              <div className="field">
                <label className="label">Host</label>
                <div className="control">
                  {roomDetail.hosts.map((host, i) => (
                    <span className="mx-2" key={host + i}>
                      <i className="fas fa-user"></i> {host}
                    </span>
                  ))}
                </div>
              </div>
            )} */}

            {/* kirim email */}
            <article className="message is-link mb-5">
              <div className="message-header">
                <p>Bagikan</p>
              </div>
              <div className="message-body buttons">
                {/* copy */}
                <button
                  title="Salin Link Undangan"
                  className="button"
                  onClick={copyLink}
                >
                  <i className="fas fa-copy"></i>
                </button>

                {/* share email */}
                <ShareEmails roomName={roomName} fullName={fullName} />
              </div>
            </article>

            {/* embed html */}
            <article className="message is-link mb-5">
              <div className="message-header">
                <p>Embed HTML</p>
              </div>
              <div className="message-body">
                {/* textarea */}
                <textarea
                  ref={iframeRef}
                  className="textarea"
                  defaultValue={`<iframe allow="camera; microphone; fullscreen; display-capture" src="${
                    config.REACT_APP_URL_UTAMA + "/meeting/video/" + roomName
                  }" style="height: 1000px; width: 1000px; border: 0px;"></iframe>`}
                  readOnly
                ></textarea>

                {/* button utk copy iframe */}
                <button className="button is-link mt-2" onClick={copyIframe}>
                  Salin
                </button>
              </div>
            </article>
          </section>
        </div>
      </div>
    </>
  );
};

const ShareEmails = ({ roomName, fullName }) => {
  const judul = encodeURIComponent("Gabung BPPT Meeting");
  const isi = encodeURIComponent(
    `${fullName} mengirim undangan kepada anda untuk bergabung ke meeting ${config.REACT_APP_URL_UTAMA}/meeting/video/${roomName}`
  );
  const links = [
    {
      title: "Email",
      href: `mailto:?subject=${judul}&body=${isi}`,
      icon: "fas fa-envelope",
    },
    {
      title: "Gmail",
      href: `https://mail.google.com/mail/?view=cm&fs=1&su=${judul}&body=${isi}`,
      icon: "fab fa-google",
    },
    {
      title: "Outlook",
      href: `https://outlook.office.com/mail/deeplink/compose?subject=${judul}&body=${isi}`,
      icon: "fab fa-microsoft",
    },
    {
      title: "Yahoo",
      href: `https://compose.mail.yahoo.com/?To=&Subj=${judul}&Body=${isi}`,
      icon: "fab fa-yahoo",
    },
    {
      title: "Whatsapp",
      href: `https://api.whatsapp.com/send?text=${isi}`,
      icon: "fab fa-whatsapp",
    },
  ];

  return (
    <>
      {links.map((link) => (
        <a
          key={link.title}
          title={link.title}
          className="button"
          href={link.href}
          target="_blank"
          rel="noopener noreferrer"
          style={{ display: "table-cell" }}
        >
          <i className={link.icon}></i>
        </a>
      ))}
    </>
  );
};

// ubah data detail meeting ke ics detail
export const dataToIcs = (data) => {
  // manipulasi waktu utk diinputkan ke ICalendarLink
  const jamMenitStart = data.schedule.start.split(":");
  const jamMenitEnd = data.schedule.end.split(":");
  const startTime = new Date(
    new Date(
      new Date(data.schedule.startDate).setHours(parseInt(jamMenitStart[0]))
    ).setMinutes(parseInt(jamMenitStart[1]))
  ).toISOString();
  const endTime = new Date(
    new Date(
      new Date(data.schedule.startDate).setHours(parseInt(jamMenitEnd[0]))
    ).setMinutes(parseInt(jamMenitEnd[1]))
  ).toISOString();

  return {
    title: data.title,
    description: data.description,
    startTime,
    endTime,
    location: "Indonesia",
  };
};

const styles = {
  button: {
    width: "35px",
    height: "35px",
  },
};

export default ShareLink;
