import React, { useState } from "react";
import RenderChatHistory from "./RenderChatHistory";

const ChatHistory = ({ chats }) => {
  const [chatOn, setChatOn] = useState(false);
  const toggleChat = () => setChatOn((prevChat) => !prevChat);

  return (
    <div className="card">
      {/* toggle chat history */}
      <div className="card-header is-clickable" onClick={toggleChat}>
        <p className="card-header-title">Chat History</p>
        <p className="card-header-icon" aria-label="more options">
          <span className="icon">
            <i className="fas fa-angle-down" aria-hidden="true"></i>
          </span>
        </p>
      </div>

      {/* chat list */}
      <div className={chatOn ? "card-content" : "card-content is-hidden"}>
        <div className="content" style={{ width: "79vh" }}>
          {chats.length > 0 &&
            chats.map((chat, i) => (
              <RenderChatHistory chat={chat} key={"chat" + i} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default ChatHistory;
