import React, { useEffect, useState } from "react";
import axios from "../../config/axios";

const VerifyEmail = ({ location }) => {
  // ambil url search params
  const token = new URLSearchParams(location.search).get("token");

  // pesan loading & sukses / gagal
  const [pesan, setPesan] = useState("Harap tunggu...");

  useEffect(() => {
    document.title = "Verifikasi Email - BPPT";

    axios
      .post("/verify-email", {
        token,
      })
      .then((response) => {
        // jika response ok
        setPesan(response.data.message);
      })
      .catch((err) => {
        console.log(err);
        window.scrollTo(0, 0);
        if (err.response) {
          err.response.data.message && setPesan(err.response.data.message);
          err.response.data.title && setPesan(err.response.data.title);
        } else {
          setPesan("Maaf terjadi kesalahan");
        }
      });
  }, [token]);

  return <div className="column p-6">{pesan}</div>;
};

export default VerifyEmail;
