import axios from "../../../../config/axios";

const fetchOnlineParticipants = (roomName, jwtToken, dispatchParticipants) => {
  // GET /online-participant
  axios
    .get(`/meeting/${roomName}/online-participant`, {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    })
    .then((res) => {
      const data = res.data.data;
      console.log(
        "fetch online participants succeed",
        data.map((d) => d.jitsiId)
      );

      const initial = data.map((d) => {
        const { author, joined, jitsiId, isSpeaker } = d;
        return {
          id: author.id,
          displayName: author.fullName,
          avatar: author.avatar && author.avatar.imageSmall,
          joinTime: joined,
          jitsiId,
          isSpeaker,
        };
      });

      dispatchParticipants({
        type: "INITIAL_PARTICIPANTS",
        data: initial,
      });
    })
    .catch((err) => console.log("fetch online participants failed", err));
};

export default fetchOnlineParticipants;
