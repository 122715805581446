import React, { useState, useEffect } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import axios from "../../config/axios";
import { Redirect, useParams } from "react-router-dom";
import formatTanggal2 from "../../helpers/formatTanggal2";
import HapusMeeting from "./edit/HapusMeeting";
import formatDate from "../../helpers/formatDate";

const EditMeeting = ({ userData, location }) => {
  // jika durasi ny 0 jam dan 0 menit
  const [durasiError, setDurasiError] = useState(false);

  // atur meeting berulang
  const [meetingBerulang, setMeetingBerulang] = useState({
    tampil: false,
    berulangBerdasarkan: 0,
    hari: [],
    setiapXHari: 1,
    setiapXMinggu: 1,
    tanggalBulan: 1,
    berakhirType: 0,
    berakhirPada: new Date().toISOString(),
    berakhirSetelah: 1,
  });

  // handle perubahan input date di recuring
  const berulangDateChange = (e) => {
    setMeetingBerulang((prevMeetingBerulang) => {
      return {
        ...prevMeetingBerulang,
        [e.target.id]: e.target.value,
      };
    });
  };

  // menentukan recuring true / false
  const meetingBerulangChange = () => {
    setMeetingBerulang((prevMeetingBerulang) => {
      return {
        ...prevMeetingBerulang,
        tampil: !prevMeetingBerulang.tampil,
      };
    });
  };

  // handle perubahan field yg isinya angka
  const berulangBerdasarkanChange = (e) => {
    setMeetingBerulang((prevMeetingBerulang) => {
      return {
        ...prevMeetingBerulang,
        [e.target.id]: parseInt(e.target.value),
      };
    });
  };

  // handle jika checkbox hari ada yg diklik (msh ada error)
  const hariChange = (e) => {
    // cek apa hari sdh ada di state / blm
    const hariAda = meetingBerulang.hari.find((h) => h === e.target.id);

    // jika hari ada di state
    if (hariAda) {
      // hapus hari dr state
      setMeetingBerulang((prevMeetingBerulang) => {
        return {
          ...prevMeetingBerulang,
          hari: prevMeetingBerulang.hari.filter((h) => h !== e.target.id),
        };
      });
    } else {
      // tambah hari ke state
      setMeetingBerulang((prevMeetingBerulang) => {
        return {
          ...prevMeetingBerulang,
          hari: prevMeetingBerulang.hari.concat(e.target.id),
        };
      });
    }
  };

  // atur perubahan berakhir / endType
  const berakhirChange = (e) => {
    setMeetingBerulang((prevMeetingBerulang) => {
      return {
        ...prevMeetingBerulang,
        berakhirType: parseInt(e.target.id),
      };
    });
  };

  // host & participant yg bs dipilih
  const [accounts, setAccounts] = useState([]);

  // utk loading indicator di react select
  const [reactSelectLoading, setReactSelectLoading] = useState(false);

  // onchange handler utk react select
  const reactSelectChange = (e) => {
    if (!reactSelectLoading) {
      setReactSelectLoading(true);

      axios
        .get("/accounts?query=" + e, {
          headers: {
            Authorization: "Bearer " + userData.data.jwtToken,
          },
        })
        .then((res) => {
          const serverAccounts = res.data.data.map((account) => {
            return {
              value: account.id,
              label: `${account.fullName} / ${account.email}`,
              userId: account.id,
            };
          });

          setAccounts(serverAccounts);
          setReactSelectLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setReactSelectLoading(false);
        });
    }
  };

  // state yg akan disubmit oleh form
  const [submit, setSubmit] = useState(defaultSubmit);

  // button
  const [button, setButton] = useState(false);
  const [redirect, setRedirect] = useState(false);

  // pesan sukses / error
  const [error, setError] = useState("");
  const [errorFields, setErrorFields] = useState({});
  const [success, setSuccess] = useState("");

  // menangani edit room (generate isi setiap field)
  const { roomName } = useParams();

  useEffect(() => {
    // ubah title saat render
    document.title = "Edit Meeting " + roomName + " - BPPT";
  }, [roomName]);

  // menampilkan loading saat data meeting sedang di fetch
  const [editLoading, setEditLoading] = useState(true);

  // fetch data meeting
  useEffect(() => {
    if (roomName) {
      axios
        .get("/meeting/" + roomName, {
          headers: {
            Authorization: "Bearer " + userData.data.jwtToken,
          },
        })
        .then((res) => {
          console.log(res.data.data);

          // manipulasi jam utk ditampilkan di halaman edit
          const start = res.data.data.schedule.start.split(":");
          const end = res.data.data.schedule.end.split(":");
          const startDateJam = parseInt(start[0]);
          const startDateMenit = parseInt(start[1]);
          const endMinusStart =
            parseInt(end[0]) * 60 +
            parseInt(end[1]) -
            startDateJam * 60 +
            startDateMenit;
          const durationJam = Math.floor(endMinusStart / 60);
          const durationMenit = endMinusStart % 60;

          // ambil data dr meetingParticipant
          const hostAndParticipant = res.data.data.meetingParticipant;
          const hosts = hostAndParticipant.filter((host) => host.role === 0);
          const participants = hostAndParticipant.filter(
            (participant) => participant.role === 1
          );

          // generate default value setiap field kecuali schedule
          setSubmit((prevSubmit) => {
            return {
              ...prevSubmit,
              ...res.data.data,
              startDateJam,
              startDateMenit,
              durationJam,
              durationMenit,
              hosts: hosts.map((host) => {
                return {
                  ...host,
                  value: host.author.id,
                  label: host.author.fullName,
                  userId: host.author.id,
                };
              }),
              participants: participants.map((participant) => {
                return {
                  ...participant,
                  value: participant.author.id,
                  label: participant.author.fullName,
                  userId: participant.author.id,
                };
              }),
            };
          });

          // manipulasi occurrenceValues utk ditampilkan sbg hari
          const haris = res.data.data.occurrenceValues
            ? res.data.data.occurrenceValues.split(",").map((hari) => hari)
            : [];

          // generate field schedule
          setMeetingBerulang({
            tampil: res.data.data.isRecuring,
            berulangBerdasarkan: res.data.data.interval,
            hari: haris,
            setiapXHari: res.data.data.intervalValue
              ? res.data.data.intervalValue
              : 1,
            setiapXMinggu: res.data.data.intervalValue
              ? res.data.data.intervalValue
              : 1,
            tanggalBulan: res.data.data.intervalValue
              ? res.data.data.intervalValue
              : 1,
            berakhirType: res.data.data.endType,
            berakhirPada:
              res.data.data.endType === 0
                ? res.data.data.endValue
                : new Date().toISOString(),
            berakhirSetelah:
              res.data.data.endType === 1
                ? res.data.data.endValue.toString()
                : 1,
          });

          // GET password meeting
          axios
            .get(`/meeting/${roomName}/password`, {
              headers: {
                Authorization: "Bearer " + userData.data.jwtToken,
              },
            })
            .then((res) => {
              // isi field password
              setSubmit((prevSubmit) => {
                return {
                  ...prevSubmit,
                  password: res.data.data,
                };
              });

              // hilangkan loading indicator
              setEditLoading(false);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => {
          setEditLoading(false);
          window.scrollTo(0, 0);
          console.log(err);
          if (err.response) {
            err.response.data.message && setError(err.response.data.message);
            err.response.data.title && setError(err.response.data.title);
          } else {
            setError("Maaf terjadi kesalahan");
          }
        });
    }
  }, [roomName]); // eslint-disable-line react-hooks/exhaustive-deps

  // jika form disubmit
  const editMeeting = (e) => {
    e.preventDefault();

    if (
      submit.durationJam.toString() === "0" &&
      submit.durationMenit.toString() === "0"
    ) {
      setDurasiError(true);
    } else {
      // disable dulu buttonny saat fetch api
      if (!button) {
        setButton(true);

        // lakukan fetch mulai dr skrg
        // memisahkan bbrp property object yg ga terpakai
        const {
          durationJam,
          durationMenit,
          startDateJam,
          startDateMenit,
          startDate,
          hosts,
          participants,
          maxParticipant,
          pendingParticipant,
          ...ygDisubmit
        } = submit;

        // tambahkan angka nol jika angka krg dr 10
        const formatNol = (angka) => {
          return angka < 10 ? "0" + angka : angka;
        };

        // manipulasi jam utk mengisi field start & end di db
        const jamMulai = parseInt(startDateJam) * 60 + parseInt(startDateMenit);
        const durasi = parseInt(durationJam) * 60 + parseInt(durationMenit);
        const endJam = Math.floor((jamMulai + durasi) / 60);
        const endMenit = (jamMulai + durasi) % 60;
        const end = `${formatNol(endJam)}:${formatNol(endMenit)}`;
        const start = `${formatNol(startDateJam)}:${formatNol(startDateMenit)}`;

        // mengisi field meetingParticipant
        let meetingParticipant = [];
        hosts &&
          hosts.map((host) =>
            meetingParticipant.push({
              id: host.id,
              userId: host.userId,
              role: 0,
              isBanned: false,
              isPending: false,
              isSpeaker: true,
            })
          );
        participants &&
          participants.map((participant) =>
            meetingParticipant.push({
              id: participant.id,
              userId: participant.userId,
              role: 1,
              isBanned: false,
              isPending: false,
            })
          );

        let recuringDetail = {};
        // jika room recuring
        if (meetingBerulang.tampil) {
          // menentukan intervalValue
          let intervalValue = submit.intervalValue;
          switch (meetingBerulang.berulangBerdasarkan) {
            case 0:
              intervalValue = meetingBerulang.setiapXHari;
              break;

            case 1:
              intervalValue = meetingBerulang.setiapXMinggu;
              break;

            case 2:
              intervalValue = meetingBerulang.tanggalBulan;
              break;

            default:
              intervalValue = submit.intervalValue;
              break;
          }

          recuringDetail.isRecuring = meetingBerulang.tampil;
          recuringDetail.interval = meetingBerulang.berulangBerdasarkan; // diisi dg 0 (daily) / 1 (weekly) / 2 (monthly)
          recuringDetail.intervalValue = intervalValue; // diisi oleh field Setiap (kecuali Bulan; dia diisi field Tanggal)
          recuringDetail.occurrenceValues = meetingBerulang.hari.toString(); // diisi oleh field hari dalam 1 minggu
          recuringDetail.endType = meetingBerulang.berakhirType; // ada 2 macam
          recuringDetail.endValue =
            meetingBerulang.berakhirType === 0
              ? new Date(meetingBerulang.berakhirPada).toISOString()
              : meetingBerulang.berakhirSetelah;
        }

        const pendingParticipantUpdated = pendingParticipant.map(
          (participant) => {
            return {
              ...participant,
              userId: participant.author.id,
            };
          }
        );

        // update meeting
        axios
          .put(
            "/meeting/" + roomName,
            {
              // roomName = hapus spasi di title dan hurufny dikecilkan smua
              ...ygDisubmit,
              roomName: submit.title
                .toLowerCase()
                .replace(/(?!\w|\s)./g, "")
                .replace(/\s/g, ""),
              schedule: {
                ...submit.schedule,
                startDate: startDate
                  ? new Date(startDate).toISOString()
                  : new Date(ygDisubmit.schedule.startDate).toISOString(),
                start,
                end,
              },
              maxParticipant: parseInt(maxParticipant),
              meetingParticipant,
              pendingParticipant: pendingParticipantUpdated,
            },
            {
              headers: {
                Authorization: "Bearer " + userData.data.jwtToken,
              },
            }
          )
          .then((res) => {
            console.log("edit meeting berhasil", res);
            setSuccess("Edit room berhasil");
            setRedirect(true);
            window.scrollTo(0, 0);
          })
          .catch((err) => {
            setButton(false);
            window.scrollTo(0, 0);
            console.log(err);
            if (err.response) {
              err.response.data.message && setError(err.response.data.message);
              err.response.data.title && setError(err.response.data.title);
              err.response.data.errors &&
                setErrorFields(err.response.data.errors);
            } else {
              setError("Maaf terjadi kesalahan");
            }
          });
      }
    }
  };

  // onchange handler utk setiap input kecuali select host & participant
  const handleChange = (e) => {
    setSubmit({
      ...submit,
      [e.target.id]: e.target.value,
    });
  };

  // host change handler
  const hostChange = (e) => {
    setSubmit({
      ...submit,
      hosts: e,
    });
  };

  // participant change handler
  const participantChange = (e) => {
    setSubmit({
      ...submit,
      participants: e,
    });
  };

  // jika checkbox dicentang
  const checkChange = (e) => {
    setSubmit({
      ...submit,
      [e.target.id]: !submit[e.target.id],
    });
  };

  // jika radio button utk meetingType berubah
  const meetingTypeChange = (e) => {
    setSubmit((prevSubmit) => {
      return {
        ...prevSubmit,
        meetingType: parseInt(e.target.id),
      };
    });
  };

  if (!location.state?.isHost) {
    // jika akses lewat url tendang
    return (
      <Redirect
        to={{
          pathname:
            userData.data.role === "Admin"
              ? "/meeting/atur"
              : "/meeting/hari-ini",
          state: {
            pesanGagal: "Silahkan edit meeting melalui klik tombol Edit",
          },
        }}
      />
    );
  } else if (userData.data.role === "User" && !location.state?.isHost) {
    // jika bukan Admin / Host tendang
    return (
      <Redirect
        to={{
          pathname: "/meeting/hari-ini",
          state: {
            pesanGagal: "Maaf, anda tidak berhak edit meeting",
          },
        }}
      />
    );
  } else if (redirect) {
    // jika sdh berhasil edit meeting
    return (
      <Redirect
        to={{
          pathname:
            userData.data.role === "Admin"
              ? "/meeting/atur"
              : "/meeting/hari-ini",
          state: { pesanSukses: success },
        }}
      />
    );
  }

  return (
    <form className="column p-5" onSubmit={editMeeting}>
      {roomName && editLoading ? (
        <progress className="progress is-small is-info" max="100"></progress>
      ) : error ? (
        // peringatan error
        <div className="my-5">
          {error && <p className="notification is-danger">{error}</p>}
        </div>
      ) : (
        <>
          <p className="is-size-3 mb-5">Edit Meeting</p>

          {/* judul */}
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Judul Meeting</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    id="title"
                    placeholder="Judul Meeting"
                    onChange={handleChange}
                    disabled={button}
                    defaultValue={submit.title}
                  />
                </div>

                {/* error per field */}
                {errorFields.Title &&
                  errorFields.Title.map((error, i) => (
                    <small className="help is-danger" key={"title" + i}>
                      {error}
                    </small>
                  ))}
              </div>
            </div>
          </div>

          {/* description */}
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Deskripsi</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <textarea
                    className="textarea"
                    placeholder="Jelaskan topiknya"
                    id="description"
                    onChange={handleChange}
                    disabled={button}
                    defaultValue={submit.description}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          {/* start, tgl & jam */}
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Mulai Meeting</label>
            </div>
            <div className="field-body">
              {/* tanggal */}
              <div className="field">
                <div className="control">
                  <label
                    htmlFor="startDate"
                    style={{
                      position: "absolute",
                      width: "80%",
                      height: "100%",
                      backgroundColor: "whitesmoke",
                      zIndex: 1,
                      padding: "7px 10px",
                      border: "1px solid whitesmoke",
                      borderRight: "none",
                      borderRadius: 5,
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                  >
                    {submit.startDate
                      ? formatTanggal2(submit.startDate)
                      : formatTanggal2(submit.schedule.startDate)}
                  </label>
                  <input
                    className="input"
                    type="date"
                    min={formatDate(new Date())}
                    id="startDate"
                    onChange={handleChange}
                    disabled
                    defaultValue={
                      submit.schedule.startDate &&
                      formatDate(submit.schedule.startDate)
                    }
                  />
                </div>
              </div>

              {/* jam */}
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Jam</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          id="startDateJam"
                          onChange={handleChange}
                          disabled={button}
                          defaultValue={submit.startDateJam}
                        >
                          {hours.map((hour, i) => (
                            <option value={hour} key={"startDateJam" + i}>
                              {hour}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* menit */}
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Menit</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          id="startDateMenit"
                          onChange={handleChange}
                          disabled={button}
                          defaultValue={submit.startDateMenit}
                        >
                          <option value={0}>0</option>
                          <option value={15}>15</option>
                          <option value={30}>30</option>
                          <option value={45}>45</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* duration */}
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Durasi</label>
            </div>
            <div className="field-body">
              {/* jam */}
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Jam</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          id="durationJam"
                          onChange={handleChange}
                          disabled={button}
                          defaultValue={submit.durationJam}
                        >
                          <option value={0}>0</option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* menit */}
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Menit</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          id="durationMenit"
                          onChange={handleChange}
                          disabled={button}
                          defaultValue={submit.durationMenit}
                        >
                          <option value={0}>0</option>
                          <option value={15}>15</option>
                          <option value={30}>30</option>
                          <option value={45}>45</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {durasiError && (
                <small className="help is-danger mt-2 ml-3">
                  Durasi tidak boleh 0
                </small>
              )}
            </div>
          </div>

          {/* password */}
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Password</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    type="password"
                    placeholder="Password (tidak wajib)"
                    id="password"
                    onChange={handleChange}
                    disabled={button}
                    defaultValue={submit.password}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* video */}
          {/* <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Video</label>
            </div>
            <div className="field-body">
              <div className="field is-grouped is-grouped-centered">
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Host</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="radio">
                          <input
                            type="checkbox"
                            id="isHostVideoAvailable"
                            className="mr-3"
                            onChange={checkChange}
                            checked={submit.isHostVideoAvailable}
                            disabled={button}
                          />
                          On
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="field is-grouped is-grouped-centered">
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Partisipan</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="radio">
                          <input
                            type="checkbox"
                            id="isParticipantVideoAvailable"
                            className="mr-3"
                            onChange={checkChange}
                            checked={submit.isParticipantVideoAvailable}
                            disabled={button}
                          />
                          On
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* saat meeting dimulai */}
          {/* <div className="field is-horizontal">
            <div className="field-label">
              <label className="label">Saat Meeting Dimulai</label>
            </div>
            <div className="field-body">
              <div className="field is-narrow">
                <div className="control">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="mr-3"
                      id="isMuteParticipantUponEntry"
                      onChange={checkChange}
                      disabled={button}
                      checked={submit.isMuteParticipantUponEntry}
                    />
                    Matikan suara saat partisipan masuk
                  </label>
                  <br />
                </div>
              </div>
            </div>
          </div> */}

          {/* isAdmit */}
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Ada Persetujuan?</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <label className="radio">
                    <input
                      type="checkbox"
                      id="isAdmit"
                      className="mr-3 mt-2"
                      onChange={checkChange}
                      disabled={button}
                      checked={submit.isAdmit}
                    />
                    Ya
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* isPublic */}
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Meeting Publik?</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <label className="radio">
                    <input
                      type="checkbox"
                      id="isPublic"
                      className="mr-3 mt-2"
                      onChange={checkChange}
                      disabled={button}
                      checked={submit.isPublic}
                    />
                    Ya
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* isRecuring */}
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Meeting Berulang?</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <label className="radio">
                    <input
                      type="checkbox"
                      id="isRecuring"
                      className="mr-3 mt-3"
                      onChange={meetingBerulangChange}
                      disabled
                      checked={submit.isRecuring}
                    />
                    Ya
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">{/* kosong utk memberi jarak */}</label>
            </div>
            <div className="field-body">
              <div className="field">
                {/* berulang berdasarkan */}
                {meetingBerulang.tampil && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label" style={{ width: 100 }}>
                        Berdasarkan
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field is-narrow">
                        <div className="control">
                          <div className="select is-fullwidth">
                            <select
                              id="berulangBerdasarkan"
                              onChange={berulangBerdasarkanChange}
                              disabled
                              defaultValue={meetingBerulang.berulangBerdasarkan}
                            >
                              <option value={0}>Hari</option>
                              <option value={1}>Minggu</option>
                              <option value={2}>Bulan</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* setiap hari */}
                {meetingBerulang.berulangBerdasarkan === 0 &&
                  meetingBerulang.tampil && (
                    <div className="field is-horizontal mt-3">
                      <div className="field-label is-normal">
                        <label className="label" style={{ width: 100 }}>
                          Setiap
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field is-narrow">
                          <div className="control">
                            <div className="select is-fullwidth">
                              <select
                                id="setiapXHari"
                                onChange={berulangBerdasarkanChange}
                                disabled
                                defaultValue={meetingBerulang.setiapXHari}
                              >
                                {oneToFifteen.map((angka) => (
                                  <option
                                    value={angka}
                                    key={"setiapXHari" + angka}
                                  >
                                    {angka}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <p className="mt-2">Hari</p>
                      </div>
                    </div>
                  )}

                {/* setiap minggu */}
                {meetingBerulang.berulangBerdasarkan === 1 &&
                  meetingBerulang.tampil && (
                    <>
                      <div className="field is-horizontal mt-3">
                        <div className="field-label is-normal">
                          <label className="label" style={{ width: 100 }}>
                            Setiap
                          </label>
                        </div>
                        <div className="field-body">
                          <div className="field is-narrow">
                            <div className="control">
                              <div className="select is-fullwidth">
                                <select
                                  id="setiapXMinggu"
                                  onChange={berulangBerdasarkanChange}
                                  disabled
                                  defaultValue={meetingBerulang.setiapXMinggu}
                                >
                                  {oneToFifteen.map((angka) => (
                                    <option
                                      value={angka}
                                      key={"setiapXMinggu" + angka}
                                    >
                                      {angka}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>

                          <p className="mt-2">Minggu</p>
                        </div>
                      </div>

                      <div className="field is-horizontal mt-3">
                        <div className="field-label is-normal">
                          <label className="label" style={{ width: 100 }}>
                            Hari?
                          </label>
                        </div>
                        <div className="field-body">
                          <div className="field is-narrow">
                            <div className="control">
                              {/* checkbox */}
                              {days.map((day, i) => (
                                <label className="checkbox" key={day + i}>
                                  <input
                                    type="checkbox"
                                    className="mx-2"
                                    id={i}
                                    onChange={hariChange}
                                    checked={meetingBerulang.hari.find(
                                      (h) => h === i.toString()
                                    )}
                                    disabled
                                  />
                                  {day}
                                </label>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                {/* setiap bulan */}
                {meetingBerulang.berulangBerdasarkan === 2 &&
                  meetingBerulang.tampil && (
                    <div className="field is-horizontal mt-3">
                      <div className="field-label is-normal">
                        <label className="label" style={{ width: 100 }}>
                          Tanggal
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field is-narrow">
                          <div className="control">
                            <div className="select is-fullwidth">
                              <select
                                id="tanggalBulan"
                                onChange={berulangBerdasarkanChange}
                                disabled
                                defaultValue={meetingBerulang.tanggalBulan}
                              >
                                {tanggals.map((tanggal) => (
                                  <option
                                    value={tanggal}
                                    key={"tanggalBulan" + tanggal}
                                  >
                                    {tanggal}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                {/* meeting berulang berakhir pada */}
                {meetingBerulang.tampil && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label" style={{ width: 100 }}>
                        Berakhir pada
                      </label>
                    </div>
                    <div className="field-body mt-3">
                      <div className="field">
                        <div className="control mt-2">
                          <input
                            type="radio"
                            name="berakhirType"
                            id={0}
                            onChange={berakhirChange}
                            checked={meetingBerulang.berakhirType === 0}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="field">
                        <div className="control">
                          <label
                            htmlFor="berakhirPada"
                            style={{
                              position: "absolute",
                              width: "80%",
                              height: "100%",
                              backgroundColor: "whitesmoke",
                              zIndex: 1,
                              padding: "7px 10px",
                              border: "1px solid whitesmoke",
                              borderRight: "none",
                              borderRadius: 5,
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                            }}
                          >
                            {meetingBerulang.berakhirPada
                              ? formatTanggal2(meetingBerulang.berakhirPada)
                              : formatTanggal2(submit.schedule.startDate)}
                          </label>
                          <input
                            name="berakhirPada"
                            className="input"
                            type="date"
                            min={formatDate(new Date())}
                            id="berakhirPada"
                            onChange={berulangDateChange}
                            defaultValue={formatDate(submit.schedule.startDate)}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="field is-horizontal">
                        <div
                          className="field-label is-normal"
                          style={{ marginTop: "-10px" }}
                        >
                          <label className="label">Berakhir setelah</label>
                        </div>
                        <div className="field-body">
                          <div className="field">
                            <div className="control mt-2">
                              <input
                                type="radio"
                                name="berakhirType"
                                id={1}
                                onChange={berakhirChange}
                                checked={meetingBerulang.berakhirType === 1}
                                disabled
                              />
                            </div>
                          </div>

                          <div className="field">
                            <div className="control">
                              <div className="select is-fullwidth">
                                <select
                                  id="berakhirSetelah"
                                  onChange={berulangBerdasarkanChange}
                                  defaultValue={meetingBerulang.berakhirSetelah}
                                  disabled
                                >
                                  {oneToFifteen.map((angka) => (
                                    <option
                                      value={angka}
                                      key={"berakhirSetelah" + angka}
                                    >
                                      {angka}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <p className="mt-2 ml-auto">Pertemuan</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* meetingType */}
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Tipe Meeting</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <label className="radio">
                    <input
                      type="radio"
                      name="meetingType"
                      id={0}
                      className="mr-3 mt-3"
                      onChange={meetingTypeChange}
                      disabled={button}
                      checked={submit.meetingType === 0}
                    />
                    Meeting Biasa{" "}
                    <small className="ml-2 has-text-grey">
                      (Partisipan Maksimal = 40)
                    </small>
                  </label>

                  <br />

                  <label className="radio">
                    <input
                      type="radio"
                      name="meetingType"
                      id={1}
                      className="mr-3 mt-3"
                      onChange={meetingTypeChange}
                      disabled={button}
                      checked={submit.meetingType === 1}
                    />
                    Meeting Konferensi{" "}
                    <small className="ml-2 has-text-grey">
                      (Partisipan Maksimal {">"} 40)
                    </small>
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* max participant */}
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Jumlah Partisipan Maksimal</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    id="maxParticipant"
                    placeholder="Jumlah partisipan maksimal"
                    onChange={handleChange}
                    disabled={button}
                    defaultValue={submit.maxParticipant}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* host */}
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Host</label>
            </div>
            <div className="field-body">
              <div className="field">
                <Select
                  defaultValue={submit.hosts}
                  isMulti
                  options={accounts}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={hostChange}
                  isDisabled={button}
                  onInputChange={reactSelectChange}
                  isLoading={reactSelectLoading}
                  placeholder="Cari nama user"
                />
              </div>
            </div>
          </div>

          {/* participant */}
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Partisipan</label>
            </div>
            <div className="field-body">
              <div className="field">
                <Select
                  defaultValue={submit.participants}
                  isMulti
                  options={accounts}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={participantChange}
                  isDisabled={button}
                  onInputChange={reactSelectChange}
                  isLoading={reactSelectLoading}
                  placeholder="Cari nama user"
                />
              </div>
            </div>
          </div>

          {/* button */}
          <div className="field is-horizontal">
            <div className="field-label">{/* Left empty for spacing */}</div>

            <div className="field-body">
              <div className="field">
                <div className="control">
                  <button className="button is-link" disabled={button}>
                    Simpan
                  </button>

                  {userData.data.role === "Admin" && (
                    <HapusMeeting
                      roomName={roomName}
                      isRecuring={submit.isRecuring}
                      jwtToken={userData.data.jwtToken}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </form>
  );
};

// utk Duration
let hours = [];
for (let i = 0; i < 24; i++) {
  hours.push(i);
}

// utk hari & minggu
let oneToFifteen = [];
for (let i = 1; i < 16; i++) {
  oneToFifteen.push(i);
}

// tanggals
let tanggals = [];
for (let i = 1; i < 32; i++) {
  tanggals.push(i);
}

// minggu - sabtu
let days = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"];

// nilai default semua field (berguna utk reset form)
const defaultSubmit = {
  roomName: "",
  title: "",
  description: "",
  password: "",
  isRecuring: false,
  // data baru
  schedule: {
    startDate: new Date().toISOString(),
    start: "",
    end: "",
  },
  interval: 0, // diisi dg 0 (daily) / 1 (weekly) / 2 (monthly)
  intervalValue: 0, // diisi oleh field Setiap (kecuali Bulan, dia diisi field Tanggal)
  occurrenceValues: "string", // diisi oleh field hari dalam 1 minggu
  endType: 0, // ada 2 macam
  endValue: "string",
  // data baru end
  calendar: "blm tau isinya",
  isHostVideoAvailable: true,
  isParticipantVideoAvailable: true,
  isWaitingRoomEnabled: true,
  isJoinBeforeHostEnabled: false,
  isMuteParticipantUponEntry: false,
  isRecordAutomatically: false,
  isPublic: true,
  isAdmit: false,
  maxParticipant: 40,
  meetingParticipant: [],
  // state khusus yg akan dimanipulasi, tdk diinput ke POST
  durationJam: 0,
  durationMenit: 45,
  startDateJam: 10,
  startDateMenit: 0,
  meetingType: 0,
};

const mapStateToProps = (state) => {
  return {
    userData: state,
  };
};

export default connect(mapStateToProps)(EditMeeting);
