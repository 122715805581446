export const config = {
  REACT_APP_URL_UTAMA: "https://telecon.pluginesia.com",
  REACT_APP_URL_API: "https://api-telecon.pluginesia.com/api",
  REACT_APP_URL_JITSI: "https://jitsi-telecon.pluginesia.com",
  REACT_APP_URL_JITSI_NO_HTTP: "jitsi-telecon.pluginesia.com",
  REACT_APP_URL_GAMBAR: "https://asset-telecon.pluginesia.com/",
  REACT_APP_URL_SLIDE: "https://api-telecon.pluginesia.com/",
  REACT_APP_URL_SIGNALR: "https://hub-telecon.pluginesia.com",
  REACT_APP_URL_LOGOUT_CAS: "https://api-telecon.pluginesia.com/cas/logout.php",
  REACT_APP_URL_LOGIN_CAS: "https://api-telecon.pluginesia.com/cas/authenticate.php",
  REACT_APP_GOOGLE_CLIENTID:
    "839309000124-qu8l3vds61ll8vluu57qaqkiuskj130e.apps.googleusercontent.com",
  REACT_APP_GOOGLE_SITEKEY: "6LdVSggaAAAAACCL8zDN96cznE1n5U5NFbvEsFeY",
  REACT_APP_GOOGLE_APIKEY_GCALENDAR: "AIzaSyDSrMR_o5qm6fkEat6DISolbdbpsbuqw3I",
  REACT_APP_MICROSOFT_APP_ID: "598f3147-43b6-45ab-a6f8-db1821ae4793",
  REACT_APP_BPPT_MAIL: "https://mail.bppt.go.id",
  REACT_APP_MODE: "development",
};
