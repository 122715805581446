import React from "react";

const Password = ({ handleChange, button }) => {
  return (
    <div className="field">
      <label className="label">Password</label>
      <div className="control">
        <input
          className="input is-rounded"
          id="password"
          type="password"
          placeholder="Password"
          onChange={handleChange}
          disabled={button}
        />
      </div>
    </div>
  );
};

export default Password;
