import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

const Dashboard = () => {
  // ubah title
  useEffect(() => {
    document.title = "Dashboard - BPPT";
  }, []);

  // menampilkan pesan error jika room tdk ada
  const [redirect, setRedirect] = useState(false); // ke jitsi
  const [error, setError] = useState(false);
  const [roomName, setRoomName] = useState("");
  const updateRoomName = (e) => setRoomName(e.target.value);

  // POST ke /meeting/join
  const gabungMeeting = (e) => {
    e.preventDefault();

    if (!roomName) {
      setError(true);
    } else {
      setRedirect(true);
    }
  };

  if (redirect) {
    return <Redirect to={"/meeting/video/" + roomName} />;
  }

  return (
    <div className="column p-5">
      <p className="is-size-3 mb-5">Gabung Meeting</p>

      <form className="columns" onSubmit={gabungMeeting}>
        <div className="column">
          <input
            className="input"
            type="text"
            placeholder="Masukkan kode meeting"
            onChange={updateRoomName}
          />
        </div>
        <div className="column">
          <button className="button is-link" onClick={gabungMeeting}>
            Gabung Meeting
          </button>
        </div>
      </form>

      {error && <small className="help is-danger">Kode room kosong</small>}
    </div>
  );
};

export default Dashboard;
