import React from "react";
import { Link, withRouter } from "react-router-dom";
import Notif from "./nav/Notif";
import { useSelector, useDispatch } from "react-redux";
import { config } from "../config";

const Nav = ({ location, setSidebarOn }) => {
  const pathname = location.pathname;
  const userData = useSelector((state) => state);
  const dispatch = useDispatch();
  const changeUserData = () => dispatch({ type: "LOGOUT" });

  // jika tombol logout ditekan
  const clearJwt = (e) => {
    e.preventDefault();
    localStorage.removeItem("reduxState");
    changeUserData();
    // jika login pk akun cas
    if (userData.data.source === "CAS") {
      // logout jg di auth.bppt.go.id
      window.location = config.REACT_APP_URL_LOGOUT_CAS;
    }
  };

  const toggleSidebar = (e) => {
    e.preventDefault();
    setSidebarOn((prevSidebar) => !prevSidebar);
  };

  return !pathname.includes("/bels/") ? (
    <nav
      className="navbar"
      role="navigation"
      aria-label="main navigation"
      style={styles.nav}
    >
      <div className="container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item">
            <img src="/img/nav/meeting_logo.png" alt="bppt-meeting" />
          </Link>

          {userData.data.jwtToken && (
            <a
              role="button"
              className="navbar-burger burger"
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
              href="/#"
              onClick={toggleSidebar}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          )}
        </div>

        <div id="navbarBasicExample" className="navbar-menu">
          {userData.data.jwtToken ? (
            <>
              <div className="navbar-end">
                <div className="navbar-item">
                  <div className="buttons">
                    {!pathname.includes("/video/") && <Notif />}

                    <Link to="/" className="mr-5">
                      <img src="/img/nav/bppt_logo.png" alt="bppt-logo" />
                    </Link>

                    <a
                      href="/#"
                      className="has-text-white mb-2"
                      onClick={clearJwt}
                    >
                      Logout{" "}
                      <i className="fas fa-sign-out-alt has-text-danger ml-2"></i>
                    </a>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="navbar-end">
              <div className="navbar-item">
                <div className="buttons">
                  <Link to="/register" className="button is-danger">
                    Register
                  </Link>

                  <Link to="/login" className="button is-link">
                    Log in
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  ) : (
    <div className="has-background-white"></div>
  );
};

export const styles = {
  nav: {
    backgroundImage: "linear-gradient(to right, #f9202e, #f98e4f)",
  },
};

export default withRouter(Nav);
