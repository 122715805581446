import React, { useEffect, useState } from "react";
import { useParams, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  formatDataFetchStatistik,
  formatDataPartisipan,
  formatDataStatistik,
} from "./statistik/helper";
import DownloadExcel from "./statistik/DownloadExcel";
import axios from "../../config/axios";
import dayjs from "dayjs";
import "dayjs/locale/id";
dayjs.locale("id");

const Statistik = () => {
  const { roomName, scheduleId } = useParams();
  const userData = useSelector((state) => state);
  const [error, setError] = useState("");
  const [statistik, setStatistik] = useState([]);
  const [fetchStatistik, setFetchStatistik] = useState({
    meetingLog: [],
    participatedUser: [],
  });

  useEffect(() => {
    document.title = "Statistik " + roomName + " - BPPT";

    const fetchData = () => {
      // data jadwal, pelaksanaan, kehadiran
      axios
        .get(`/meeting/${roomName}/summary/${scheduleId}`, {
          headers: {
            Authorization: "Bearer " + userData.data.jwtToken,
          },
        })
        .then((res) => {
          const dataStatistik = res.data.data;
          console.log("statistik nih", dataStatistik);
          setStatistik(formatDataStatistik(roomName, dataStatistik));
          setFetchStatistik(formatDataFetchStatistik(roomName, dataStatistik));

          // GET /online-participant
          axios
            .get(`/meeting/${roomName}/online-participant`, {
              headers: {
                Authorization: "Bearer " + userData.data.jwtToken,
              },
            })
            .then((res) => {
              const data = res.data.data;
              const onlineParticipants = data.map((d) => {
                return {
                  id: d.author.id,
                  fullName: d.author.fullName,
                };
              });
              console.log("GET online participant succeed", data);
              setStatistik((prevStatistik) =>
                prevStatistik.map((stat) =>
                  stat.subtitle === "Kondisi Meeting Saat Ini"
                    ? {
                        subtitle: stat.subtitle,
                        content: [
                          ...stat.content,
                          {
                            title: "Jumlah Partisipan",
                            value: data.length,
                          },
                          {
                            title: "Daftar Partisipan",
                            value: formatDataPartisipan(onlineParticipants),
                          },
                        ],
                      }
                    : stat
                )
              );
            })
            .catch((err) => {
              console.log("GET online participant failed", err);
              window.scrollTo(0, 0);
              if (err.response) {
                err.response.data.message &&
                  setError(err.response.data.message);
                err.response.data.title && setError(err.response.data.title);
              } else {
                setError("Maaf terjadi kesalahan");
              }
            });
        })
        .catch((err) => {
          window.scrollTo(0, 0);
          console.log(err);
          if (err.response) {
            err.response.data.message && setError(err.response.data.message);
            err.response.data.title && setError(err.response.data.title);
          } else {
            setError("Maaf terjadi kesalahan");
          }
        });
    };

    const loop = setInterval(fetchData, 60000);

    // run lgsg tanpa nunggu 1 menit (ini yg pertama x di run)
    fetchData();

    return () => clearInterval(loop);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // pindah ke halaman login jika bkn admin
  if (userData.data.role !== "Admin") {
    return <Redirect to="/meeting?page=1" />;
  }

  return (
    <div className="column p-5">
      <small className="has-text-grey">
        Halaman ini akan refresh otomatis setiap 1 menit
      </small>

      <p className="is-size-3">Statistik {roomName}</p>

      {(fetchStatistik.meetingLog.length > 0 ||
        fetchStatistik.participatedUser.length > 0) && (
        <DownloadExcel fetchStatistik={fetchStatistik} roomName={roomName} />
      )}

      {/* peringatan error */}
      <div className="my-5">
        {error && <p className="notification is-danger">{error}</p>}
      </div>

      {statistik.length > 0 &&
        statistik.map((stat, i) => (
          <div key={"field" + i}>
            <p className="is-size-3 has-text-centered notification is-info my-5">
              {stat.subtitle}
            </p>
            {stat.content.map((cont, i) => (
              <RenderField
                title={cont.title}
                value={cont.value}
                key={i.toString()}
              />
            ))}
          </div>
        ))}
    </div>
  );
};

const RenderField = ({ title, value }) => {
  return (
    <div className="columns is-mobile">
      <div className="column has-text-right has-text-weight-bold">{title}</div>
      <div className="column">{value}</div>
    </div>
  );
};

export default Statistik;
