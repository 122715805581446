import React, { useEffect, useState } from "react";
import VideoPlayer from "react-video-js-player";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { HubConnectionBuilder } from "@microsoft/signalr";

const TestMeeting1 = () => {
  const { roomName } = useParams();
  const [conn, setConn] = useState(0);

  useEffect(() => {
    var retryCount = 0;
    var retryDelay = [5000, 10000, 15000];
    const connection = new HubConnectionBuilder()
      .withUrl(
        "https://api-conf.bppt.go.id/hub/super-duper-important-dev-test?roomName=" +
          roomName
      )
      .build();

    connection.serverTimeoutInMilliseconds = 1800000;

    // jika ada error
    connection.on("onError", function (message) {
      console.log(message);
      connection.stop();
      loop();
    });

    // utk mendapatkan jumlah partisipan
    connection.on("onActiveConnection", function (jmlConnection) {
      console.log("onActiveConnection nih", jmlConnection);
      setConn(jmlConnection);
    });

    const callSignalR = () => {
      console.log("call signalr");

      connection
        .start()
        .then(() => {
          console.log("signalR chat started");
          connection
            .invoke("ActiveConnection", roomName)
            .then(() => console.log("ActiveConnection succeed"))
            .catch((err) => {
              console.log("ActiveConnection failed", err);
              connection.stop();
              loop();
            });
        })
        .catch((err) => {
          console.log("signalR error", err);
          connection.stop();
          loop();
        });
    };

    const loop = () => {
      if (retryCount === retryDelay.length) {
        alert("Gagal terhubung dengan server, harap refresh halaman");
        window.location.reload();
      }

      console.log("call loop");
      setTimeout(callSignalR, retryDelay[retryCount]);
      retryCount++;
    };

    connection.onclose(loop);

    callSignalR();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="/css/video.css" />
      </Helmet>

      <div className="jitsi-div">
        <h1 className="has-text-white p-2">Jumlah Partisipan : {conn}</h1>

        <VideoPlayer
          className="video-js vjs-default-skin vjs-16-9"
          data-setup='{"fluid": true}'
          src={`https://record-conf.bppt.go.id:8081/hls/${roomName}.m3u8`}
          type="application/x-mpegURL"
          autoplay
          preload="auto"
          hideControls={[
            "play",
            "volume",
            "seekbar",
            "timer",
            "playbackrates",
            "fullscreen",
          ]}
        />
      </div>
    </>
  );
};

export default TestMeeting1;
