import React from "react";

// component pembantu FormForm
const FormFormHelper = ({ label, children }) => {
  return (
    <div className="field is-horizontal mt-3">
      <div className="field-label" style={{ width: 300 }}>
        <label className="label">{label}</label>
      </div>
      <div className="field-body">
        <div className="field">
          <div className="control">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default FormFormHelper;
