import React from "react";
import { useSelector } from "react-redux";

const SpeakerToast = ({ roomName, userObj, dispatchParticipants }) => {
  const userData = useSelector((state) => state);

  const allowSpeaker = () => {
    userData.signalRChat
      .invoke("RequestSpeakerResponse", roomName, userObj.id, true)
      .then(() => {
        console.log("RequestSpeakerResponse succeed");
        dispatchParticipants({
          type: "CHANGE_ISSPEAKER",
          data: {
            userId: userObj.id,
            isSpeaker: true,
          },
        });
      })
      .catch((err) => console.log("RequestSpeakerResponse failed", err));
  };

  const rejectSpeaker = () => {
    userData.signalRChat
      .invoke("RequestSpeakerResponse", roomName, userObj.id, false)
      .then(() => console.log("RequestSpeakerResponse succeed"))
      .catch((err) => console.log("RequestSpeakerResponse failed", err));
  };

  return (
    <div>
      <p className="mb-2">{userObj.fullName} meminta ijin bicara</p>
      <button
        className="button is-small is-link mr-2"
        type="button"
        onClick={allowSpeaker}
      >
        Ijinkan Bicara
      </button>
      <button
        className="button is-small is-danger"
        type="button"
        onClick={rejectSpeaker}
      >
        Tidak Ijinkan
      </button>
    </div>
  );
};

export default SpeakerToast;
