import { msalConfig, msalRequest } from "./azure";
import axios from "../../../../config/axios";
const msal = window.msal;
const msalClient = new msal.PublicClientApplication(msalConfig);

const addOutlookCalendar = (subject, startDateTime, endDateTime) => {
  // login microsoft
  msalClient
    .loginPopup(msalRequest)
    .then((res) => {
      const tokenMicrosoft = res.accessToken;
      console.log("respond login microsoft", res);
      console.log("tokenMicrosoft", tokenMicrosoft);

      // ambil smua calendar
      axios
        .get("https://graph.microsoft.com/v1.0/me/calendars", {
          headers: {
            Authorization: "Bearer " + tokenMicrosoft,
          },
        })
        .then((res) => {
          const calendars = res.data.value;
          const calendarId = calendars.filter(
            (calendar) => calendar.name === "Calendar"
          )[0].id;
          console.log("success fetch calendar microsoft", calendarId);

          // add event di calendar
          axios
            .post(
              `https://graph.microsoft.com/v1.0/me/calendars/${calendarId}/events`,
              {
                subject,
                start: {
                  dateTime: startDateTime,
                  timeZone: "Pacific Standard Time",
                },
                end: {
                  dateTime: endDateTime,
                  timeZone: "Pacific Standard Time",
                },
              },
              {
                headers: {
                  Authorization: "Bearer " + tokenMicrosoft,
                },
              }
            )
            .then((res) => {
              const linkMicrosoftCalendar = res.data.webLink;
              console.log(
                "add calendar microsoft berhasil",
                linkMicrosoftCalendar
              );
              window.open(linkMicrosoftCalendar);
            })
            .catch((err) => {
              console.log("add calendar microsoft gagal", err);
            });
        })
        .catch((err) => {
          console.log("gagal fetch calendar microsoft", err);
        });
    })
    .catch((err) => console.log("err login microsoft", err));
};

export default addOutlookCalendar;
