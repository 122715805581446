import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import axios from "../../config/axios";
import ErrorSuccess from "../user/login/ErrorSuccess";
import { useDropzone } from "react-dropzone";
import { config } from "../../config";

const EditAvatar = ({ userData, changeUserData }) => {
  // utk dragndrop
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );

      if (acceptedFiles[0].size > 5242880) {
        setErrorFields((prevErr) => {
          return {
            ...prevErr,
            File: ["Maksimal ukuran gambar 5MB"],
          };
        });
      } else {
        setErrorFields((prevErr) => {
          return {
            ...prevErr,
            File: [],
          };
        });
      }
    },
  });

  // utk form
  const form = useRef(null);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [errorFields, setErrorFields] = useState({
    File: [],
  });
  const [disableBtn, setDisableBtn] = useState(false);

  // PUT ke /api/user
  const editAvatar = (e) => {
    // spy tdk refresh halaman saat submit form
    e.preventDefault();

    if (
      !disableBtn &&
      !errorFields.File.includes("Maksimal ukuran gambar 5MB")
    ) {
      // disableBtn button saat fetch
      setDisableBtn(true);

      // mulai fetch
      let data = new FormData(form.current);
      data.append("file", files[0]);
      axios
        .post("/accounts/avatar", data, {
          headers: {
            Authorization: "Bearer " + userData.data.jwtToken,
          },
        })
        .then((res) => {
          changeUserData({
            ...userData.data,
            avatar: res.data.data,
          });
          setSuccess("Edit avatar berhasil");
          setDisableBtn(false);
          window.scrollTo(0, 0);
          setError("");
        })
        .catch((err) => {
          setSuccess("");
          setDisableBtn(false);
          console.log(err);
          window.scrollTo(0, 0);
          if (err.response) {
            err.response.data.message && setError(err.response.data.message);
            err.response.data.title && setError(err.response.data.title);
            err.response.data.errors &&
              setErrorFields(err.response.data.errors);
          } else {
            setError("Maaf terjadi kesalahan");
          }
        });
    }
  };

  useEffect(() => {
    // ubah title
    document.title = "Edit Avatar - BPPT";
  }, []);

  return (
    <form ref={form} className="column p-5" onSubmit={editAvatar}>
      {/* peringatan success */}
      <ErrorSuccess error={error} success={success} />

      {/* judul */}
      <p className="is-size-3 mb-5">Edit Avatar</p>

      {/* gambar */}
      <div className="field is-horizontal">
        <div className="field-label is-normal">
          {/* kosong utk kasih jarak */}
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <img
                className="is-rounded"
                src={
                  userData.data.avatar
                    ? config.REACT_APP_URL_GAMBAR +
                      userData.data.avatar.imageDefault
                    : "/img/placeholder/bppt120x120.png"
                }
                alt="user-avatar"
                style={{ width: 200 }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* file */}
      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Unggah gambar</label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <div
                {...getRootProps()}
                style={{
                  height: 100,
                  border: "2px dashed #ccc",
                  color: "#ccc",
                  padding: 10,
                }}
              >
                {files.length > 0 ? files[0].name : "Belum ada file"}
                <input {...getInputProps()} style={{ display: "none" }} />
              </div>
            </div>

            {/* error per field  */}
            {errorFields.File &&
              errorFields.File.map((error, i) => (
                <small className="help is-danger" key={"error" + error + i}>
                  {error}
                </small>
              ))}
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label">{/* kosong utk kasih jarak */}</div>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <button className="button is-link" disabled={disableBtn}>
                Simpan
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeUserData: (data) => dispatch({ type: "CHANGE_USER_DATA", data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAvatar);
