import React, { useState, useEffect } from "react";
import axios from "../../config/axios";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Recaptcha from "react-recaptcha";
import { config } from "../../config";

const Register = ({ userData }) => {
  // recaptcha sdh verified blm
  const [verified, setVerified] = useState(false);
  const [captchaError, setCaptchaError] = useState("");

  const [error, setError] = useState("");
  const [errorFields, setErrorFields] = useState({});

  // button
  const [button, setButton] = useState(false);
  const [redirect, setRedirect] = useState(false);

  // form
  const [submit, setSubmit] = useState({
    title: "Pak",
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
    acceptTerms: true,
  });

  // ubah title
  useEffect(() => {
    document.title = "Register - BPPT";
  }, []);

  const handleRegister = (e) => {
    // spy halaman tdk refresh
    e.preventDefault();

    if (!verified) {
      setCaptchaError("Mohon verifikasi captcha!");
    } else {
      // disable button ketika diklik submit
      if (!button) {
        setButton(true);

        // lakukan fetch
        axios
          .post("/register", submit)
          .then(() => setRedirect(true))
          .catch((err) => {
            setButton(false);
            console.log(err);
            window.scrollTo(0, 0);
            if (err.response) {
              err.response.data.message && setError(err.response.data.message);
              err.response.data.title && setError(err.response.data.title);
              err.response.data.errors &&
                setErrorFields(err.response.data.errors);
            } else {
              setError("Maaf terjadi kesalahan");
            }
          });
      }
    }
  };

  const handleChange = (e) => {
    setSubmit({
      ...submit,
      [e.target.id]: e.target.value,
    });
  };

  const verifyCallback = (res) => {
    if (res) {
      setVerified(true);
    }
  };

  // pindah ke halaman login, dg menampilkan pesan sukses
  if (userData.data.jwtToken) {
    return <Redirect to="/meeting/hari-ini" />;
  } else if (redirect) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: {
            registerSukses:
              "Registrasi berhasil silahkan cek email untuk verifikasi akun",
          },
        }}
      />
    );
  }

  return (
    <div className="column p-6">
      <form onSubmit={handleRegister}>
        {/* peringatan error */}
        <div className="my-5">
          {error && <p className="notification is-danger">{error}</p>}
        </div>

        <div className="field">
          <label className="label">Jenis Kelamin</label>
          <div className="control">
            <div className="select">
              <select disabled={button} id="title" onChange={handleChange}>
                <option value="Pak">Laki-laki</option>
                <option value="Bu">Perempuan</option>
              </select>
            </div>
          </div>
        </div>

        {/* fields di looping */}
        {fields.map((field, i) => (
          <div className="field" key={i.toString()}>
            <label className="label">{field.label}</label>
            <div className="control">
              <input
                className="input"
                id={field.name}
                type={field.type}
                placeholder={field.label}
                onChange={handleChange}
                disabled={button}
              />
            </div>

            {/* error per field  */}
            {errorFields[field.error] &&
              errorFields[field.error].map((error, i) => (
                <small className="help is-danger" key={i.toString()}>
                  {error}
                </small>
              ))}
          </div>
        ))}

        {/* recaptcha */}
        <div className="field is-grouped">
          <div className="control">
            <Recaptcha
              render="explicit"
              sitekey={config.REACT_APP_GOOGLE_SITEKEY}
              verifyCallback={verifyCallback}
            />
            {captchaError && (
              <small className="help is-danger">{captchaError}</small>
            )}
          </div>
        </div>

        {/* button */}
        <div className="field is-grouped">
          <div className="control">
            <button className="button is-link" disabled={button}>
              Simpan
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

const fields = [
  { label: "Nama Lengkap", name: "fullName", type: "text", error: "FullName" },
  { label: "Email", name: "email", type: "text", error: "Email" },
  { label: "Password", name: "password", type: "password", error: "Password" },
  {
    label: "Ulangi Password",
    name: "confirmPassword",
    type: "password",
    error: "ConfirmPassword",
  },
];

const mapStateToProps = (state) => {
  return {
    userData: state,
  };
};

export default connect(mapStateToProps)(Register);
