import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import axios from "../../config/axios";

const EditUser = ({ userData }) => {
  // pesan error
  const [error, setError] = useState("");
  const [errorFields, setErrorFields] = useState({});

  // utk ambil id di URL
  const { id } = useParams();

  // GET /user/id
  const [user, setUser] = useState({});

  // onchange handler utk setiap input
  const handleChange = (e) => {
    setUser((prevUser) => {
      if (e.target.id === "isActive") {
        return {
          ...prevUser,
          [e.target.id]: e.target.value === "false" ? false : true,
        };
      } else {
        return {
          ...prevUser,
          [e.target.id]: e.target.value,
        };
      }
    });
  };

  useEffect(() => {
    // GET /user/id
    axios
      .get("/accounts/" + id, {
        headers: {
          Authorization: "Bearer " + userData.data.jwtToken,
        },
      })
      .then((res) => {
        console.log("fetch user succeed", res.data.data);
        setUser(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        window.scrollTo(0, 0);
        if (err.response) {
          err.response.data.message && setError(err.response.data.message);
          err.response.data.title && setError(err.response.data.title);
        } else {
          setError("Maaf terjadi kesalahan");
        }
      });
  }, [id, userData.data.jwtToken]);

  // redirect ke daftar user jika berhasil tambah user
  const [redirect, setRedirect] = useState(false);

  // disableBtn button & field saat fetch
  const [disableBtn, setDisableBtn] = useState(false);

  // PUT ke /api/user
  const editUser = (e) => {
    // spy tdk refresh halaman saat submit form
    e.preventDefault();

    if (!disableBtn) {
      // disableBtn button saat fetch
      setDisableBtn(true);

      // mulai fetch
      axios
        .put("/accounts/" + id, user, {
          headers: {
            Authorization: "Bearer " + userData.data.jwtToken,
          },
        })
        .then(() => setRedirect(true))
        .catch((err) => {
          setDisableBtn(false);
          console.log(err);
          window.scrollTo(0, 0);
          if (err.response) {
            err.response.data.message && setError(err.response.data.message);
            err.response.data.title && setError(err.response.data.title);
            err.response.data.errors &&
              setErrorFields(err.response.data.errors);
          } else {
            setError("Maaf terjadi kesalahan");
          }
        });
    }
  };

  useEffect(() => {
    // ubah title
    document.title = "Edit User - BPPT";
  }, []);

  // pindah ke halaman login jika blm login
  if (userData.data.role !== "Admin") {
    return <Redirect to="/meeting/hari-ini" />;
  } else if (redirect) {
    return (
      <Redirect
        to={{
          pathname: "/user",
          state: { pesanSukses: "Edit user berhasil" },
        }}
      />
    );
  }

  return (
    <form className="column p-5" onSubmit={editUser}>
      {/* peringatan error */}
      <div className="my-5">
        {error && <p className="notification is-danger">{error}</p>}
      </div>

      {/* judul */}
      <p className="is-size-3 mb-5">Edit User</p>

      {fields.map((field, i) => (
        <div className="field is-horizontal" key={i.toString()}>
          <div className="field-label is-normal">
            <label className="label">{field.label}</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <RenderInput
                  field={field}
                  defaultValue={user[field.id]}
                  handleChange={handleChange}
                />
              </div>

              {/* error per field  */}
              {errorFields[field.error] &&
                errorFields[field.error].map((error, i) => (
                  <small className="help is-danger" key={i.toString()}>
                    {error}
                  </small>
                ))}
            </div>
          </div>
        </div>
      ))}

      <div className="field is-horizontal">
        <div className="field-label">{/* kosong utk kasih jarak */}</div>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <button className="button is-link" disabled={disableBtn}>
                Simpan
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

// di looping utk menghasilkan field
const fields = [
  { label: "Nama Lengkap", id: "fullName", type: "text", error: "FullName" },
  {
    label: "Jenis Kelamin",
    id: "title",
    type: "select",
    options: [
      { value: "Pak", label: "Laki-laki" },
      { value: "Bu", label: "Perempuan" },
    ],
    error: "Title",
  },
  {
    label: "Jabatan",
    id: "role",
    type: "select",
    options: [
      { value: "User", label: "User" },
      { value: "Admin", label: "Admin" },
    ],
    error: "isActive",
  },
  {
    label: "Aktif?",
    id: "isActive",
    type: "select",
    options: [
      { value: true, label: "Ya" },
      { value: false, label: "Tidak" },
    ],
    error: "isActive",
  },
  { label: "Email", id: "email", type: "email", error: "Email" },
];

const RenderInput = ({ field, defaultValue, handleChange }) => {
  // destructuring prop field
  const { type, label, id, options } = field;

  const defaultValueValue = options?.find(
    (option) => option.value === defaultValue
  );

  // daftar jsx yg bs dipilih sesuai type
  const inputs = {
    text: (
      <input
        className="input"
        id={id}
        type="text"
        placeholder={label}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    ),
    email: (
      <input
        className="input"
        id={id}
        type="email"
        placeholder={label}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    ),
    password: (
      <input
        className="input"
        id={id}
        type="password"
        placeholder={label}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    ),
    select: (
      <div className="select">
        <select id={id} onChange={handleChange} defaultValue={defaultValue}>
          <option value={defaultValueValue?.value}>
            {defaultValueValue?.label}
          </option>

          {options
            ?.filter((option) => option.value !== defaultValue)
            .map((option) => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
        </select>
      </div>
    ),
  };

  return inputs[type];
};

const mapStateToProps = (state) => {
  return {
    userData: state,
  };
};

export default connect(mapStateToProps)(EditUser);
