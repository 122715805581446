import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import axios from "../../config/axios";
import formatTanggal from "../../helpers/formatTanggal";
import ReactPaginate from "react-paginate";
import ErrorSuccess from "../user/login/ErrorSuccess";
import KotakCari from "../meeting/meetings/KotakCari";
import { config } from "../../config";

const Events = ({ location, userData }) => {
  const history = useHistory(); // utk push halaman pagination di url
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [query, setQuery] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const pageFromParams = new URLSearchParams(location.search).get("page");
  const [page, setPage] = useState(pageFromParams ? pageFromParams : 1);
  const onPageClick = (e) => {
    const halaman = e.selected + 1;
    history.push("?page=" + halaman);
    setPage(halaman);
    window.scrollTo(0, 0);
  };

  // atur pencarian
  const changeCari = (e) => {
    setQuery(e.target.value);
    setPage(1);
  };

  useEffect(() => {
    // jika user telah berhasil menambah acara tampilkan pesan sukses
    setSuccess(location.state?.pesanSukses);
    setError("");
    window.scrollTo(0, 0);
  }, [location.state?.pesanSukses]);

  // events list
  const [events, setEvents] = useState([]);

  // utk render loading bar
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // ubah title sesuai nama halaman
    document.title = "Daftar Acara - BPPT";
  }, []);

  useEffect(() => {
    // fetch data events list
    axios
      .get(`/event?page=${page}&query=${query}`, {
        headers: {
          Authorization: "Bearer " + userData.data.jwtToken,
        },
      })
      .then((res) => {
        console.log("event nih", res.data);
        setEvents(res.data.data);
        setPageCount(res.data.totalPage);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        window.scrollTo(0, 0);
        console.log(err);
        if (err.response) {
          err.response.data.message && setError(err.response.data.message);
          err.response.data.title && setError(err.response.data.title);
        } else {
          setError("Maaf terjadi kesalahan");
        }
      });
  }, [success, page, query]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="column">
      {/* peringatan success */}
      <ErrorSuccess error={error} success={success} />

      {/* kotak pencarian */}
      <KotakCari changeCari={changeCari} placeholder="Cari judul acara" />

      {/* looping events */}
      {events.length > 0 ? (
        events.map((n) => (
          <RenderEventsItem
            key={n.id}
            title={n.title}
            author={n.author.fullName}
            content={n.content}
            startDate={formatTanggal(n.startDate)}
            endDate={n.endDate && formatTanggal(n.endDate)}
            img={
              n.featuredImage
                ? config.REACT_APP_URL_GAMBAR + n.featuredImage.imageSmall
                : "/img/placeholder/bppt120x120.png"
            }
            slug={n.slug}
          />
        ))
      ) : loading ? (
        <progress className="progress is-small is-info" max="100"></progress>
      ) : (
        <p className="has-text-grey-light has-text-centered">
          Belum ada acara.
        </p>
      )}

      {/* pagination */}
      <ReactPaginate
        initialPage={page - 1}
        pageCount={pageCount}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        previousLabel="Sebelumnya"
        nextLabel="Selanjutnya"
        containerClassName="pagination is-rounded is-justify-content-start mt-6"
        pageLinkClassName="pagination-link"
        activeLinkClassName="pagination-link is-current"
        previousClassName="pagination-previous"
        nextClassName="pagination-next"
        breakLinkClassName="pagination-ellipsis"
        onPageChange={onPageClick}
        disableInitialCallback
      />
    </div>
  );
};

// render events per item
const RenderEventsItem = ({
  title,
  author,
  content,
  startDate,
  endDate,
  img,
  slug,
}) => {
  return (
    <div className="card">
      <div className="card-content">
        <Link to={"/acara/" + slug} className="media">
          <div className="media-left">
            <figure className="image is-48x48">
              <img src={img} alt={title} />
            </figure>
          </div>
          <div className="media-content">
            <p className="title is-4">{title}</p>
            <p className="subtitle is-6">{author}</p>
          </div>
        </Link>

        <div className="content">
          {content.replace(/<\/?[^>]+(>|$)/g, " ").substring(0, 200)} ...
          <br />
          <p className="has-text-grey-light">
            Dilaksanakan pada {startDate} {endDate && "- " + endDate}
          </p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state,
  };
};

export default connect(mapStateToProps)(Events);
