import React, { useState } from "react";
import { config } from "../../../../config";

const RenderMessage = ({ message, chatku }) => {
  const [imageModal, setImageModal] = useState({
    className: "modal",
    imgSrc: "",
    imgAlt: "",
  });

  const imageModalOn = (e) => {
    setImageModal({
      className: "modal is-active",
      imgSrc: e.target.id,
      alt: e.target.alt,
    });
  };

  const imageModalOff = () => setImageModal({ className: "modal" });

  return message.type === 0 ? (
    // jika pesan adl text
    <>{message.content}</>
  ) : message.type === 1 ? (
    // jika pesan adl gambar
    <>
      <img
        className="is-clickable"
        src={config.REACT_APP_URL_GAMBAR + message.media.imageSmall}
        alt={`gambar ${message.id} dari ${message.author.fullName}`}
        id={config.REACT_APP_URL_GAMBAR + message.media.imageDefault}
        onClick={imageModalOn}
      />

      {/* image modal */}
      <div className={imageModal.className}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <p className="image">
            <img src={imageModal.imgSrc} alt={imageModal.imgAlt} />
          </p>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={imageModalOff}
        ></button>
      </div>
    </>
  ) : message.type === 3 ? (
    // jika pesan adl file
    <a
      className={chatku ? "has-text-white is-size-7" : "is-size-7"}
      href={config.REACT_APP_URL_GAMBAR + message.attachment.downloadUri}
      style={{ display: "inline" }}
      onClick={downloadFile}
    >
      {message.attachment.fileName}
    </a>
  ) : (
    // jika pesan adl video
    <div className="has-background-white"></div>
  );
};

export const downloadFile = (e) => {
  e.preventDefault();
  window.open(e.target.href, "Download");
};

export default RenderMessage;
