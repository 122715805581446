import React from "react";

const CariPartisipan = ({ searchParticipant }) => {
  return (
    <p className="control has-icons-left">
      <input
        className="input"
        type="text"
        placeholder="Cari nama partisipan"
        onChange={searchParticipant}
      />
      <span className="icon is-left is-small">
        <i className="fas fa-search" aria-hidden="true"></i>
      </span>
    </p>
  );
};

export default CariPartisipan;
