import React from "react";
import DotIconParticipant from "./video-audio/DotIconParticipant";

const VideoAudio = ({
  participant,
  setTabs,
  setRecipentProps,
  signalRConn,
  roomName,
  isHost,
  jitsi,
  meetingType,
  dispatchParticipants,
}) => {
  return (
    <span style={{ marginLeft: "auto" }}>
      {/* icon dot */}
      <DotIconParticipant
        participant={participant}
        setTabs={setTabs}
        setRecipentProps={setRecipentProps}
        signalRConn={signalRConn}
        roomName={roomName}
        isHost={isHost}
        jitsi={jitsi}
        meetingType={meetingType}
        dispatchParticipants={dispatchParticipants}
      />
    </span>
  );
};

export default VideoAudio;
