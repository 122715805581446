import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import Meetings from "./Meetings";

const MeetingKemarin = ({ location }) => {
  const userData = useSelector((state) => state);

  if (!userData.data.isSuperAdmin) {
    return (
      <Redirect
        to={{
          pathname: "/meeting/hari-ini",
          state: {
            pesanGagal: "Maaf, anda tidak berhak mengakses halaman ini",
          },
        }}
      />
    );
  }

  return (
    <Meetings
      location={location}
      endPoint="/meeting"
      title="Meeting Super Admin - BPPT"
    />
  );
};

export default MeetingKemarin;
