import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="has-text-centered">
      <img src="/img/login/meeting.png" alt="logo-bppt-big" width="200px" />
      <p
        className="has-text-weight-bold"
        style={{ position: "relative", top: -25, right: 20 }}
      >
        BPPT <span className="has-text-danger">Teleconference</span>
      </p>
      <p className="is-size-4 has-text-weight-bold mt-5">Selamat Datang</p>
      <p className="mb-5">
        Belum punya akun?
        <Link to="/register" className="has-text-danger">
          {" "}
          <u>Daftar sekarang!</u>
        </Link>
      </p>
    </div>
  );
};

export default Header;
