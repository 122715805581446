import React from "react";

const ErrorSuccess = ({ statePesan, clearError, clearSuccess }) => {
  return (
    <div className="my-5">
      {statePesan.sukses && (
        <div className="notification is-info">
          <button className="delete" onClick={clearSuccess}></button>
          {statePesan.sukses}
        </div>
      )}
      {statePesan.error && (
        <div className="notification is-danger">
          <button className="delete" onClick={clearError}></button>
          {statePesan.error}
        </div>
      )}
    </div>
  );
};

export default ErrorSuccess;
