import React, { useState } from "react";
import { useSelector } from "react-redux";
import { config } from "../../../../config";

const Presentasi = ({ roomName, presentasi, setPresentasi }) => {
  const userData = useSelector((state) => state);
  const [slideNumber, setSlideNumber] = useState(0);
  const [pesanError, setPesanError] = useState("");
  const [loading, setLoading] = useState(false);

  const prev = () => changeSlide(slideNumber - 1);
  const next = () => changeSlide(slideNumber + 1);

  const changeSlide = (slideNumber) => {
    console.log("ganti ke slide", slideNumber);
    if (userData.signalRChat) {
      // jika sdh connect ke signalr
      setLoading(true);
      userData.signalRChat
        .invoke("ChangeSlide", roomName, presentasi.id, slideNumber + 1)
        .then(() => {
          // jika berhasil ganti slide
          console.log("ChangeSlide succeed");
          setLoading(false);
          setSlideNumber(slideNumber);
          setPesanError("");
        })
        .catch((err) => {
          // jika gagal ganti slide
          console.log("ChangeSlide failed", err);
          setLoading(false);
          setPesanError("Gagal ganti slide, silahkan coba lagi");
        });
    } else {
      // jika blm connect ke signalr
      setLoading(false);
      setPesanError("Gagal ganti slide, silahkan coba lagi");
    }
  };

  const stopPresentasi = () => {
    if (userData.signalRChat) {
      // jika sdh connect ke signalr
      setLoading(true);
      userData.signalRChat
        .invoke("StopPresentation", roomName, presentasi.id)
        .then(() => {
          // jika berhasil stop presentasi
          console.log("StopPresentation succeed");
          setPresentasi((prevPresentasi) => {
            return {
              ...prevPresentasi,
              tampil: false,
            };
          });
        })
        .catch((err) => {
          // jika gagal stop presentasi
          console.log("StopPresentation failed", err);
          setLoading(false);
          setPesanError("Gagal hentikan presentasi, silahkan coba lagi");
        });
    } else {
      // jika blm connect ke signalr
      setPesanError("Gagal hentikan presentasi, silahkan coba lagi");
    }
  };

  return (
    <>
      {/* tombol start presentasi */}
      <div className="px-2 mb-2">
        <button
          className={
            loading
              ? "button is-fullwidth is-danger is-loading"
              : "button is-fullwidth is-danger"
          }
          onClick={stopPresentasi}
          type="button"
          disabled={loading}
        >
          Hentikan Presentasi
        </button>
      </div>

      {/* gambar presentasi */}
      {presentasi.slideList.length > 0 &&
        presentasi.slideList.map((slide, i) => (
          <img
            key={"slide" + i}
            src={config.REACT_APP_URL_SLIDE + slide.uri}
            alt={"slide" + i}
            style={{
              maxWidth: 250,
              maxHeight: 250,
              margin: "0 auto",
              display: i === slideNumber ? "block" : "none",
            }}
            className="my-2"
          />
        ))}
      {/* tombol navigasi */}
      {slideNumber > 0 && (
        <button
          className={
            loading
              ? "button is-clickable is-pulled-left is-loading"
              : "button is-clickable is-pulled-left"
          }
          onClick={prev}
        >
          Sebelumnya
        </button>
      )}
      {slideNumber < presentasi.slideCount - 1 && (
        <button
          className={
            loading
              ? "button is-clickable is-pulled-right is-loading"
              : "button is-clickable is-pulled-right"
          }
          onClick={next}
        >
          Selanjutnya
        </button>
      )}
      {/* utk menangani float di atas */}
      <div style={{ clear: "both" }}></div>
      {/* pesan error */}
      <p className="py-2">
        {pesanError && <i className="fas fa-times has-text-danger mr-1"></i>}
        {pesanError}
      </p>
    </>
  );
};

export default Presentasi;
