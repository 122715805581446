// render checklist
const renderChecklist = (v) => {
  return v.type === true ? (
    <>
      <i className="fas fa-check has-text-success mr-2"></i>
      {v.text}
    </>
  ) : v.type === false ? (
    <>
      <i className="fas fa-times has-text-danger mr-2"></i>
      {v.text}
    </>
  ) : (
    <>
      <i className="fas fa-user has-text-grey-light mr-2"></i>
      {v}
    </>
  );
};

export default renderChecklist;
