import React, { useState } from "react";
import Routeku from "./Routeku";

// components
import Nav from "./components/Nav";
import Footer from "./components/Footer";

// library
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";

function App() {
  const [sidebarOn, setSidebarOn] = useState(false);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Nav setSidebarOn={setSidebarOn} />

        <div className="container mb-6" style={{ minHeight: "75vh" }}>
          <Routeku sidebarOn={sidebarOn} setSidebarOn={setSidebarOn} />
        </div>

        <Footer />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
