export const styles = {
  panels: {
    position: "absolute",
    top: 0,
    bottom: 0, // ini tinggi custom button jitsi
    right: 0,
    zIndex: 99,
    width: 300,
    backgroundColor: "#fff",
  },
  button: {
    width: "35px",
    height: "35px",
  },
};
