import React from "react";

const KotakCari = ({ changeCari, placeholder }) => {
  return (
    <div className="field">
      <p className="control has-icons-left">
        <input
          className="input"
          type="text"
          placeholder={placeholder}
          onChange={changeCari}
        />
        <span className="icon is-small is-left">
          <i className="fas fa-search"></i>
        </span>
      </p>
    </div>
  );
};

export default KotakCari;
