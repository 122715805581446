import * as axiosBase from "axios";
import { config } from "../config";
import jwtDecode from "./jwtDecode";

const axios = axiosBase.create({
  baseURL: config.REACT_APP_URL_API,
});

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  const reduxState = JSON.parse(localStorage.getItem("reduxState"));
  console.log("dr setiap request axios nih", reduxState.data.jwtToken);

  if (
    reduxState.data.jwtToken &&
    jwtDecode(reduxState.data.jwtToken).exp < Date.now() / 1000
  ) {
    localStorage.removeItem("reduxState");

    // jika login pk akun cas
    if (reduxState.data.source === "CAS") {
      // logout jg di auth.bppt.go.id
      window.location = config.REACT_APP_URL_LOGOUT_CAS;
    }

    window.location = "/";
  }

  return config;
});

export default axios;
