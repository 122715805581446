import React from "react";
import Meetings from "./Meetings";
import InteractiveGuideku from "../../InteractiveGuideku";

const MeetingHariIni = ({ location }) => {
  return (
    <>
      <InteractiveGuideku />

      <Meetings
        location={location}
        endPoint="/meeting/today-personal"
        title="Meeting Hari Ini - BPPT"
      />
    </>
  );
};

export default MeetingHariIni;
