import React from "react";
import dayjs from "dayjs";
import { config } from "../../../config";

const AddGoogleCalendar = ({ schedule, summary, description }) => {
  const start = schedule.start.split(":");
  const startJam = parseInt(start[0]);
  const startMenit = parseInt(start[1]);
  const startDateTime = dayjs(schedule.startDate)
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)
    .add(startJam, "hour")
    .add(startMenit, "minute");

  const end = schedule.end.split(":");
  const endJam = parseInt(end[0]);
  const endMenit = parseInt(end[1]);
  const endDateTime = dayjs(schedule.startDate)
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)
    .add(endJam, "hour")
    .add(endMenit, "minute");

  const event = {
    summary,
    description,
    start: {
      dateTime: startDateTime,
      timeZone: "Asia/Jakarta",
    },
    end: {
      dateTime: endDateTime,
      timeZone: "Asia/Jakarta",
    },
    // recurrence: ["RRULE:FREQ=DAILY;COUNT=2"],
    // attendees: [{ email: "lpage@example.com" }, { email: "sbrin@example.com" }],
    reminders: {
      useDefault: false,
      overrides: [
        { method: "email", minutes: 24 * 60 }, // 1 hari sblm meeting akan dpt email dr google
        { method: "popup", minutes: 10 }, // 10 menit sblm meeting dimulai akan muncul popup UI di google calendar
      ],
    },
  };

  // script src di index.html
  const gapi = window.gapi;

  const handleItemClick = () => {
    gapi.load("client:auth2", () => {
      console.log("auth2 client loading");
      gapi.client
        .init({
          apiKey: config.REACT_APP_GOOGLE_APIKEY_GCALENDAR,
          clientId: config.REACT_APP_GOOGLE_CLIENTID,
          discoveryDocs,
          scope,
        })
        .then((res) => {
          console.log("gclient init succeed", res);

          gapi.auth2
            .getAuthInstance()
            .signIn()
            .then((res) => {
              console.log("gclient signIn succeed", res);

              gapi.client.calendar.events
                .insert({
                  calendarId: "primary",
                  resource: event,
                })
                .execute((e) => {
                  window.open(e.htmlLink);
                });
            })
            .catch((err) => console.log("gclient signIn failed", err));
        })
        .catch((err) => console.log("gclient init failed", err));
    });
  };

  return (
    <button
      className="button is-fullwidth is-danger"
      title="Tambah ke Google Calendar"
      onClick={handleItemClick}
    >
      <i className="fab fa-google mr-2"></i> Google Calendar
    </button>
  );
};

const discoveryDocs = [
  "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
];

const scope = "https://www.googleapis.com/auth/calendar.readonly";

export default AddGoogleCalendar;
