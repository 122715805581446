import React from "react";
import Meetings from "./Meetings";

const MeetingKemarinPersonal = ({ location }) => {
  return (
    <Meetings
      location={location}
      endPoint="/meeting/previous-personal"
      title="Meeting yang Lalu (Personal) - BPPT"
    />
  );
};

export default MeetingKemarinPersonal;
