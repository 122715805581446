import React from "react";
import { downloadFile } from "../video/chat-panel/RenderMessage";

const InfoDanDownload = ({ video }) => {
  const { fileName, fileSize, urlPath } = video;
  return (
    <>
      <p className="title is-5">{fileName.substring(0, 25)}...</p>
      <p className="subtitle is-6">{fileSize} KB</p>
      <div className="buttons">
        <a
          href={urlPath}
          className="button is-small is-link"
          onClick={downloadFile}
        >
          <i className="fas fa-arrow-circle-down mr-2"></i>Download Video
        </a>

        {/* <button className="button is-small is-info">
          <i className="fas fa-arrow-circle-down mr-2"></i>Download Audio
        </button> */}
      </div>
    </>
  );
};

export default InfoDanDownload;
