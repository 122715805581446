// format tanggal dari date ISOString
const formatTanggal = (dateISOString) => {
  const dateObj = new Date(dateISOString);
  const tahun = dateObj.getFullYear();
  const bulan = dateObj.getMonth();
  const tanggal = dateObj.getDate();

  return `${tanggal} ${intToBulan(bulan)} ${tahun}`;
};

// angka ke bulan menurut js (0 = Januari)
const intToBulan = (int) => {
  const bulan = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];

  return bulan[int];
};

export default formatTanggal;
