import React, { useRef } from "react";
import axios from "../../../../../config/axios";

const UploadFile = ({
  roomName,
  scheduleId,
  userData,
  signalRConn,
  displayChatError,
  toggleUploadMenu,
  setIndicatorUpload,
}) => {
  const buttonRef = useRef(null);
  const formRef = useRef(null);
  const inputRef = useRef(null);

  const kirimClick = (e) => {
    e.preventDefault();
    inputRef.current.click();
    toggleUploadMenu();
  };

  const uploadGambar = (e) => {
    e.preventDefault();
    setIndicatorUpload("Sedang mengupload file ...");
    let data = new FormData(formRef.current);
    data.append("RoomName", roomName);
    data.append("ScheduleId", scheduleId);
    axios
      .post("/meeting/upload", data, {
        headers: {
          Authorization: "Bearer " + userData.data.jwtToken,
        },
      })
      .then((res) => {
        console.log("upload file berhasil", res);
        displayFile(roomName, res.data.data.id, signalRConn, displayChatError);
        setIndicatorUpload("");
        displayChatError("");
      })
      .catch((err) => {
        console.log(err);
        displayChatError("Terjadi kesalahan saat kirim file (maks. 5MB)");
        setIndicatorUpload("");
      });
  };

  const submitForm = () => buttonRef.current.click();

  return (
    <form ref={formRef} onSubmit={uploadGambar}>
      <a
        href="/#"
        className="dropdown-item"
        htmlFor="file"
        onClick={kirimClick}
      >
        Kirim file
      </a>

      <input
        key="iniinpututkuploadfiledichatpanel"
        ref={inputRef}
        type="file"
        name="file"
        className="is-hidden"
        onChange={submitForm}
      />

      <button ref={buttonRef} className="is-hidden"></button>
    </form>
  );
};

const displayFile = (roomName, fileId, signalRConn, displayChatError) => {
  signalRConn
    .invoke("SendFile", roomName, fileId)
    .then((res) => {
      console.log("kirim file berhasil", res);
    })
    .catch((err) => {
      console.log("kirim file gagal", err);
      displayChatError("Terjadi kesalahan saat kirim gambar");
    });
};

export default UploadFile;
