import React, { useEffect } from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { config } from "../config";
import jwtDecode from "../config/jwtDecode";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  window.scrollTo(0, 0);
  const pathname = rest.location.pathname;
  const userData = useSelector((state) => state);

  // cek token expired / tdk
  useEffect(() => {
    if (userData.data.jwtToken) {
      if (jwtDecode(userData.data.jwtToken).exp < Date.now() / 1000) {
        localStorage.removeItem("reduxState");

        // jika login pk akun cas
        if (userData.data.source === "CAS") {
          // logout jg di auth.bppt.go.id
          window.location = config.REACT_APP_URL_LOGOUT_CAS;
        }

        window.location = "/";
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Route
      {...rest}
      render={(props) => {
        return userData.data.jwtToken ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { prevUrl: pathname },
            }}
          />
        );
      }}
    />
  );
};

export default withRouter(ProtectedRoute);
