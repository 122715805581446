import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "../../config/axios";
import ErrorSuccess from "../../components/user/login/ErrorSuccess";

const Pengingat = () => {
  const userData = useSelector((state) => state);
  const [timeValue, setTimeValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);

  const selectChange = (e) => setTimeValue(e.target.value);

  useEffect(() => {
    document.title = "Pengingat Email - BPPT";

    // GET /setting/meeting-reminder
    axios
      .get("/setting/meeting-reminder", {
        headers: {
          Authorization: "Bearer " + userData.data.jwtToken,
        },
      })
      .then((res) => {
        setLoading(false);
        setError("");
        console.log("fetch reminder succeed", res);
        setTimeValue(res.data.data.value);
      })
      .catch((err) => {
        setLoading(false);
        setSuccess("");
        console.log("fetch reminder failed", err);
        window.scrollTo(0, 0);
        if (err.response) {
          err.response.data.message && setError(err.response.data.message);
          err.response.data.title && setError(err.response.data.title);
        } else {
          setError("Maaf terjadi kesalahan");
        }
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const editReminder = (e) => {
    e.preventDefault();

    if (!disableBtn) {
      setDisableBtn(true);

      axios
        .put(
          "/setting/meeting-reminder",
          {
            value: timeValue,
          },
          {
            headers: {
              Authorization: "Bearer " + userData.data.jwtToken,
            },
          }
        )
        .then((res) => {
          setSuccess("Edit pengingat berhasil");
          setError("");
          setDisableBtn(false);
          console.log("edit reminder succeed", res);
          window.scrollTo(0, 0);
        })
        .catch((err) => {
          setSuccess("");
          setDisableBtn(false);
          console.log("edit reminder failed", err);
          window.scrollTo(0, 0);
          if (err.response) {
            err.response.data.message && setError(err.response.data.message);
            err.response.data.title && setError(err.response.data.title);
          } else {
            setError("Maaf terjadi kesalahan");
          }
        });
    }
  };

  return (
    <div className="column p-5">
      {/* peringatan success & error */}
      <ErrorSuccess error={error} success={success} />

      {/* judul */}
      <p className="is-size-3 mb-5">Pengingat Email</p>
      {loading ? (
        <progress className="progress is-small is-info" max="100"></progress>
      ) : timeValue > 0 ? (
        <form onSubmit={editReminder}>
          <p className="has-text-grey mb-5">
            Menentukan kapan notifikasi pengingat akan dikirim di Email
            partisipan
          </p>

          <RenderField label="Dikirim">
            <RenderSelect
              options={hari}
              selectChange={selectChange}
              timeValue={timeValue}
            />
          </RenderField>

          <RenderField label="">
            <div className="buttons">
              <button className="button is-link" disabled={disableBtn}>
                Simpan
              </button>
            </div>
          </RenderField>
        </form>
      ) : null}
    </div>
  );
};

let hari = [];
for (let i = 1; i < 31; i++) {
  hari.push({
    value: i,
    label: i,
  });
}

const RenderField = ({ label, children }) => {
  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <label className="label">{label}</label>
      </div>
      <div className="field-body">
        <div className="field">
          <div className="control">{children}</div>
        </div>
      </div>
    </div>
  );
};

const RenderSelect = ({ label, options, selectChange, timeValue }) => {
  return (
    <>
      <div className="select">
        <select id={label} onChange={selectChange}>
          <option value={timeValue}>{timeValue}</option>

          {options.map((option) => (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <span className="ml-2 mt-2 is-inline-block">
        hari sebelum meeting dimulai
      </span>
    </>
  );
};

export default Pengingat;
