import React, { useState } from "react";
import { useSelector } from "react-redux";

const DotIconParticipant = ({
  participant,
  setTabs,
  setRecipentProps,
  signalRConn,
  roomName,
  isHost,
  jitsi,
  meetingType,
  dispatchParticipants,
}) => {
  console.log("jitsiid doticon", participant.jitsiId);
  const userData = useSelector((state) => state);
  const [menuOn, setMenuOn] = useState(false);
  const toggleMenu = () => setMenuOn((prevMenu) => !prevMenu);
  const dropdownItemClick = (e) => {
    e.preventDefault();
    toggleMenu();
    setTabs({
      chat: "is-active",
    });
    setRecipentProps({
      id: participant.id,
      name: participant.displayName,
    });
  };

  // const pinParticipant = (e) => {
  //   e.preventDefault();
  //   toggleMenu();
  //   signalRConn &&
  //     signalRConn
  //       .invoke(
  //         "SendMessage",
  //         roomName,
  //         `pinParticipant#${participant.jitsiId}`
  //       )
  //       .then(() => console.log("kirim pinParticipant berhasil"))
  //       .catch((err) => console.log("kirim pinParticipant gagal", err));
  // };

  const revokeSpeaker = (e) => {
    e.preventDefault();
    toggleMenu();
    signalRConn &&
      signalRConn
        .invoke("RevokeSpeaker", roomName, participant.id)
        .then(() => {
          console.log("RevokeSpeaker succeed");
          dispatchParticipants({
            type: "CHANGE_ISSPEAKER",
            data: {
              userId: participant.id,
              isSpeaker: false,
            },
          });
        })
        .catch((err) => console.log("RevokeSpeaker failed", err));
  };

  const grantSpeaker = (e) => {
    e.preventDefault();
    toggleMenu();
    signalRConn &&
      signalRConn
        .invoke("RequestSpeakerResponse", roomName, participant.id, true)
        .then(() => {
          console.log("grantSpeaker succeed");
          dispatchParticipants({
            type: "CHANGE_ISSPEAKER",
            data: {
              userId: participant.id,
              isSpeaker: true,
            },
          });
        })
        .catch((err) => console.log("grantSpeaker failed", err));
  };

  return participant.id !== userData.data.id ? (
    <div className={menuOn ? "dropdown is-active" : "dropdown"}>
      <div className="dropdown-trigger">
        <i
          className="fas fa-ellipsis-v is-clickable"
          title="Menu"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onClick={toggleMenu}
        ></i>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div
          className="dropdown-content"
          style={{ position: "absolute", right: 200, top: 10, width: 200 }}
        >
          <a href="/#" className="dropdown-item" onClick={dropdownItemClick}>
            Kirim pesan privat
          </a>

          {(userData.data.role === "Admin" || isHost) && meetingType === 1 && (
            <>
              {participant.isSpeaker ? (
                <a href="/#" className="dropdown-item" onClick={revokeSpeaker}>
                  Cabut ijin bicara
                </a>
              ) : (
                <a href="/#" className="dropdown-item" onClick={grantSpeaker}>
                  Beri ijin bicara
                </a>
              )}

              {/* {participant.jitsiId !== 0 && (
                <a href="/#" className="dropdown-item" onClick={pinParticipant}>
                  Pin partisipan
                </a>
              )} */}
            </>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className="has-background-white"></div>
  );
};

export default DotIconParticipant;
