import React from "react";
import { useSelector } from "react-redux";

const PanelsTabs = ({
  tabs,
  gantiTab,
  blmDibaca,
  meetingDetail,
  jumlahPartisipan,
  jumlahPembicara,
}) => {
  console.log("meetingDetail.isSpeaker dr panelstabs", meetingDetail.isSpeaker);
  const userData = useSelector((state) => state);

  return (
    <div className="panel-tabs">
      <a
        href="/#"
        id="participant"
        className={tabs.participant}
        onClick={gantiTab}
      >
        {jumlahPartisipan} Partisipan
      </a>

      <a href="/#" id="chat" className={tabs.chat} onClick={gantiTab}>
        Chat {/* jumlah chat yg blm dibaca */}
        {blmDibaca > 0 && (
          <small
            className="has-text-centered has-background-danger has-text-white"
            style={{ borderRadius: "30%", padding: "0 7px" }}
          >
            {blmDibaca}
          </small>
        )}
      </a>

      <a href="/#" id="pembicara" className={tabs.pembicara} onClick={gantiTab}>
        {jumlahPembicara} Pembicara
      </a>

      {(userData.data.role === "Admin" ||
        meetingDetail.isHost ||
        (meetingDetail.isSpeaker && meetingDetail.meetingType === 1)) && (
        <a
          href="/#"
          id="presentasi"
          className={tabs.presentasi}
          onClick={gantiTab}
        >
          Slide
        </a>
      )}
    </div>
  );
};

export default PanelsTabs;
