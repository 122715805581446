import React from "react";

const Streaming = ({ urlPath }) => {
  return (
    <div className="column">
      <div className="columns">
        <div className="column">
          <a
            title="Streaming video"
            href={urlPath}
            target="_blank"
            rel="noopener noreferrer"
            style={{ display: "table-cell" }}
            className="button is-danger is-fullwidth is-pulled-right"
            // hrs ny g perlu pulled right klo ad button 1 lg
          >
            <i className="fas fa-play mr-2"></i>Tonton Video
          </a>
        </div>
        {/* <div className="column">
          <button className="button is-warning is-fullwidth">
            <i className="fas fa-headphones mr-2"></i>Dengar Audio
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default Streaming;
