import React from "react";
import formatTanggal from "../../../helpers/formatTanggal";
import { Link } from "react-router-dom";
import RenderButtons from "./RenderButtons";
import { isMeetingKeDepan } from "./RenderButtons";

const RenderMeetings = ({ meetings, userData, modalEditOn, dispatchPesan }) => {
  const groupingTgls = meetings
    .map((meeting) => formatTanggal(meeting.schedule.startDate))
    .filter((item, pos, self) => self.indexOf(item) === pos);

  let arrObjTgl = groupingTgls.map((tgl) => {
    return { tgl };
  });

  for (let i = 0; i < arrObjTgl.length; i++) {
    arrObjTgl[i].meetings = meetings.filter(
      (meeting) =>
        formatTanggal(meeting.schedule.startDate) === arrObjTgl[i].tgl
    );
  }

  return (
    <>
      {arrObjTgl.map((obj) => (
        <div key={obj.tgl}>
          <p className="title is-4 py-3 px-5 mt-5">{obj.tgl}</p>

          {obj.meetings.map((meeting) => (
            <div className="card" key={meeting + meeting.schedule.id}>
              <hr style={{ marginTop: "-20px" }} />
              <div className="card-content columns is-vcentered">
                <JudulDanKode
                  roomName={meeting.roomName}
                  title={meeting.title}
                  scheduleId={meeting.schedule.id}
                />

                <JamDanStatus meeting={meeting} />

                <ButtonsDanEdit
                  meeting={meeting}
                  userData={userData}
                  modalEditOn={modalEditOn}
                  dispatchPesan={dispatchPesan}
                />
              </div>
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

const JudulDanKode = ({ roomName, title, scheduleId }) => {
  return (
    <div className="column">
      <Link
        to={"/meeting/" + roomName + "/jadwal/" + scheduleId}
        className="is-size-5 has-text-danger"
      >
        {title}
      </Link>
      <br />
      <small>
        Kode:
        <span className="has-text-weight-bold">
          {" "}
          <code>{roomName}</code>
        </span>
      </small>
    </div>
  );
};

const JamDanStatus = ({ meeting }) => {
  return (
    <div className="column has-text-centered">
      <p className="has-text-danger">
        {meeting.schedule.start} - {meeting.schedule.end}
      </p>
      <p>
        {meeting.schedule.isStarted && (
          <span className="tag is-info">Sedang Berlangsung</span>
        )}
        {!meeting.isPublic && <span className="tag">Privat</span>}
        {meeting.hasPassword && (
          <span className="tag is-danger">
            <i className="fas fa-key"></i>
          </span>
        )}
      </p>
    </div>
  );
};

const ButtonsDanEdit = ({ meeting, userData, modalEditOn, dispatchPesan }) => {
  return (
    <div className="column">
      <div className="columns">
        {/* munculkan tombol edit jika dia host */}
        {meeting.isHost &&
          userData.data.role !== "Admin" &&
          isMeetingKeDepan(meeting.schedule) && (
            <>
              {meeting.isRecuring ? (
                <ModalEdit meeting={meeting} modalEditOn={modalEditOn} />
              ) : (
                <LinkEdit roomName={meeting.roomName} isHost={meeting.isHost} />
              )}
            </>
          )}

        {/* render button lain jika berhak */}
        <RenderButtons
          meeting={meeting}
          userData={userData}
          dispatchPesan={dispatchPesan}
        />
      </div>
    </div>
  );
};

const ModalEdit = ({ meeting, modalEditOn }) => {
  return (
    <div className="column">
      {/* tombol edit meeting recuring */}
      <button
        title={meeting.roomName}
        id={meeting.schedule.id}
        onClick={modalEditOn}
        className="button is-link is-rounded is-fullwidth"
      >
        Edit
      </button>
    </div>
  );
};

const LinkEdit = ({ roomName, isHost }) => {
  return (
    <div className="column">
      {/* tombol edit meeting non recuring */}
      <Link
        to={{
          pathname: "/meeting/edit/" + roomName,
          state: {
            isHost: isHost,
          },
        }}
        className="button is-link is-rounded is-fullwidth"
      >
        Edit
      </Link>
    </div>
  );
};

export default RenderMeetings;
