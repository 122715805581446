import React, { useState } from "react";
import RenderFileHistory from "./RenderFileHistory";

const FileHistory = ({ files }) => {
  const [fileOn, setFileOn] = useState(false);
  const toggleFile = () => setFileOn((prevFile) => !prevFile);

  return (
    <div className="card">
      {/* toggle file history */}
      <div className="card-header is-clickable" onClick={toggleFile}>
        <p className="card-header-title">File History</p>
        <p className="card-header-icon" aria-label="more options">
          <span className="icon">
            <i className="fas fa-angle-down" aria-hidden="true"></i>
          </span>
        </p>
      </div>

      {/* file list */}
      <div className={fileOn ? "card-content" : "card-content is-hidden"}>
        <div className="content" style={{ width: "70vh" }}>
          {files.length > 0 &&
            files.map((file) => (
              <RenderFileHistory file={file} key={"file" + file.id} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default FileHistory;
