import React, { useState, useEffect } from "react";
import axios from "../../config/axios";
import { Redirect, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import GoogleLogin from "react-google-login";
import Email from "./login/Email";
import Password from "./login/Password";
import ErrorSuccess from "./login/ErrorSuccess";
import Header from "./login/Header";
import { Helmet } from "react-helmet";
import { config } from "../../config";

const Login = ({ location }) => {
  const refer = location.state ? location.state.prevUrl : "/meeting/hari-ini";
  console.log("refer", refer);
  const userData = useSelector((state) => state);
  const dispatch = useDispatch();
  const changeUserData = (data) => dispatch({ type: "CHANGE_USER_DATA", data })
  const changeReferer = (data) => dispatch({ type: "CHANGE_REFERER", data })
  const [displayOtherLogin, setDisplayOtherLogin] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [button, setButton] = useState(false);
  const [submit, setSubmit] = useState({
    email: "",
    password: "",
  });

  const handleLogin = (data, endpoint) => {
    axios
      .post(
        endpoint,
        endpoint === "/authenticate-google" ? { idToken: data } : data
      )
      .then((response) => {
        console.log("login berhasil", response.data.data);
        if (!redirect) {
          // redirect ke dashboard
          setRedirect(true);

          // simpan user data ke localStorage melalui redux
          changeUserData(response.data.data);
        }
      })
      .catch((err) => {
        setButton(false);
        console.log(err);
        window.scrollTo(0, 0);
        if (err.response) {
          err.response.data.message && setError(err.response.data.message);
          err.response.data.title && setError(err.response.data.title);
        } else {
          setError("Maaf terjadi kesalahan");
        }
      });
  };

  // login user biasa yg register ke db
  const loginBiasa = (e) => {
    // spy halaman tdk refresh
    e.preventDefault();

    if (!submit.email) {
      setError("Email harus diisi");
    } else if (!submit.password) {
      setError("Password harus diisi");
    } else {
      // disable button ketika diklik login
      setButton(true);

      // lakukan fetch
      handleLogin(submit, "/authenticate");
    }
  };

  // login melalui tombol google
  const loginGoogle = (res) => {
    // console.log("respond login google", res.tokenId);
    if (!button) {
      // disable button ketika diklik login
      setButton(true);
      // lakukan fetch
      handleLogin(res.tokenId, "/authenticate-google");
    }
  };

  // menangani error google
  const errorGoogle = (err) => {
    console.log(err);
    err.error !== "idpiframe_initialization_failed" && setError(err.details);
  };

  const handleChange = (e) => {
    setSubmit({
      ...submit,
      [e.target.id]: e.target.value,
    });
  };

  const otherLogin = () => {
    window.scrollTo(0, 0);
    setDisplayOtherLogin((prevDisplay) => !prevDisplay);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    // jika user telah berhasil registrasi
    setSuccess(location.state?.registerSukses);
    setError("");

    // ubah title
    document.title = "Login - BPPT";

    // simpan referer ke redux
    changeReferer(refer)
  }, [location.state?.registerSukses]); // eslint-disable-line react-hooks/exhaustive-deps

  // pindah ke halaman dashboard
  if (redirect || userData.data.jwtToken) {
    return <Redirect to={userData.referer ? userData.referer : "/meeting/hari-ini"} />;
  }

  return (
    <div
      className="column has-background-white is-4 p-5 mb-6 login-box"
      style={{
        borderRadius: 10,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      }}
    >
      <Helmet>
        <link rel="stylesheet" href="/css/login.css" />
      </Helmet>

      <form onSubmit={loginBiasa}>
        {/* peringatan error dan success */}
        <ErrorSuccess error={error} success={success} />

        {/* gambar & link daftar */}
        <Header />

        {!displayOtherLogin && (
          <>
            {/* email */}
            <Email handleChange={handleChange} button={button} />

            {/* password */}
            <Password handleChange={handleChange} button={button} />

            <Link
              to="/account/forgot-password"
              className="has-text-info is-pulled-right mb-2"
            >
              <u>Lupa Password</u>
            </Link>

            <button
              className="button is-danger is-fullwidth is-rounded my-2"
              disabled={button}
            >
              Masuk
            </button>
          </>
        )}

        {displayOtherLogin && (
          <>
            <a
              href={config.REACT_APP_URL_LOGIN_CAS}
              className="button is-info is-fullwidth is-rounded my-2"
              disabled={button}
            >
              BPPT USER
            </a>

            <GoogleLogin
              clientId={config.REACT_APP_GOOGLE_CLIENTID}
              buttonText="Google"
              onSuccess={loginGoogle}
              onFailure={errorGoogle}
              cookiePolicy="single_host_origin"
              type="button"
              render={(renderProps) => (
                <button
                  className="button is-fullwidth is-rounded my-2"
                  onClick={renderProps.onClick}
                  disabled={button}
                >
                  <img
                    src="/img/login/logo_google_kecil.png"
                    alt="logo-google-kecil"
                    className="mr-2"
                  />{" "}
                  Google
                </button>
              )}
            />
          </>
        )}

        <p className="my-5 has-text-centered">Atau</p>

        <button
          className="button is-fullwidth is-rounded my-2"
          disabled={button}
          onClick={otherLogin}
          type="button"
        >
          {displayOtherLogin
            ? "Masuk dengan email dan password"
            : "Masuk dengan cara lain"}
        </button>
      </form>
    </div>
  );
};

export default Login;
