import React, { useRef, useState, useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import axios from "../../config/axios";
import Modal from "./file-presentasi/Modal";

const FilePresentasi = () => {
  const { roomName, scheduleId } = useParams();
  const userData = useSelector((state) => state);
  const formRef = useRef(null);
  const [uploadIndicator, setUploadIndicator] = useState("");
  const [files, setFiles] = useState([]);
  const [reactDropzoneErrors, setReactDropzoneErrors] = useState([]);
  const [presentasi, setPresentasi] = useState([]);
  const [modal, setModal] = useState({
    tampil: false,
    presentasi: null,
    slideNumber: 0,
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".ppt,.pptx,.pdf",
    // multiple: false,
    // maxFiles: 1,
    maxSize: 20971520,
    onDropAccepted: (acceptedFiles) => {
      console.log("acceptedFiles", acceptedFiles);

      const arrFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setFiles(arrFiles);
      setUploadIndicator("");
      uploadPresentasi(arrFiles);
      setReactDropzoneErrors([]);
    },
    onDropRejected: (rejectedFiles) => {
      console.log("rejectedFiles", rejectedFiles);

      setReactDropzoneErrors(rejectedFiles);
    },
  });

  const uploadPresentasi = (arrFiles) => {
    console.log("run uploadPresentasi");
    let data = new FormData(formRef.current);
    data.append("RoomName", roomName);
    data.append("ScheduleId", scheduleId);
    arrFiles.forEach((file) => {
      data.append("file", file);
    });
    setUploadIndicator("Sedang mengupload file ...");
    axios
      .post("/meeting/upload-presentation", data, {
        headers: {
          Authorization: "Bearer " + userData.data.jwtToken,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log("upload file ppt succeed", res);
        setUploadIndicator("Upload berhasil");
        setReactDropzoneErrors([]);
        setPresentasi((prevPresentasi) => {
          return [...prevPresentasi, ...res.data.data];
        });
      })
      .catch((err) => {
        console.log("upload file ppt failed", err);
        setUploadIndicator("Maaf terjadi kesalahan");
        if (err.response) {
          err.response.data.message &&
            setUploadIndicator(err.response.data.message);
          err.response.data.title &&
            setUploadIndicator(err.response.data.title);
        } else {
          setUploadIndicator("Maaf terjadi kesalahan");
        }
      });
  };

  const previewFile = (e) => {
    e.preventDefault();
    setModal({
      tampil: true,
      presentasi: presentasi.find((pre) => pre.id === parseInt(e.target.id)),
      slideNumber: 0,
    });
  };

  useEffect(() => {
    // get data presentasi yang sudah di upload
    axios
      .get(`/meeting/${roomName}/presentation/${scheduleId}`, {
        headers: {
          Authorization: "Bearer " + userData.data.jwtToken,
        },
      })
      .then((res) => {
        console.log("fetch uploaded presentations success", res.data.data);
        setPresentasi(res.data.data);
      })
      .catch((err) => {
        console.log("fetch uploaded presentations fail", err);
        setUploadIndicator("Maaf terjadi kesalahan");
        if (err.response) {
          err.response.data.message &&
            setUploadIndicator(err.response.data.message);
          err.response.data.title &&
            setUploadIndicator(err.response.data.title);
        } else {
          setUploadIndicator("Maaf terjadi kesalahan");
        }
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // pindah ke halaman login jika bkn admin
  if (userData.data.role !== "Admin") {
    return <Redirect to="/meeting?page=1" />;
  }

  return (
    <div className="column p-5">
      <p className="is-size-3">Upload File {roomName}</p>
      <p className="is-size-6 mb-5">Upload file presentasi disini</p>

      {/* upload box */}
      <RenderField label="Upload File">
        <form ref={formRef}>
          <div
            {...getRootProps()}
            style={uploadPresentasiStyle.uploadBox}
            className="my-2 is-clickable"
          >
            {files.length > 0
              ? limit20Character(files[0].name)
              : "Belum ada file"}
            <input {...getInputProps()} className="is-hidden" />
          </div>

          {/* reactDropzoneErrors  */}
          {reactDropzoneErrors.length > 0 &&
            reactDropzoneErrors.map((error, i) => (
              <div key={error.file.name + i.toString()}>
                <p>
                  <i className="fas fa-times has-text-danger mr-1"></i>
                  {error.file.name}
                </p>
                {error.errors.map((err) => (
                  <p className="is-size-7" key={err.code}>
                    {renderDropzoneError(err.code)}
                  </p>
                ))}
              </div>
            ))}

          {/* pesan sukses / error upload */}
          <div className="px-2">
            <p className="py-2">
              <i className={uploadIndicatorIcon(uploadIndicator)}></i>
              {uploadIndicator}
            </p>
          </div>
        </form>
      </RenderField>

      {/* daftar file */}
      <RenderField label="Daftar File">
        {presentasi.length > 0 ? (
          presentasi.map((slide) => (
            <a
              href="/#"
              className="panel-block"
              key={"slide" + slide.id}
              onClick={previewFile}
              title={slide.fileName}
              id={slide.id}
            >
              <span className="panel-icon">
                <i className="fas fa-file" aria-hidden="true"></i>
              </span>
              {slide.fileName.length > 50
                ? slide.fileName.substring(0, 50) + "..."
                : slide.fileName}
            </a>
          ))
        ) : (
          <p className="mt-1 has-text-grey">Belum ada file yang diupload</p>
        )}
      </RenderField>

      <Modal modal={modal} setModal={setModal} />
    </div>
  );
};

const RenderField = ({ label, children }) => (
  <div className="field is-horizontal">
    <div className="field-label is-normal">
      <label className="label">{label}</label>
    </div>
    <div className="field-body">
      <div className="field">
        <div className="control">{children}</div>
      </div>
    </div>
  </div>
);

const uploadPresentasiStyle = {
  uploadBox: {
    height: 100,
    border: "2px dashed #ccc",
    color: "#ccc",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const limit20Character = (str) =>
  str.length > 20 ? str.substring(0, 20) + "..." : str;

const uploadIndicatorIcon = (text) => {
  if (text === "Upload berhasil") {
    return "fas fa-check has-text-success mr-1";
  } else if (
    text === "Sedang mengupload file ..." ||
    text === "Mohon tunggu ..."
  ) {
    return "has-text-grey";
  } else if (text.length > 0) {
    return "fas fa-times has-text-danger mr-1";
  }

  return "";
};

const renderDropzoneError = (errorCode) => {
  const errorMessages = {
    "file-invalid-type": "Hanya file .ppt dan .pptx yang dapat diupload",
    "file-too-large": "Maksimal ukuran file 20 MB",
  };

  return errorMessages[errorCode];
};

export default FilePresentasi;
