import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "../../config/axios";
import ErrorSuccess from "../../components/user/login/ErrorSuccess";
import { cas, nonCas } from "./edit-profil/fields";

const EditProfil = ({ userData, changeUserData }) => {
  console.log("login source nih", userData.data);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [errorFields, setErrorFields] = useState({});
  const [user, setUser] = useState({}); // GET /user/id
  const [disableBtn, setDisableBtn] = useState(false); // disableBtn button & field saat fetch

  // di looping utk menghasilkan field
  let fields = [];

  if (userData.data.source === "CAS") {
    fields = cas;
  } else {
    fields = nonCas;
  }

  // onchange handler utk setiap input
  const handleChange = (e) => {
    setUser((prevUser) => {
      return {
        ...prevUser,
        [e.target.id]: e.target.value,
      };
    });
  };

  useEffect(() => {
    // GET /user/id
    axios
      .get("/accounts/" + userData.data.id, {
        headers: {
          Authorization: "Bearer " + userData.data.jwtToken,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        setUser(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        window.scrollTo(0, 0);
        if (err.response) {
          err.response.data.message && setError(err.response.data.message);
          err.response.data.title && setError(err.response.data.title);
        } else {
          setError("Maaf terjadi kesalahan");
        }
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // PUT ke /api/user
  const editProfil = (e) => {
    // spy tdk refresh halaman saat submit form
    e.preventDefault();

    if (!disableBtn) {
      // disableBtn button saat fetch
      setDisableBtn(true);

      // mulai fetch
      axios
        .put("/accounts/" + userData.data.id, user, {
          headers: {
            Authorization: "Bearer " + userData.data.jwtToken,
          },
        })
        .then((res) => {
          changeUserData({
            ...userData.data,
            ...res.data.data,
          });
          setSuccess("Edit profil berhasil");
          setDisableBtn(false);
          window.scrollTo(0, 0);
          setError("");
        })
        .catch((err) => {
          setSuccess("");
          setDisableBtn(false);
          console.log(err);
          window.scrollTo(0, 0);
          if (err.response) {
            err.response.data.message && setError(err.response.data.message);
            err.response.data.title && setError(err.response.data.title);
            err.response.data.errors &&
              setErrorFields(err.response.data.errors);
          } else {
            setError("Maaf terjadi kesalahan");
          }
        });
    }
  };

  useEffect(() => {
    // ubah title
    document.title = "Edit Profil - BPPT";
  }, []);

  return (
    <div className="column p-5">
      {/* peringatan success & error */}
      <ErrorSuccess error={error} success={success} />

      {/* judul */}
      <p className="is-size-3 mb-5">Edit Profil</p>

      <form onSubmit={editProfil}>
        {fields.map((field, i) => (
          <div className="field is-horizontal" key={field.label + i}>
            <div className="field-label is-normal">
              <label className="label">{field.label}</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <RenderInput
                    field={field}
                    defaultValue={user[field.id]}
                    handleChange={handleChange}
                  />
                </div>

                {/* error per field  */}
                {errorFields[field.error] &&
                  errorFields[field.error].map((error, i) => (
                    <small className="help is-danger" key={i.toString()}>
                      {error}
                    </small>
                  ))}
              </div>
            </div>
          </div>
        ))}

        <div className="field is-horizontal">
          <div className="field-label">{/* kosong utk kasih jarak */}</div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <button className="button is-link" disabled={disableBtn}>
                  Simpan
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

const RenderInput = ({ field, defaultValue, handleChange }) => {
  // destructuring prop field
  const { type, label, id, options } = field;

  const defaultValueValue = options?.find(
    (option) => option.value === defaultValue
  );

  // daftar jsx yg bs dipilih sesuai type
  const inputs = {
    text: (
      <input
        className="input"
        id={id}
        type="text"
        placeholder={label}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    ),
    email: (
      <input
        className="input"
        id={id}
        type="email"
        placeholder={label}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    ),
    password: (
      <input
        className="input"
        id={id}
        type="password"
        placeholder={label}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    ),
    select: (
      <div className="select">
        <select id={id} onChange={handleChange} defaultValue={defaultValue}>
          <option value={defaultValueValue?.value}>
            {defaultValueValue?.label}
          </option>

          {options
            ?.filter((option) => option.value !== defaultValue)
            .map((option) => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
        </select>
      </div>
    ),
  };

  return inputs[type];
};

const mapStateToProps = (state) => {
  return {
    userData: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeUserData: (data) => dispatch({ type: "CHANGE_USER_DATA", data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfil);
