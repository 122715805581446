import React from "react";
import dayjs from "dayjs";
import addOutlookCalendar from "./azure/addOutlookCalendar";

const AddOutlookCalendar = ({ subject, schedule }) => {
  // olah start & startDate
  const start = schedule.start.split(":");
  const startJam = parseInt(start[0]);
  const startMenit = parseInt(start[1]);
  const startDateTime = dayjs(schedule.startDate)
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)
    .add(startJam, "hour")
    .add(startMenit, "minute");

  // olah end & endDate
  const end = schedule.end.split(":");
  const endJam = parseInt(end[0]);
  const endMenit = parseInt(end[1]);
  const endDateTime = dayjs(schedule.startDate)
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)
    .add(endJam, "hour")
    .add(endMenit, "minute");

  const addEvent = () =>
    addOutlookCalendar(subject, startDateTime, endDateTime);

  return (
    <button
      className="button is-fullwidth is-info"
      title="Tambah ke Google Calendar"
      onClick={addEvent}
    >
      <i className="fab fa-microsoft mr-2"></i> Outlook Calendar
    </button>
  );
};

export default AddOutlookCalendar;
