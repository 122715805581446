import React, { useState } from "react";
import UploadGambar from "./UploadGambar";
import UploadFile from "./UploadFile";

const UploadMenu = ({
  roomName,
  scheduleId,
  userData,
  signalRConn,
  displayChatError,
  toggleShowFile,
  setIndicatorUpload,
}) => {
  const [uploadMenuOn, setUploadMenuOn] = useState(false);
  const toggleUploadMenu = () =>
    setUploadMenuOn((prevUploadMenu) => !prevUploadMenu);
  const daftarFileClick = (e) => {
    e.preventDefault();
    toggleShowFile();
    toggleUploadMenu();
  };

  return (
    <div className={uploadMenuOn ? "dropdown is-active" : "dropdown"}>
      <div className="dropdown-trigger">
        <span
          className="icon is-small is-clickable ml-2"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onClick={toggleUploadMenu}
        >
          <i className="fas fa-ellipsis-v" aria-hidden="true"></i>
        </span>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div
          className="dropdown-content"
          style={{ position: "absolute", right: 180, bottom: 50, width: 200 }}
        >
          {/* kirim gambar */}
          <UploadGambar
            roomName={roomName}
            scheduleId={scheduleId}
            userData={userData}
            signalRConn={signalRConn}
            displayChatError={displayChatError}
            toggleUploadMenu={toggleUploadMenu}
            setIndicatorUpload={setIndicatorUpload}
          />

          {/* kirim file */}
          <UploadFile
            roomName={roomName}
            scheduleId={scheduleId}
            userData={userData}
            signalRConn={signalRConn}
            displayChatError={displayChatError}
            toggleUploadMenu={toggleUploadMenu}
            setIndicatorUpload={setIndicatorUpload}
          />

          <a href="/#" className="dropdown-item" onClick={daftarFileClick}>
            Daftar file
          </a>
        </div>
      </div>
    </div>
  );
};

export default UploadMenu;
