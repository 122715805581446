import React from "react";
import { Link } from "react-router-dom";

const ModalEditMeeting = ({
  className,
  modalEditOff,
  roomName,
  scheduleId,
}) => {
  return (
    <div className={className}>
      <div
        className="modal-background"
        onClick={modalEditOff}
        style={{ opacity: 0.6 }}
      ></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Info</p>
          <button
            className="delete"
            aria-label="close"
            onClick={modalEditOff}
          ></button>
        </header>
        <section className="modal-card-body">
          Meeting ini adalah meeting yang berulang.
          <br />
          Apa anda ingin mengedit jadwal ini, atau semua jadwal?
        </section>
        <footer className="modal-card-foot">
          <Link
            to={{
              pathname: "/meeting/edit/" + roomName + "/jadwal/" + scheduleId,
              state: {
                isHost: true,
              },
            }}
            className="button is-link"
          >
            Edit Jadwal Ini
          </Link>
          <Link
            to={{
              pathname: "/meeting/edit/" + roomName,
              state: {
                isHost: true,
              },
            }}
            className="button is-info"
          >
            Edit Semua Jadwal
          </Link>

          <button className="button" onClick={modalEditOff}>
            Batal
          </button>
        </footer>
      </div>
    </div>
  );
};

export default ModalEditMeeting;
