import React from "react";

const ErrorSuccess = ({ error, success }) => {
  return (
    <div className="my-5">
      {error && <div className="notification is-danger">{error}</div>}
      {success && <div className="notification is-info">{success}</div>}
    </div>
  );
};

export default ErrorSuccess;
