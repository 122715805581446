import React, { useState, useRef, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useSelector, useDispatch } from "react-redux";
import axios from "../../../../config/axios";

const UploadPresentasi = ({
  roomName,
  scheduleId,
  presentasi,
  setPresentasi,
}) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state);
  const formRef = useRef(null);
  const [uploadIndicator, setUploadIndicator] = useState("");
  const [files, setFiles] = useState([]);
  const [reactDropzoneErrors, setReactDropzoneErrors] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".ppt,.pptx,.pdf",
    // multiple: false,
    // maxFiles: 1,
    maxSize: 20971520,
    onDropAccepted: (acceptedFiles) => {
      console.log("acceptedFiles", acceptedFiles);

      const arrFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setFiles(arrFiles);
      setUploadIndicator("");
      uploadPresentasi(arrFiles);
      setReactDropzoneErrors([]);
    },
    onDropRejected: (rejectedFiles) => {
      console.log("rejectedFiles", rejectedFiles);

      setReactDropzoneErrors(rejectedFiles);
    },
  });

  const uploadPresentasi = (arrFiles) => {
    console.log("run uploadPresentasi");
    let data = new FormData(formRef.current);
    data.append("RoomName", roomName);
    data.append("ScheduleId", scheduleId);
    arrFiles.forEach((file) => {
      data.append("file", file);
    });
    setUploadIndicator("Sedang mengupload file ...");
    axios
      .post("/meeting/upload-presentation", data, {
        headers: {
          Authorization: "Bearer " + userData.data.jwtToken,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log("upload file ppt succeed", res);
        setUploadIndicator("Upload berhasil");
        setReactDropzoneErrors([]);
        setPresentasi((prevPresentasi) => {
          return {
            ...prevPresentasi,
            slides: prevPresentasi.slides.concat(res.data.data),
          };
        });
      })
      .catch((err) => {
        console.log("upload file ppt failed", err);
        setUploadIndicator("Maaf terjadi kesalahan");
        if (err.response) {
          err.response.data.message &&
            setUploadIndicator(err.response.data.message);
          err.response.data.title &&
            setUploadIndicator(err.response.data.title);
        } else {
          setUploadIndicator("Maaf terjadi kesalahan");
        }
      });
  };

  const startPresentasi = (e) => {
    e.preventDefault();
    const presentationId = parseInt(e.target.id);

    if (userData.presentationStarted) {
      setUploadIndicator(
        "Gagal memulai presentasi, ada presentasi lain yang sedang berjalan"
      );
    } else {
      if (userData.signalRChat) {
        // cek sdh konek signalr chat / blm
        setUploadIndicator("Mohon tunggu ...");
        userData.signalRChat
          .invoke("StartPresentation", roomName, presentationId)
          .then(() => {
            console.log("StartPresentation succeed");
            setPresentasi((prevPresentasi) => {
              return {
                ...prevPresentasi,
                tampil: true,
                ...prevPresentasi.slides.find(
                  (slide) => slide.id === presentationId
                ),
              };
            });
          })
          .catch((err) => {
            console.log("StartPresentation failed", err);
            setUploadIndicator("Gagal memulai presentasi, silahkan coba lagi");
          });
      } else {
        // jika blm konek signalr chat
        setUploadIndicator("Gagal memulai presentasi, silahkan coba lagi");
      }
    }
  };

  const stopPresentasi = () => {
    if (userData.signalRChat) {
      // jika sdh connect ke signalr
      setUploadIndicator("Mohon tunggu ...");
      userData.signalRChat
        .invoke("StopPresentation", roomName, userData.presentationStarted)
        .then(() => {
          // jika berhasil stop presentasi
          console.log("StopPresentation succeed");
          setUploadIndicator("Stop presentasi berhasil");

          // ubah state presentationStarted di redux
          dispatch({
            type: "CHANGE_PRESENTATION_STATE",
            data: null,
          });

          // menampilkan daftar presentasi dan upload box utk upload file presentasi
          setPresentasi((prevPresentasi) => {
            return {
              ...prevPresentasi,
              tampil: false,
            };
          });
        })
        .catch((err) => {
          // jika gagal stop presentasi
          console.log("StopPresentation failed", err);
          setUploadIndicator("Gagal hentikan presentasi, silahkan coba lagi");
        });
    } else {
      // jika blm connect ke signalr
      setUploadIndicator("Gagal hentikan presentasi, silahkan coba lagi");
    }
  };

  useEffect(() => {
    // get data presentasi yang sudah di upload
    axios
      .get(`/meeting/${roomName}/presentation/${scheduleId}`, {
        headers: {
          Authorization: "Bearer " + userData.data.jwtToken,
        },
      })
      .then((res) => {
        console.log("fetch uploaded presentations success", res.data.data);
        setPresentasi((prevPresentasi) => {
          return {
            ...prevPresentasi,
            slides: res.data.data,
          };
        });
      })
      .catch((err) => {
        console.log("fetch uploaded presentations fail", err);
        setUploadIndicator("Maaf terjadi kesalahan");
        if (err.response) {
          err.response.data.message &&
            setUploadIndicator(err.response.data.message);
          err.response.data.title &&
            setUploadIndicator(err.response.data.title);
        } else {
          setUploadIndicator("Maaf terjadi kesalahan");
        }
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form ref={formRef}>
      {/* pesan sukses / error upload */}
      {uploadIndicator && (
        <div className="px-2 mb-2">
          <p className="py-2">
            <i className={uploadIndicatorIcon(uploadIndicator)}></i>
            {uploadIndicator}
          </p>
        </div>
      )}

      {/* tombol start presentasi */}
      {userData.presentationStarted && (
        <div className="px-2 mb-2">
          <button
            className={
              uploadIndicator === "Mohon tunggu..."
                ? "button is-fullwidth is-danger is-loading"
                : "button is-fullwidth is-danger"
            }
            onClick={stopPresentasi}
            type="button"
            disabled={uploadIndicator === "Mohon tunggu..."}
          >
            Hentikan Presentasi
          </button>
        </div>
      )}

      {/* nama file presentasi */}
      <p>
        <strong>
          Klik atau drag untuk upload file presentasi (.ppt / .pptx)
        </strong>
      </p>

      <div
        {...getRootProps()}
        style={uploadPresentasiStyle.uploadBox}
        className="my-2 is-clickable"
      >
        {files.length > 0 ? limit20Character(files[0].name) : "Belum ada file"}
        <input {...getInputProps()} className="is-hidden" />
      </div>

      {/* reactDropzoneErrors  */}
      {reactDropzoneErrors.length > 0 &&
        reactDropzoneErrors.map((error, i) => (
          <div key={error.file.name + i.toString()}>
            <p>
              <i className="fas fa-times has-text-danger mr-1"></i>
              {error.file.name}
            </p>
            {error.errors.map((err) => (
              <p className="is-size-7">{renderDropzoneError(err.code)}</p>
            ))}
          </div>
        ))}

      <div className="py-2">
        <small>
          Untuk memulai presentasi, silahkan klik file yang sudah diupload di
          bawah:
        </small>
      </div>

      {/* daftar file yg telah diupload */}
      <div className="py-2">
        {presentasi.slides.length > 0 ? (
          presentasi.slides.map((slide) => (
            <a
              href="/#"
              className="panel-block"
              key={"slide" + slide.id}
              onClick={startPresentasi}
              title={slide.fileName}
              id={slide.id}
            >
              <span className="panel-icon">
                <i className="fas fa-file" aria-hidden="true"></i>
              </span>
              {slide.fileName.length > 20
                ? slide.fileName.substring(0, 20) + "..."
                : slide.fileName}
            </a>
          ))
        ) : (
          <div className="py-2">
            <small className="has-text-grey">Belum ada file presentasi</small>
          </div>
        )}
      </div>
    </form>
  );
};

const limit20Character = (str) =>
  str.length > 20 ? str.substring(0, 20) + "..." : str;

const uploadIndicatorIcon = (text) => {
  if (text === "Upload berhasil" || text === "Stop presentasi berhasil") {
    return "fas fa-check has-text-success mr-1";
  } else if (
    text === "Sedang mengupload file ..." ||
    text === "Mohon tunggu ..."
  ) {
    return "has-text-grey";
  } else if (text.length > 0) {
    return "fas fa-times has-text-danger mr-1";
  }

  return "";
};

const renderDropzoneError = (errorCode) => {
  const errorMessages = {
    "file-invalid-type": "Hanya file .ppt dan .pptx yang dapat diupload",
    "file-too-large": "Maksimal ukuran file 20 MB",
  };

  return errorMessages[errorCode];
};

const uploadPresentasiStyle = {
  uploadBox: {
    height: 100,
    border: "2px dashed #ccc",
    color: "#ccc",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default UploadPresentasi;
