import React, { useEffect, useState, useReducer } from "react";
import { Redirect, useParams, Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "../../config/axios";
import formatTanggal from "../../helpers/formatTanggal";
import ReactHtmlParser from "react-html-parser";
import { config } from "../../config";

const DetailNews = ({ userData }) => {
  const [error, setError] = useState("");

  // redirect ke /berita jika berhasil hapus berita
  const [redirectDelete, setRedirectDelete] = useState(false);

  // useReducer utk menangani modal
  const [stateModal, dispatchModal] = useReducer(modalReducer, initModal);

  // menampilkan modal konfirmasi hapus news
  const konfirmasiHapus = (e) => {
    e.preventDefault(); // spy ga pindah halaman
    dispatchModal({ type: "MODAL_AKTIF" });
  };

  // hapus News
  const deleteNews = () => {
    if (!stateModal.disableModalButton) {
      // disable modal button saat fetch
      dispatchModal({ type: "DISABLE_MODAL_BUTTON" });

      // mulai fetch
      axios
        .delete("/news/" + news.slug, {
          headers: {
            Authorization: "Bearer " + userData.data.jwtToken,
          },
        })
        .then(() => setRedirectDelete(true))
        .catch((err) => {
          dispatchModal({ type: "ENABLE_MODAL_BUTTON" });
          console.log(err);
          window.scrollTo(0, 0);
          if (err.response) {
            err.response.data.message && setError(err.response.data.message);
            err.response.data.title && setError(err.response.data.title);
          } else {
            setError("Maaf terjadi kesalahan");
          }
        });
    }
  };

  // ambil slug dr param
  const { slug } = useParams();

  // redirect jika slug tdk ditemukan di db
  const [redirectSlug, setRedirectSlug] = useState(false);

  // menampilkan loading saat data meeting sedang di fetch
  const [loading, setLoading] = useState(true);

  // data news
  const [news, setNews] = useState({});

  useEffect(() => {
    // ubah title dg judul news
    document.title = `${news.title ? news.title : "Detail News"} - BPPT`;

    // fetch data news
    if (slug) {
      axios
        .get("/news/" + slug, {
          headers: {
            Authorization: "Bearer " + userData.data.jwtToken,
          },
        })
        .then((res) => {
          setNews(res.data.data);
          setLoading(false);
        })
        .catch((err) => {
          setRedirectSlug(true);
          setLoading(false);
          console.log(err);
          window.scrollTo(0, 0);
          if (err.response) {
            err.response.data.message && setError(err.response.data.message);
            err.response.data.title && setError(err.response.data.title);
          } else {
            setError("Maaf terjadi kesalahan");
          }
        });
    }
  }, [slug, userData.data.jwtToken, news.title]);

  // cm user yg sdh login yg boleh akses halaman ini
  if (redirectDelete) {
    return (
      <Redirect
        to={{
          pathname: "/berita",
          state: { pesanSukses: "Hapus berita berhasil" },
        }}
      />
    );
  }

  return (
    <div className="column p-5">
      {slug && loading ? (
        <progress className="progress is-small is-info" max="100"></progress>
      ) : redirectSlug ? (
        <p className="has-text-centered has-text-grey-light">
          {error ? error : "Maaf terjadi kesalahan"}
        </p>
      ) : (
        <div className="card">
          <div className="card-image">
            <figure className="image">
              <img
                src={
                  news.featuredImage
                    ? config.REACT_APP_URL_GAMBAR +
                      news.featuredImage.imageDefault
                    : "/img/placeholder/bppt800x800.png"
                }
                alt={news.slug}
              />
            </figure>
          </div>
          <div className="card-content">
            <div className="media">
              <div className="media-content">
                <p className="title is-4">{news.title}</p>
                <p className="subtitle is-6">{news.author.fullName}</p>
              </div>
            </div>

            <div className="content">
              {ReactHtmlParser(news.content)}
              <br />
              <p className="has-text-grey-light">
                {formatTanggal(news.created)}
              </p>
            </div>

            {/* tampilkan tombol edit & delete jika admin */}
            {userData.data.role === "Admin" && (
              <footer className="card-footer">
                <Link
                  to={"/berita/edit/" + news.slug}
                  className="card-footer-item"
                >
                  Edit Berita
                </Link>
                <button
                  className="button is-white card-footer-item has-text-danger"
                  onClick={konfirmasiHapus}
                >
                  Hapus Berita
                </button>
              </footer>
            )}
          </div>
        </div>
      )}

      {/* modal konfirmasi hapus news */}
      <ModalDelete
        deleteNews={deleteNews}
        stateModal={stateModal}
        dispatchModal={dispatchModal}
      />
    </div>
  );
};

// utk useReducer
const initModal = {
  modalClass: "modal",
  disableModalButton: false,
};

// utk useReducer
const modalReducer = (state = initModal, action) => {
  switch (action.type) {
    case "MODAL_AKTIF":
      return {
        ...initModal,
        modalClass: "modal is-active",
      };

    case "MODAL_MATI":
      return {
        ...initModal,
        modalClass: "modal",
      };

    case "DISABLE_MODAL_BUTTON":
      return {
        ...initModal,
        disableModalButton: true,
      };

    case "ENABLE_MODAL_BUTTON":
      return {
        ...initModal,
        disableModalButton: false,
      };

    default:
      return state;
  }
};

// modal konfirmasi hapus news
const ModalDelete = ({ deleteNews, stateModal, dispatchModal }) => {
  const matikanModal = () => dispatchModal({ type: "MODAL_MATI" });

  return (
    <div className={stateModal.modalClass}>
      <div
        className="modal-background"
        onClick={matikanModal}
        style={{ opacity: 0.6 }}
      ></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Info</p>
          <button
            className="delete"
            aria-label="close"
            onClick={matikanModal}
            disabled={stateModal.disableModalButton}
          ></button>
        </header>
        <section className="modal-card-body">
          Anda yakin ingin menghapus berita?
        </section>
        <footer className="modal-card-foot">
          <button
            className="button is-danger"
            onClick={deleteNews}
            disabled={stateModal.disableModalButton}
          >
            Hapus Berita
          </button>
          <button
            className="button"
            onClick={matikanModal}
            disabled={stateModal.disableModalButton}
          >
            Batal
          </button>
        </footer>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state,
  };
};

export default connect(mapStateToProps)(DetailNews);
