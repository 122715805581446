import { config } from "../../../config";

// inject iframe jitsi ke html
export const loadJitsiScript = () => {
  let resolveLoadJitsiScriptPromise = null;

  const loadJitsiScriptPromise = new Promise((resolve) => {
    resolveLoadJitsiScriptPromise = resolve;
  });

  const script = document.createElement("script");
  script.src = config.REACT_APP_URL_JITSI + "/external_api.js";
  script.async = true;
  script.onload = () => resolveLoadJitsiScriptPromise(true);
  document.body.appendChild(script);

  return loadJitsiScriptPromise;
};
