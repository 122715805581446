import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const DownloadExcel = ({ fetchStatistik, roomName }) => {
  return (
    <ExcelFile
      filename={`kehadiran-${roomName}`}
      element={
        <button className="button is-small is-link">
          <i className="fas fa-file-excel mr-2"></i>
          Download Kehadiran
        </button>
      }
    >
      <ExcelSheet data={fetchStatistik.jadwal} name="Jadwal">
        <ExcelColumn label="Kode Meeting" value="roomName" />
        <ExcelColumn label="Tanggal" value="startDate" />
        <ExcelColumn label="Jam Mulai" value="start" />
        <ExcelColumn label="Jam Berakhir" value="end" />
      </ExcelSheet>

      {fetchStatistik.meetingLog.length > 0 && (
        <ExcelSheet data={fetchStatistik.meetingLog} name="Kehadiran">
          <ExcelColumn label="User ID" value="id" />
          <ExcelColumn label="Nama User" value="username" />
          <ExcelColumn label="Bergabung" value="bergabung" />
          <ExcelColumn label="Keluar" value="keluar" />
          <ExcelColumn label="Durasi" value="durasi" />
        </ExcelSheet>
      )}

      {fetchStatistik.participatedUser.length > 0 && (
        <ExcelSheet
          data={fetchStatistik.participatedUser}
          name="Daftar Partisipan"
        >
          <ExcelColumn label="User ID" value="id" />
          <ExcelColumn label="Nama User" value="username" />
        </ExcelSheet>
      )}

      {fetchStatistik.totalDuration.length > 0 && (
        <ExcelSheet
          data={fetchStatistik.totalDuration}
          name="Total Durasi Hadir"
        >
          <ExcelColumn label="User ID" value="id" />
          <ExcelColumn label="Nama User" value="username" />
          <ExcelColumn label="Total Durasi Hadir" value="totalDuration" />
        </ExcelSheet>
      )}
    </ExcelFile>
  );
};

export default DownloadExcel;
