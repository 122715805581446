import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

const VideoTimer = ({ schedule, setTimerOn, timerOn }) => {
  const [menit, setMenit] = useState("");
  const [className, setClassName] = useState("modal");
  const modalOff = () => setClassName("modal");

  useEffect(() => {
    const bandingkan = () => {
      console.log("run bandingkan");
      const end = schedule.end.split(":");
      const berakhir = dayjs(schedule.startDate)
        .hour(parseInt(end[0]))
        .minute(parseInt(end[1]))
        .second(0)
        .millisecond(0)
        .toDate()
        .valueOf();

      const sisaMenit = berakhir - new Date();

      if (sisaMenit < 60000 * 15 && sisaMenit > 0) {
        setClassName("modal is-active");
        setMenit(sisaMenit.toString()[0]);
      }
    };

    bandingkan();

    setInterval(bandingkan, 60000 * 5);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={className}>
      <div className="modal-background"></div>
      <div className="modal-content">
        <header className="modal-card-head">
          <p className="modal-card-title">Info</p>
          <button
            className="delete"
            aria-label="close"
            onClick={modalOff}
          ></button>
        </header>
        <section className="modal-card-body">
          Meeting akan segera berakhir {menit && `dalam ${menit} menit`}
        </section>
        <footer className="modal-card-foot">
          <button className="button is-link" onClick={modalOff}>
            Ok
          </button>
        </footer>
      </div>
      <button className="modal-close is-large" aria-label="close"></button>
    </div>
  );
};

export default VideoTimer;
