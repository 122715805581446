import React from "react";
import { config } from "../../../config";

const SideLogin = () => {
  return (
    <div
      className="column has-background-white is-4 p-5 mb-6 has-text-centered login-box"
      style={{
        borderRadius: 10,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      }}
    >
      {/* gambar header side login */}
      <img
        src="/img/login/bppt_biru_kecil.png"
        alt="logo-bppt-kecil"
        style={{ position: "absolute" }}
      />
      <img src="/img/login/org_meeting.png" alt="gambar-orang-lagi-meeting" />

      <p className="has-text-left py-2 px-5">
        <strong className="has-text-danger">BPPT</strong> -{" "}
        {config.REACT_APP_LOGIN_TEXT}
      </p>

      {/* gambar header side login */}
      <img
        src="/img/login/bottom_side_login.png"
        alt="gambar-logo-dibawah-side-login"
        className="mt-6"
      />
    </div>
  );
};

export default SideLogin;
