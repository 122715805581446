import React, { useState, useEffect, useRef } from "react";
import ChatPanel from "./chat-panel/ChatPanel";
import ParticipantPanel from "./participant-panel/ParticipantPanel";
import PembicaraPanel from "./pembicara-panel/PembicaraPanel";
import PresentasiPanel from "./presentasi-panel/PresentasiPanel";
import { useSelector, useDispatch } from "react-redux";
import PanelsHeader from "./panels/PanelsHeader";
import PanelsButton from "./panels/PanelsButton";
import { Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VideoTimer from "./VideoTimer";
import axios from "../../../config/axios";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { toast } from "react-toastify";
import CustomToast from "./panels/CustomToast";
import fetchOnlineParticipants from "./panels/fetchOnlineParticipants";
import SpeakerToast from "./panels/SpeakerToast";
import { config } from "../../../config";
import dayjs from "dayjs";
import initializeJitsiHelper from "./initializeJitsiHelper";
import tombolJitsi from "../../../config/tombolJitsi";

const Panels = ({
  stateParticipants,
  dispatchParticipants,
  roomName,
  scheduleId,
  meetingDetail,
  jitsiId,
  jitsi,
  tileView,
  footer,
  setMeetingDetail,
  kebutuhanInitializeJitsiHelper,
}) => {
  const chatParrentRef = useRef(null);
  const chatDiv = useRef(null);
  const userData = useSelector((state) => state);
  const [redirectEndmeeting, setRedirectEndmeeting] = useState(false);
  const [recipentProps, setRecipentProps] = useState({});
  const [jumlahGambar, setJumlahGambar] = useState(0);
  const [jumlahFile, setJumlahFile] = useState(0);
  const [error, setError] = useState(false);
  const [signalRConn, setSignalRConn] = useState(null);
  const [messages, setMessages] = useState([]);
  const [blmDibaca, setBlmDibaca] = useState(0);
  const [tabs, setTabs] = useState({
    participant: "is-active",
  });

  const dispatch = useDispatch();
  const changeSignalRChatData = (data) =>
    dispatch({
      type: "CHANGE_SIGNALRCHAT_DATA",
      data,
    });
  const disableIjinBicara = (data) =>
    dispatch({
      type: "DISABLE_IJIN_BICARA",
      data,
    });

  const gantiTab = (e) => {
    e.preventDefault();
    const namaTab = e.target.id;
    namaTab === "chat" && setBlmDibaca(0);
    setTabs({
      [namaTab]: "is-active",
    });
  };

  const tambahNotifChat = (namaClass) => {
    // jika chat panel sedang tersembunyi, tambahkan notifikasi jika ada
    if (namaClass === "is-hidden") {
      setBlmDibaca((prevJumlah) => prevJumlah + 1);
    }
  };

  const tileViewOff = () => {
    console.log("tile view off run");
    if (tileView) {
      console.log("jitsi execute command tile view run");
      jitsi.executeCommand("toggleTileView");
    }
  };

  useEffect(() => {
    // signalr chat
    let unmounted = 0;
    let retryCount = 0;
    let retryDelay = [5000, 10000, 15000];
    const jitsiIdBoom = jitsiId ? jitsiId : "0";
    const connection = new HubConnectionBuilder()
      .withUrl(
        config.REACT_APP_URL_SIGNALR +
          "/hub/chat?access_token=" +
          userData.data.jwtToken +
          "&roomName=" +
          roomName +
          "&jitsiId=" +
          jitsiIdBoom
      )
      .build();

    connection.serverTimeoutInMilliseconds = 1800000;

    // jika ada pesan baru
    connection.on("newMessage", (messageData) => {
      console.log("chat baru nih", messageData);

      // nama class div yg membungkus chat panel
      const namaClass = chatParrentRef.current.className;

      // kasih notifikasi +1 pesan baru, jika tdk sedang melihat chat panel
      tambahNotifChat(namaClass);

      // tampilkan pesan baru ke chat panel
      setMessages((prevMessages) => {
        return [...prevMessages, messageData];
      });

      // update state jumlahGambar jika ada gambar baru
      messageData.type === 1 && setJumlahGambar((jumlah) => jumlah + 1);

      // update state jumlahFile jika ada file baru
      messageData.type === 3 && setJumlahFile((jumlah) => jumlah + 1);

      // setiap ada chat terbaru, dia akan scroll ke chat plg bwh
      chatDiv.current.scrollTo(0, chatDiv.current.scrollHeight);
    });

    // jika ada pesan private baru
    connection.on("newPrivateMessage", (messageData) => {
      console.log(messageData);

      // nama class div yg membungkus chat panel
      const namaClass = chatParrentRef.current.className;

      // kasih notifikasi +1 pesan baru, jika tdk sedang melihat chat panel
      tambahNotifChat(namaClass);

      // tampilkan pesan baru ke chat panel
      setMessages((prevMessages) => {
        return [...prevMessages, { ...messageData, private: true }];
      });

      // setiap ada chat terbaru, dia akan scroll ke chat plg bwh
      chatDiv.current.scrollTo(0, chatDiv.current.scrollHeight);
    });

    // utk smentara gtau fungsi ny utk ap dan blm jln (mgkn hrsny ketika user gabung dia dpt smua chat)
    connection.on("newMessages", (messagesData) => {
      console.log("newMessages", messagesData);
    });

    // jika ada error
    connection.on("onError", function (message) {
      console.log("onError", message);
      setError(true);
      connection.stop();
      loop();
    });

    // jika ada user yg connect ke video meeting
    connection.on("userConnect", function (data) {
      console.log("user connect", data);
      const sdhAda = stateParticipants.participants.find(
        (part) => part.id === data.userId
      );
      console.log("sdhAda", sdhAda);
      !sdhAda && dispatchParticipants({ type: "ADD_PARTICIPANT", data });
    });

    // jika ada user yg disconnect dr video meeting
    connection.on("userDisconnect", function (data) {
      console.log("user disconnect", data);
      dispatchParticipants({ type: "REMOVE_PARTICIPANT", data });
    });

    connection.on("onTerminateMeeting", function (data) {
      console.log("onterminate nih");
      setRedirectEndmeeting(true);
    });

    connection.on("onForceRefresh", function (userId) {
      console.log("onrefresh nih");
      if (userData.data.id !== userId) {
        window.location.reload();
      }
    });

    connection.on("pendingParticipant", function (userObj, participantId) {
      console.log("pendingParticipant", userObj, participantId);

      if (userData.data.role === "Admin" || meetingDetail.isHost) {
        toast.info(
          <CustomToast
            roomName={roomName}
            participantId={participantId}
            userObj={userObj}
          />,
          {
            position: toast.POSITION.TOP_LEFT,
            autoClose: false,
          }
        );
      }
    });

    connection.on("onNewRequestSpeaker", function (obj) {
      console.log("onNewRequestSpeaker nih");
      if (meetingDetail.isSpeaker) {
        toast.warn(
          <SpeakerToast
            roomName={roomName}
            userObj={obj}
            dispatchParticipants={dispatchParticipants}
          />,
          {
            position: toast.POSITION.TOP_LEFT,
            autoClose: false,
          }
        );
      }
    });

    connection.on("onNewRequestSpeakerResponse", function (userId, isAccepted) {
      console.log("onNewRequestSpeakerResponse nih");
      if (isAccepted) {
        dispatchParticipants({
          type: "CHANGE_ISSPEAKER",
          data: {
            userId,
            isSpeaker: true,
          },
        });
      }

      if (userData.data.id === userId) {
        if (isAccepted) {
          // window.location.reload();

          // memunculkan div utk diisi iframe jitsi
          setMeetingDetail((prevMeetingDetail) => {
            return {
              ...prevMeetingDetail,
              isSpeaker: true,
            };
          });

          const {
            setPasswordRoom,
            jitsiContainerId,
            setJitsiId,
            setJitsi,
            setRedirectKick,
          } = kebutuhanInitializeJitsiHelper;

          initializeJitsiHelper(
            meetingDetail,
            roomName,
            userData,
            setPasswordRoom,
            tombolJitsi,
            jitsiContainerId,
            dispatchParticipants,
            setJitsiId,
            0,
            setJitsi,
            setRedirectKick
          );
        } else {
          toast.warn("Maaf anda belum diijinkan untuk bicara", {
            position: toast.POSITION.TOP_LEFT,
          });

          // mengaktifkan kembali tombol Ijin Bicara di Streaming.js
          disableIjinBicara(false);
        }
      }
    });

    connection.on("onRevokeSpeaker", function (userId) {
      console.log("onRevokeSpeaker nih");
      dispatchParticipants({
        type: "CHANGE_ISSPEAKER",
        data: {
          userId,
          isSpeaker: false,
        },
      });

      if (userData.data.id === userId) {
        // window.location.reload();

        // memunculkan component Streaming dan hide div jitsi
        setMeetingDetail((prevMeetingDetail) => {
          return {
            ...prevMeetingDetail,
            isSpeaker: false,
          };
        });

        // mengaktifkan kembali tombol Ijin Bicara di Streaming.js
        disableIjinBicara(false);
      }
    });

    // if there is a presentation
    connection.on("onHasPresentation", function (obj) {
      console.log("onHasPresentation from react", obj);

      dispatch({
        type: "CHANGE_PRESENTATION_STATE",
        data: obj.presentationId,
      });

      tileViewOff();
    });

    // when the presentation start
    connection.on("onStartPresentation", function (slideObj) {
      console.log("onStartPresentation from react", slideObj);

      dispatch({
        type: "CHANGE_PRESENTATION_STATE",
        data: slideObj.id,
      });

      tileViewOff();
    });

    // when the presentation stop
    connection.on("onStopPresentation", function (slideObj) {
      console.log("onStopPresentation from react", slideObj);

      dispatch({
        type: "CHANGE_PRESENTATION_STATE",
        data: null,
      });
    });

    const startSignalR = () => {
      unmounted = 0;

      if (signalRConn) {
        connection.stop();
      }

      connection
        .start()
        .then(() => {
          retryCount = 0;
          console.log("signalR chat started");
          setSignalRConn(connection);
          changeSignalRChatData(connection);
          setTimeout(fetchAfter5Seconds, 5000);
          // invoke HasPresentation to check whether there is a presentation or not
          connection
            .invoke("HasPresentation", roomName)
            .then(() => console.log("invoke HasPresentation success"))
            .then((err) => console.log("invoke HasPresentation fail", err));
        })
        .catch((err) => {
          console.log("signalR chat error", err);
          connection.stop();
          loop();
        });
    };

    const fetchAfter5Seconds = () => {
      fetchOnlineParticipants(
        roomName,
        userData.data.jwtToken,
        dispatchParticipants
      );
    };

    const callSignalR = () => {
      console.log("call signalr");

      // jika admin / host
      if (userData.data.role === "Admin" || meetingDetail.isHost) {
        if (!meetingDetail.isStarted) {
          // isStarted diubah dlu
          axios
            .post(
              `/meeting/${roomName}/start/${meetingDetail.schedule.id}`,
              null,
              {
                headers: {
                  Authorization: "Bearer " + userData.data.jwtToken,
                },
              }
            )
            .then((res) => {
              console.log("update istarted succeed", res);
              startSignalR();
            })
            .catch((err) => {
              console.log("update istarted failed", err);
              startSignalR();
            });
        } else {
          startSignalR();
        }
      } else {
        // jika bkn admin / host
        startSignalR();
      }
    };

    const loop = () => {
      if (unmounted === 0) {
        if (retryCount === retryDelay.length) {
          toast.warn(
            "Maaf anda terputus dari server, browser akan restart dalam 5 detik",
            {
              position: toast.POSITION.TOP_LEFT,
              onClose: () => window.location.reload(),
            }
          );
        }

        console.log("call loop");
        setTimeout(callSignalR, retryDelay[retryCount]);
        retryCount++;
      }
    };

    connection.onclose(loop);

    callSignalR();
    // signalr chat end

    // fetch chat history
    axios
      .get(`/meeting/${roomName}/history/${scheduleId}`, {
        headers: {
          Authorization: "Bearer " + userData.data.jwtToken,
        },
      })
      .then((res) => {
        const data = res.data.data.chatLog;
        console.log("fetch chat history succeed", data);
        if (data.length > 0) {
          console.log("data.length lebih dr 0 nih");
          const chatsHistory = data.map((chat, i) => {
            const { fullName, created, content } = chat;
            console.log("fullName dr single obj chat history", fullName);

            return {
              id: fullName + i,
              type: 0,
              content,
              created: dayjs(created).add(7, "hour"),
              author: {
                fullName,
                id: 0,
              },
            };
          });

          setMessages(chatsHistory);
        }
      })
      .catch((err) => console.log("fetch chat history failed", err));
    // fetch chat history end

    // function yg dijalankan ketika component unmount (utk mematikan signalr chat jika user dikick)
    return () => {
      unmounted = 1;
      console.log("signalr chat unmounted");
      connection.stop();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (redirectEndmeeting) {
    return (
      <Redirect
        to={{
          pathname: "/meeting/hari-ini",
          state: { pesanGagal: "Meeting telah berakhir" },
        }}
      />
    );
  }

  return (
    <>
      {/* jika waktu berakhir, kasih pemberitahuan */}
      <VideoTimer schedule={meetingDetail.schedule} />

      {/* jika ada partisipan msk di meeting private, muncul notifikasi */}
      <ToastContainer />

      <article
        className="panel is-link"
        style={{
          backgroundColor: "#fff",
          height: "100vh",
          position: "relative",
          maxHeight: "100vh",
        }}
      >
        {signalRConn ? (
          <>
            <PanelsHeader
              tabs={tabs}
              gantiTab={gantiTab}
              blmDibaca={blmDibaca}
              meetingDetail={meetingDetail}
              jumlahPartisipan={stateParticipants.participants.length}
              jumlahPembicara={
                stateParticipants.participants.filter(
                  (participant) => participant.isSpeaker
                ).length
              }
            />

            <ParticipantPanel
              show={tabs.participant}
              stateParticipants={stateParticipants}
              dispatchParticipants={dispatchParticipants}
              roomName={roomName}
              setTabs={setTabs}
              setRecipentProps={setRecipentProps}
              signalRConn={signalRConn}
              isHost={meetingDetail.isHost}
              jitsi={jitsi}
              meetingType={meetingDetail.meetingType}
            />

            <PembicaraPanel
              show={tabs.pembicara}
              stateParticipants={stateParticipants}
              dispatchParticipants={dispatchParticipants}
              roomName={roomName}
              setTabs={setTabs}
              setRecipentProps={setRecipentProps}
              signalRConn={signalRConn}
              isHost={meetingDetail.isHost}
              jitsi={jitsi}
              meetingType={meetingDetail.meetingType}
            />
          </>
        ) : (
          <div className="ml-2 mr-4 my-2">
            <progress
              className="progress is-small is-info m-2"
              max="100"
            ></progress>
          </div>
        )}

        <ChatPanel
          show={tabs.chat}
          roomName={roomName}
          scheduleId={scheduleId}
          signalRConn={signalRConn}
          error={error}
          messages={messages}
          chatDiv={chatDiv}
          jumlahGambar={jumlahGambar}
          setJumlahGambar={setJumlahGambar}
          jumlahFile={jumlahFile}
          setJumlahFile={setJumlahFile}
          chatParrentRef={chatParrentRef}
          recipentProps={recipentProps}
        />

        <PresentasiPanel
          show={tabs.presentasi}
          roomName={roomName}
          scheduleId={scheduleId}
        />

        <PanelsButton
          signalRConn={signalRConn}
          roomName={roomName}
          isHost={meetingDetail.isHost}
          scheduleId={meetingDetail.schedule.id}
          jitsi={jitsi}
          meetingType={meetingDetail.meetingType}
        >
          {footer}
        </PanelsButton>
      </article>
    </>
  );
};

export default Panels;
