import React from "react";
import { downloadFile } from "../video/chat-panel/RenderMessage";
import { config } from "../../../config";
import dayjs from "dayjs";
import "dayjs/locale/id";
dayjs.locale("id");

const RenderFileHistory = ({ file }) => {
  return (
    <>
      {/* icon org & nama user */}
      <p>
        <i className="fas fa-user mr-2"></i>
        <small>{file.author.fullName}</small>
      </p>

      {/* isi file */}
      <p
        className="has-background-light p-2"
        style={{ borderRadius: 3, maxWidth: "80%", wordWrap: "break-word" }}
      >
        <a
          href={config.REACT_APP_URL_GAMBAR + file.downloadUri}
          onClick={downloadFile}
        >
          {file.fileName}
        </a>
      </p>
    </>
  );
};

export default RenderFileHistory;
