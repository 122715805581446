import React, { useState } from "react";
import { config } from "../../../config";
import dayjs from "dayjs";
import "dayjs/locale/id";
dayjs.locale("id");

const RenderImageHistory = ({ image }) => {
  const [imageModal, setImageModal] = useState({
    className: "modal",
    imgSrc: "",
    imgAlt: "",
  });

  const imageModalOn = (e) => {
    setImageModal({
      className: "modal is-active",
      imgSrc: e.target.id,
      alt: e.target.alt,
    });
  };

  const imageModalOff = () => setImageModal({ className: "modal" });

  return (
    <>
      {/* icon org & nama user */}
      <p>
        <i className="fas fa-user mr-2"></i>
        <small>{image.author.fullName}</small>
      </p>

      {/* isi image */}
      <p className="has-background-light p-2" style={{ borderRadius: 3 }}>
        <>
          <img
            className="is-clickable"
            src={config.REACT_APP_URL_GAMBAR + image.imageSmall}
            alt={`gambar ${image.id} dari ${image.author.fullName}`}
            id={config.REACT_APP_URL_GAMBAR + image.imageDefault}
            onClick={imageModalOn}
          />

          {/* image modal */}
          <div className={imageModal.className}>
            <div className="modal-background"></div>
            <div className="modal-content">
              <p className="image">
                <img src={imageModal.imgSrc} alt={imageModal.imgAlt} />
              </p>
            </div>
            <button
              className="modal-close is-large"
              aria-label="close"
              onClick={imageModalOff}
            ></button>
          </div>
        </>
      </p>
    </>
  );
};

export default RenderImageHistory;
