import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import axios from "../../config/axios";
import { useDispatch, useSelector } from "react-redux";

const AuthenticateCas = ({ location }) => {
  // ambil url search params
  const token = new URLSearchParams(location.search).get("token");
  const userData = useSelector((state) => state);
  const dispatch = useDispatch();
  const changeUserData = (data) => dispatch({ type: "CHANGE_USER_DATA", data });
  const [redirectMeeting, setRedirectMeeting] = useState(false);
  const [pesan, setPesan] = useState("Harap tunggu...");

  useEffect(() => {
    document.title = "Autentikasi CAS - BPPT";

    axios
      .post("/authenticate-cas", {
        casToken: token,
      })
      .then((res) => {
        setRedirectMeeting(true);
        changeUserData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        window.scrollTo(0, 0);
        if (err.response) {
          err.response.data.message && setPesan(err.response.data.message);
          err.response.data.title && setPesan(err.response.data.title);
        } else {
          setPesan("Maaf terjadi kesalahan");
        }
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (redirectMeeting) {
    return <Redirect to={userData.referer ? userData.referer : "/meeting/hari-ini"} />;
  }

  return <div className="column p-5">{pesan}</div>;
};

export default AuthenticateCas;
