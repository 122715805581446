import React, { useState, useEffect, useReducer } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import axios from "../../config/axios";
import { sortMeeting } from "./Meetings";
import ReactPaginate from "react-paginate";
import ErrorSuccess from "./meetings/ErrorSuccess";
import KotakCari from "./meetings/KotakCari";
import { pesanReducer, initPesan } from "./Meetings";
import ModalEditMeeting from "./meetings/ModalEditMeeting";
import RenderPerMeeting from "./atur-meeting/RenderPerMeeting";

const AturMeeting = ({ userData, location }) => {
  const history = useHistory(); // utk push halaman pagination di url
  const pageFromParams = new URLSearchParams(location.search).get("page");
  // pk useReducer dr Meetings
  const [statePesan, dispatchPesan] = useReducer(pesanReducer, initPesan);
  // ambil params dr url
  const [page, setPage] = useState(pageFromParams ? pageFromParams : 1);
  // atur pencarian meeting
  const [query, setQuery] = useState("");
  // utk render loading bar
  const [meetingLoading, setMeetingLoading] = useState(true);
  // atur tampil modal utk edit meeting
  const [modalEdit, setModalEdit] = useState({
    className: "modal",
    roomName: "",
    scheduleId: 0,
  });

  const modalEditOn = (e) => {
    setModalEdit({
      className: "modal is-active",
      roomName: e.target.title,
      scheduleId: e.target.id,
    });
  };

  const modalEditOff = () =>
    setModalEdit({
      className: "modal",
    });
  // meeting personal
  const [totalPage, setTotalPage] = useState(0);
  const [meetings, setMeetings] = useState([]);

  const onPageClick = (e) => {
    const halaman = e.selected + 1;
    history.push("?page=" + halaman);
    setPage(halaman);
    window.scrollTo(0, 0);
  };

  const changeCari = (e) => {
    setQuery(e.target.value);
    setPage(1);
  };

  const clearSuccess = () => dispatchPesan({ type: "RESET_SUKSES" });
  const clearError = () => dispatchPesan({ type: "RESET_GAGAL" });

  useEffect(() => {
    if (!meetingLoading) {
      setMeetingLoading(true);

      // jika query pencarian berubah
      axios
        .get("/meeting/personal?query=" + query, {
          headers: {
            Authorization: "Bearer " + userData.data.jwtToken,
          },
        })
        .then((res) => {
          console.log(res);
          setTotalPage(res.data.totalPage);
          setMeetings(sortMeeting(res.data.data));
          setMeetingLoading(false);
        })
        .catch((err) => {
          setMeetingLoading(false);
          window.scrollTo(0, 0);
          console.log(err);
          if (err.response) {
            err.response.data.message &&
              dispatchPesan({ type: "GAGAL", data: err.response.data.message });
            err.response.data.title &&
              dispatchPesan({ type: "GAGAL", data: err.response.data.title });
          } else {
            dispatchPesan({ type: "GAGAL", data: "Maaf terjadi kesalahan" });
          }
        });
    }
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // ubah title
    document.title = "Atur Meeting - BPPT";

    // render meeting yg diikuti user
    axios
      .get("/meeting/personal?page=" + page, {
        headers: {
          Authorization: "Bearer " + userData.data.jwtToken,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        setTotalPage(res.data.totalPage);
        setMeetings(sortMeeting(res.data.data));
        setMeetingLoading(false);
      })
      .catch((err) => {
        setMeetingLoading(false);
        window.scrollTo(0, 0);
        console.log(err);
        if (err.response) {
          err.response.data.message &&
            dispatchPesan({ type: "GAGAL", data: err.response.data.message });
          err.response.data.title &&
            dispatchPesan({ type: "GAGAL", data: err.response.data.title });
        } else {
          dispatchPesan({ type: "GAGAL", data: "Maaf terjadi kesalahan" });
        }
      });
  }, [statePesan.sukses, page]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // jika user telah berhasil registrasi
    dispatchPesan({ type: "SUKSES", data: location.state?.pesanSukses });
    window.scrollTo(0, 0);
  }, [location.state?.pesanSukses]);

  useEffect(() => {
    // jika user telah gagal masuk halaman edit krn lewat url lgsg
    dispatchPesan({ type: "GAGAL", data: location.state?.pesanGagal });
    window.scrollTo(0, 0);
  }, [location.state?.pesanGagal]);

  // pindah ke halaman meeting jika bkn Admin
  if (userData.data.role !== "Admin") {
    return <Redirect to="/meeting?page=1" />;
  }

  return (
    <div className="column p-5">
      {/* peringatan error dan success */}
      <ErrorSuccess
        statePesan={statePesan}
        clearError={clearError}
        clearSuccess={clearSuccess}
      />

      {/* kotak pencarian utk mencari meetings */}
      <KotakCari changeCari={changeCari} placeholder="Cari judul meeting" />

      {/* meeting list */}
      {meetings.length > 0 ? (
        meetings.map((meeting, i) => (
          <RenderPerMeeting meeting={meeting} i={i} modalEditOn={modalEditOn} />
        ))
      ) : meetingLoading ? (
        <progress className="progress is-small is-info" max="100"></progress>
      ) : (
        <p className="has-text-grey-light my-2 mx-2">Belum ada meeting.</p>
      )}

      {/* konfirmasi edit meeting */}
      <ModalEditMeeting
        className={modalEdit.className}
        modalEditOff={modalEditOff}
        roomName={modalEdit.roomName}
        scheduleId={modalEdit.scheduleId}
      />

      <ReactPaginate
        initialPage={page - 1}
        pageCount={totalPage}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        previousLabel="Sebelumnya"
        nextLabel="Selanjutnya"
        containerClassName="pagination is-rounded mt-6"
        pageLinkClassName="pagination-link"
        activeLinkClassName="pagination-link is-current"
        previousClassName="pagination-previous"
        nextClassName="pagination-next"
        breakLinkClassName="pagination-ellipsis"
        onPageChange={onPageClick}
        disableInitialCallback
      />
    </div>
  );
};

// redux
const mapStateToProps = (state) => {
  return {
    userData: state,
  };
};

export default connect(mapStateToProps)(AturMeeting);
