import axios from "../../../config/axios";

const postJoin = (
  roomName,
  scheduleId,
  password,
  userData,
  setMeetingDetail,
  initializeJitsi,
  setModalMeetingPassword,
  setError
) => {
  axios
    .post(
      "/meeting/join",
      {
        roomName,
        scheduleId: scheduleId ? parseInt(scheduleId) : 0,
        password,
      },
      {
        headers: {
          Authorization: "Bearer " + userData.data.jwtToken,
        },
      }
    )
    .then((res) => {
      const data = res.data.data;
      console.log("hasil /join nih", data);
      setMeetingDetail(data);

      if (!data.schedule.isStarted) {
        // jika meeting blm dimulai
        if (data.isSpeaker) {
          // jika pembicara, msk jitsi
          initializeJitsi(data);
        } else {
          // jika bkn pembicara & meeting blm dimulai, msk lobi
          window.location = "/meeting/lobi/" + roomName;
        }
      } else {
        // jika meeting sdh dimulai
        initializeJitsi(data);
      }
    })
    .catch((err) => {
      console.log(err);
      if (err.response) {
        if (err.response.data.message === "Password meeting salah") {
          setModalMeetingPassword(true);
        } else {
          window.scrollTo(0, 0);
          console.log(err);
          err.response.data.message && setError(err.response.data.message);
          err.response.data.title && setError(err.response.data.title);
        }
      } else {
        setError("Maaf terjadi kesalahan");
      }
    });
};

export default postJoin;
