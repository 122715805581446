// format tanggal dari date ISOString
const formatTanggal2 = (dateISOString) => {
  const dateObj = new Date(dateISOString);
  const tahun = dateObj.getFullYear();
  const bulan = dateObj.getMonth();
  const tanggal = dateObj.getDate();

  return `${tanggal} ${intToBulan(bulan)} ${tahun}`;
};

// angka ke bulan menurut js (0 = Januari)
const intToBulan = (int) => {
  const bulan = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Mei",
    "Juni",
    "Juli",
    "Agu",
    "Sept",
    "Okt",
    "Nov",
    "Des",
  ];

  return bulan[int];
};

export default formatTanggal2;
