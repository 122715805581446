import axios from "../../../../config/axios";

const importIcs = (
  jwtToken,
  roomName,
  scheduleId,
  setSuccess,
  setDisableBtn,
  disableBtn
) => {
  if (!disableBtn) {
    setDisableBtn(true);

    axios
      .post(`/meeting/${roomName}/import-ics/${scheduleId}`, null, {
        headers: {
          Authorization: "Bearer " + jwtToken,
        },
      })
      .then((res) => {
        console.log("import ics succeed", res);
        setSuccess(true);
        setDisableBtn(false);
      })
      .catch((err) => {
        console.log("import ics failed", err);
        setDisableBtn(false);
      });
  }
};

export default importIcs;
