import React, { useState } from "react";
import Presentasi from "./Presentasi";
import UploadPresentasi from "./UploadPresentasi";

const PresentasiPanel = ({ show, roomName, scheduleId }) => {
  const [presentasi, setPresentasi] = useState({
    tampil: false,
    slides: [],
  });

  return (
    <div className={show ? "" : "is-hidden"}>
      <div style={styles.presentasiContainer}>
        {presentasi.tampil ? (
          <Presentasi
            roomName={roomName}
            presentasi={presentasi}
            setPresentasi={setPresentasi}
          />
        ) : (
          <UploadPresentasi
            roomName={roomName}
            scheduleId={scheduleId}
            presentasi={presentasi}
            setPresentasi={setPresentasi}
          />
        )}
      </div>
    </div>
  );
};

export const styles = {
  presentasiButton: {
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
  },
  presentasiContainer: {
    overflowY: "scroll",
    overflowX: "hidden",
    position: "absolute",
    left: 0,
    right: 0,
    top: 90,
    bottom: 50,
    padding: 10,
    textAlign: "center",
  },
};

export default PresentasiPanel;
