import React, { useState, useEffect } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import axios from "../../config/axios";
import { Redirect } from "react-router-dom";
import formatTanggal2 from "../../helpers/formatTanggal2";
import formatDate from "../../helpers/formatDate";

const AddMeeting = ({ userData }) => {
  // jika durasi ny 0 jam dan 0 menit
  const [durasiError, setDurasiError] = useState(false);

  // jika meeting berulang berdasar minggu tp hari ny gada yg dicentang
  const [hariError, setHariError] = useState(false);

  // atur meeting berulang
  const [meetingBerulang, setMeetingBerulang] = useState({
    tampil: false,
    berulangBerdasarkan: 0,
    hari: [],
    setiapXHari: 1,
    setiapXMinggu: 1,
    tanggalBulan: 1,
    berakhirType: 0,
    berakhirPada: new Date().toISOString(),
    berakhirSetelah: 1,
  });

  // handle perubahan input date di recuring
  const berulangDateChange = (e) => {
    setMeetingBerulang((prevMeetingBerulang) => {
      return {
        ...prevMeetingBerulang,
        [e.target.id]: e.target.value,
      };
    });
  };

  // menentukan recuring true / false
  const meetingBerulangChange = () => {
    setMeetingBerulang((prevMeetingBerulang) => {
      return {
        ...prevMeetingBerulang,
        tampil: !prevMeetingBerulang.tampil,
      };
    });
  };

  // handle perubahan field yg isinya angka
  const berulangBerdasarkanChange = (e) => {
    setMeetingBerulang((prevMeetingBerulang) => {
      return {
        ...prevMeetingBerulang,
        [e.target.id]: parseInt(e.target.value),
      };
    });
  };

  // handle jika checkbox hari ada yg diklik (msh ada error)
  const hariChange = (e) => {
    // cek apa hari sdh ada di state / blm
    const hariAda =
      meetingBerulang.hari &&
      meetingBerulang.hari.find((h) => h === e.target.id);

    // jika hari ada di state
    if (hariAda) {
      // hapus hari dr state
      setMeetingBerulang((prevMeetingBerulang) => {
        return {
          ...prevMeetingBerulang,
          hari: prevMeetingBerulang.hari.filter((h) => h !== e.target.id),
        };
      });
    } else {
      // tambah hari ke state
      setMeetingBerulang((prevMeetingBerulang) => {
        return {
          ...prevMeetingBerulang,
          hari: prevMeetingBerulang.hari.concat(e.target.id),
        };
      });
    }
  };

  // atur perubahan berakhir / endType
  const berakhirChange = (e) => {
    setMeetingBerulang((prevMeetingBerulang) => {
      return {
        ...prevMeetingBerulang,
        berakhirType: parseInt(e.target.id),
      };
    });
  };

  // state yg akan disubmit oleh form
  const [submit, setSubmit] = useState(defaultSubmitValue);

  // button
  const [button, setButton] = useState(false);
  const [redirect, setRedirect] = useState(false);

  // pesan sukses / error
  const [error, setError] = useState("");
  const [errorFields, setErrorFields] = useState({});
  const [success, setSuccess] = useState("");

  useEffect(() => {
    // ubah title
    document.title = "Tambah Meeting - BPPT";
  }, []);

  // jika form disubmit
  const addMeeting = (e) => {
    e.preventDefault();

    if (
      meetingBerulang.tampil &&
      meetingBerulang.berulangBerdasarkan === 1 &&
      meetingBerulang.hari.length < 1
    ) {
      setHariError(true);
      setDurasiError(false);
    } else if (
      submit.durationJam.toString() === "0" &&
      submit.durationMenit.toString() === "0"
    ) {
      setHariError(false);
      setDurasiError(true);
    } else {
      setHariError(false);
      setDurasiError(false);

      if (!button) {
        // disable dulu buttonny saat fetch api
        setButton(true);

        // lakukan fetch mulai dr skrg
        // memisahkan bbrp property object yg ga terpakai
        const {
          durationJam,
          durationMenit,
          startDateJam,
          startDateMenit,
          startDate,
          hosts,
          participants,
          maxParticipant,
          ...ygDisubmit
        } = submit;

        // tambahkan angka nol jika angka krg dr 10
        const formatNol = (angka) => {
          return angka < 10 ? "0" + angka : angka;
        };

        // manipulasi jam utk mengisi field start & end dr start + durasi
        const jamMulai = parseInt(startDateJam) * 60 + parseInt(startDateMenit);
        const durasi = parseInt(durationJam) * 60 + parseInt(durationMenit);
        const endJam = Math.floor((jamMulai + durasi) / 60);
        const endMenit = (jamMulai + durasi) % 60;
        const end = `${formatNol(endJam)}:${formatNol(endMenit)}`;
        const start = `${formatNol(startDateJam)}:${formatNol(startDateMenit)}`;

        // mengisi field meetingParticipant
        let meetingParticipant = [];
        hosts &&
          hosts.map((host) =>
            meetingParticipant.push({
              userId: host.userId,
              role: 0,
              isBanned: false,
              isPending: false,
              isSpeaker: true,
            })
          );
        participants &&
          participants.map((participant) =>
            meetingParticipant.push({
              userId: participant.userId,
              role: 1,
              isBanned: false,
              isPending: false,
            })
          );

        // menentukan intervalValue
        let intervalValue = 0;
        switch (meetingBerulang.berulangBerdasarkan) {
          case 0:
            intervalValue = meetingBerulang.setiapXHari;
            break;

          case 1:
            intervalValue = meetingBerulang.setiapXMinggu;
            break;

          case 2:
            intervalValue = meetingBerulang.tanggalBulan;
            break;

          default:
            intervalValue = 0;
            break;
        }

        // buat meeting baru
        axios
          .post(
            "/meeting",
            {
              // meetingName = hapus spasi di title dan hurufny dibesarkan smua
              ...ygDisubmit,
              roomName: submit.title
                .toLowerCase()
                .replace(/(?!\w|\s)./g, "")
                .replace(/\s/g, ""),
              schedule: {
                startDate: new Date(startDate).toISOString(),
                start,
                end,
              },
              maxParticipant: parseInt(maxParticipant),
              meetingParticipant,
              isRecuring: meetingBerulang.tampil,
              interval: meetingBerulang.berulangBerdasarkan, // diisi dg 0 (daily) / 1 (weekly) / 2 (monthly)
              intervalValue, // diisi oleh field Setiap (kecuali Bulan, dia diisi field Tanggal)
              occurrenceValues: meetingBerulang.hari.toString(), // diisi oleh field hari dalam 1 minggu
              endType: meetingBerulang.berakhirType, // ada 2 macam
              endValue:
                meetingBerulang.berakhirType === 0
                  ? new Date(meetingBerulang.berakhirPada).toISOString()
                  : meetingBerulang.berakhirSetelah.toString(),
            },
            {
              headers: {
                Authorization: "Bearer " + userData.data.jwtToken,
              },
            }
          )
          .then((res) => {
            console.log(res);

            // jika response ok, isi state success
            setSuccess("Tambah meeting berhasil");

            // pindah ke MeetingList, dg menampilkan pesan sukses
            setRedirect(true);

            window.scrollTo(0, 0);
          })
          .catch((err) => {
            console.log(err);
            setButton(false);
            window.scrollTo(0, 0);
            if (err.response) {
              err.response.data.message && setError(err.response.data.message);
              err.response.data.title && setError(err.response.data.title);
              err.response.data.errors &&
                setErrorFields(err.response.data.errors);
            } else {
              setError("Maaf terjadi kesalahan");
            }
          });
      }
    }
  };

  // onchange handler utk setiap input kecuali select host & participant
  const handleChange = (e) => {
    setSubmit({
      ...submit,
      [e.target.id]: e.target.value,
    });
  };

  // host & participant yg bs dipilih
  const [accounts, setAccounts] = useState([]);

  // utk loading indicator di react select
  const [reactSelectLoading, setReactSelectLoading] = useState(false);

  // onchange handler utk react select
  const reactSelectChange = (e) => {
    if (!reactSelectLoading) {
      setReactSelectLoading(true);

      axios
        .get("/accounts?query=" + encodeURIComponent(e), {
          headers: {
            Authorization: "Bearer " + userData.data.jwtToken,
          },
        })
        .then((res) => {
          console.log(res.data.data);
          const serverAccounts = res.data.data.map((account) => {
            return {
              value: account.id,
              label: `${account.fullName} / ${account.email}`,
              userId: account.id,
            };
          });

          setAccounts(serverAccounts);
          setReactSelectLoading(false);
        })
        .catch(() => setReactSelectLoading(false));
    }
  };

  // host change handler
  const hostChange = (e) => {
    setSubmit({
      ...submit,
      hosts: e,
    });
  };

  // participant change handler
  const participantChange = (e) => {
    setSubmit({
      ...submit,
      participants: e,
    });
  };

  // jika checkbox dicentang
  const checkChange = (e) => {
    setSubmit({
      ...submit,
      [e.target.id]: !submit[e.target.id],
    });
  };

  // jika radio button utk meetingType berubah
  const meetingTypeChange = (e) => {
    setSubmit((prevSubmit) => {
      return {
        ...prevSubmit,
        meetingType: parseInt(e.target.id),
      };
    });
  };

  // pindah ke halaman login jika blm login
  if (userData.data.role !== "Admin") {
    return <Redirect to="/meeting/hari-ini" />;
  } else if (redirect) {
    return (
      <Redirect
        to={{
          pathname: "/meeting/hari-ini",
          state: { pesanSukses: success },
        }}
      />
    );
  }

  return (
    <form onSubmit={addMeeting} className="column p-5">
      {/* peringatan error */}
      <div className="my-5">
        {error && <p className="notification is-danger">{error}</p>}
      </div>

      <p className="is-size-3 mb-5">Tambah Meeting</p>

      {/* topic */}
      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Judul Meeting</label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <input
                className="input"
                type="text"
                id="title"
                placeholder="Topik meeting"
                onChange={handleChange}
                disabled={button}
              />
            </div>

            {/* error per field */}
            {errorFields.Title &&
              errorFields.Title.map((error, i) => (
                <small className="help is-danger" key={i.toString()}>
                  {error}
                </small>
              ))}
          </div>
        </div>
      </div>

      {/* description */}
      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Deskripsi</label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <textarea
                className="textarea"
                placeholder="Jelaskan topiknya"
                id="description"
                onChange={handleChange}
                disabled={button}
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      {/* tgl & jam */}
      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Mulai Meeting</label>
        </div>
        <div className="field-body">
          {/* tanggal */}

          <div className="field">
            <div className="control" style={{ minWidth: 150 }}>
              <label
                htmlFor="startDate"
                style={{
                  position: "absolute",
                  width: "80%",
                  height: "100%",
                  backgroundColor: "#fff",
                  zIndex: 99,
                  padding: "7px 10px",
                  border: "1px solid lightgrey",
                  borderRight: "none",
                  borderRadius: 5,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
              >
                {submit.startDate
                  ? formatTanggal2(submit.startDate)
                  : formatTanggal2(submit.schedule.startDate)}
              </label>
              <input
                name="startDate"
                className="input"
                type="date"
                min={formatDate(new Date())}
                id="startDate"
                onChange={handleChange}
                disabled={button}
                defaultValue={formatDate(submit.schedule.startDate)}
                style={{
                  border: "1px solid lightgrey",
                }}
              />
            </div>
          </div>

          {/* jam */}
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Jam</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      id="startDateJam"
                      onChange={handleChange}
                      defaultValue={10}
                    >
                      {hours.map((hour) => (
                        <option value={hour} key={hour.toString()}>
                          {hour}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* menit */}
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Menit</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <div className="select is-fullwidth">
                    <select id="startDateMenit" onChange={handleChange}>
                      <option value={0}>0</option>
                      <option value={15}>15</option>
                      <option value={30}>30</option>
                      <option value={45}>45</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* duration */}
      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Durasi</label>
        </div>
        <div className="field-body">
          {/* jam */}
          <div className="field is-narrow">
            <div className="control">
              <div className="select">
                <select
                  id="durationJam"
                  onChange={handleChange}
                  defaultValue={0}
                >
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                </select>
              </div>
            </div>
          </div>

          <p className="mt-2 mr-3">Jam</p>

          {/* menit */}
          <div className="field is-narrow">
            <div className="control">
              <div className="select">
                <select
                  id="durationMenit"
                  onChange={handleChange}
                  defaultValue={45}
                >
                  <option value={0}>0</option>
                  <option value={15}>15</option>
                  <option value={30}>30</option>
                  <option value={45}>45</option>
                </select>
              </div>
            </div>
          </div>

          <p className="mt-2 mr-3">Menit</p>

          {durasiError && (
            <small className="help is-danger mt-2 ml-3">
              Durasi tidak boleh 0
            </small>
          )}
        </div>
      </div>

      {/* password */}
      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Password</label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <input
                className="input"
                type="password"
                placeholder="Password (tidak wajib)"
                id="password"
                onChange={handleChange}
                disabled={button}
              />
            </div>
          </div>
        </div>
      </div>

      {/* video */}
      {/* <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Video</label>
        </div>
        <div className="field-body">
          <div className="field is-grouped is-grouped-centered">
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">Host</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <label className="radio">
                      <input
                        type="checkbox"
                        id="isHostVideoAvailable"
                        className="mr-3"
                        onChange={checkChange}
                        defaultChecked
                        disabled={button}
                      />
                      Hidup
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="field is-grouped is-grouped-centered">
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">Partisipan</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <label className="radio">
                      <input
                        type="checkbox"
                        id="isParticipantVideoAvailable"
                        className="mr-3"
                        onChange={checkChange}
                        defaultChecked
                        disabled={button}
                      />
                      Hidup
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* advanced options */}
      {/* <div className="field is-horizontal">
        <div className="field-label">
          <label className="label">Saat Meeting Dimulai</label>
        </div>
        <div className="field-body">
          <div className="field is-narrow">
            <div className="control">
              <label className="checkbox">
                <input
                  type="checkbox"
                  className="mr-3"
                  id="isMuteParticipantUponEntry"
                  onChange={checkChange}
                  disabled={button}
                />
                Matikan suara saat partisipan masuk
              </label>
              <br />
            </div>
          </div>
        </div>
      </div> */}

      {/* isAdmit */}
      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Ada Persetujuan?</label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <label className="radio">
                <input
                  type="checkbox"
                  id="isAdmit"
                  className="mr-3 mt-3"
                  onChange={checkChange}
                  disabled={button}
                />
                Ya
              </label>
            </div>
          </div>
        </div>
      </div>

      {/* isPublic */}
      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Meeting Publik?</label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <label className="radio">
                <input
                  type="checkbox"
                  id="isPublic"
                  className="mr-3 mt-3"
                  onChange={checkChange}
                  disabled={button}
                  defaultChecked
                />
                Ya
              </label>
            </div>
          </div>
        </div>
      </div>

      {/* isRecuring */}
      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Meeting Berulang?</label>
        </div>
        <div className="field-body mt-3">
          <div className="field">
            <div className="control">
              <label className="radio">
                <input
                  type="checkbox"
                  id="isRecuring"
                  className="mr-3 mt-3"
                  onChange={meetingBerulangChange}
                  disabled={button}
                />
                Ya
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">{/* kosong utk memberi jarak */}</label>
        </div>
        <div className="field-body">
          <div className="field">
            {/* berulang berdasarkan */}
            {meetingBerulang.tampil && (
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label" style={{ width: 100 }}>
                    Berdasarkan
                  </label>
                </div>
                <div className="field-body">
                  <div className="field is-narrow">
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          id="berulangBerdasarkan"
                          onChange={berulangBerdasarkanChange}
                          disabled={button}
                          defaultValue={meetingBerulang.berulangBerdasarkan}
                        >
                          <option value={0}>Hari</option>
                          <option value={1}>Minggu</option>
                          <option value={2}>Bulan</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* setiap hari */}
            {meetingBerulang.berulangBerdasarkan === 0 &&
              meetingBerulang.tampil && (
                <div className="field is-horizontal mt-3">
                  <div className="field-label is-normal">
                    <label className="label" style={{ width: 100 }}>
                      Setiap
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field is-narrow">
                      <div className="control">
                        <div className="select is-fullwidth">
                          <select
                            id="setiapXHari"
                            onChange={berulangBerdasarkanChange}
                            disabled={button}
                          >
                            {oneToFifteen.map((angka) => (
                              <option value={angka} key={angka.toString()}>
                                {angka}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <p className="mt-2">Hari</p>
                  </div>
                </div>
              )}

            {/* setiap minggu */}
            {meetingBerulang.berulangBerdasarkan === 1 &&
              meetingBerulang.tampil && (
                <>
                  <div className="field is-horizontal mt-3">
                    <div className="field-label is-normal">
                      <label className="label" style={{ width: 100 }}>
                        Setiap
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field is-narrow">
                        <div className="control">
                          <div className="select is-fullwidth">
                            <select
                              id="setiapXMinggu"
                              onChange={berulangBerdasarkanChange}
                              disabled={button}
                            >
                              {oneToFifteen.map((angka) => (
                                <option value={angka} key={angka.toString()}>
                                  {angka}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <p className="mt-2">Minggu</p>
                    </div>
                  </div>

                  <div className="field is-horizontal mt-3">
                    <div className="field-label is-normal">
                      <label className="label" style={{ width: 100 }}>
                        Hari?
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field is-narrow">
                        <div className="control">
                          {/* checkbox */}
                          {days.map((day, i) => (
                            <label className="checkbox" key={day}>
                              <input
                                type="checkbox"
                                className="mx-2"
                                id={i}
                                onChange={hariChange}
                              />
                              {day}
                            </label>
                          ))}
                        </div>

                        {hariError && (
                          <small className="help is-danger">
                            Hari harus dipilih
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}

            {/* setiap bulan */}
            {meetingBerulang.berulangBerdasarkan === 2 &&
              meetingBerulang.tampil && (
                <div className="field is-horizontal mt-3">
                  <div className="field-label is-normal">
                    <label className="label" style={{ width: 100 }}>
                      Tanggal
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field is-narrow">
                      <div className="control">
                        <div className="select is-fullwidth">
                          <select
                            id="tanggalBulan"
                            onChange={berulangBerdasarkanChange}
                            disabled={button}
                          >
                            {tanggals.map((tanggal) => (
                              <option value={tanggal} key={tanggal.toString()}>
                                {tanggal}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

            {/* meeting berulang berakhir pada */}
            {meetingBerulang.tampil && (
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label" style={{ width: 100 }}>
                    Berakhir pada
                  </label>
                </div>
                <div className="field-body mt-3">
                  <div className="field">
                    <div className="control mt-2">
                      <input
                        type="radio"
                        name="berakhirType"
                        id={0}
                        onChange={berakhirChange}
                        defaultChecked
                      />
                    </div>
                  </div>

                  <div className="field">
                    <div className="control" style={{ minWidth: 150 }}>
                      <label
                        htmlFor="berakhirPada"
                        style={{
                          position: "absolute",
                          width: "80%",
                          height: "100%",
                          backgroundColor: "#fff",
                          zIndex: 99,
                          padding: "7px 10px",
                          border: "1px solid lightgrey",
                          borderRight: "none",
                          borderRadius: 5,
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                        }}
                      >
                        {meetingBerulang.berakhirPada
                          ? formatTanggal2(meetingBerulang.berakhirPada)
                          : formatTanggal2(submit.schedule.startDate)}
                      </label>
                      <input
                        name="berakhirPada"
                        className="input"
                        type="date"
                        min={formatDate(new Date())}
                        id="berakhirPada"
                        onChange={berulangDateChange}
                        disabled={button}
                        defaultValue={formatDate(submit.schedule.startDate)}
                        style={{ border: "1px solid lightgrey" }}
                      />
                    </div>
                  </div>

                  <p className="field label" style={{ marginTop: -5 }}>
                    Berakhir
                    <br />
                    setelah
                  </p>
                  <div className="field">
                    <div className="control mt-2">
                      <input
                        type="radio"
                        name="berakhirType"
                        id={1}
                        onChange={berakhirChange}
                      />
                    </div>
                  </div>

                  <div className="field">
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          id="berakhirSetelah"
                          onChange={berulangBerdasarkanChange}
                          disabled={button}
                        >
                          {oneToFifteen.map((angka) => (
                            <option value={angka} key={angka.toString()}>
                              {angka}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <p className="mt-2 ml-auto">Pertemuan</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* meetingType */}
      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Tipe Meeting</label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <label className="radio">
                <input
                  type="radio"
                  name="meetingType"
                  id={0}
                  className="mr-3 mt-3"
                  onChange={meetingTypeChange}
                  disabled={button}
                  defaultChecked
                />
                Meeting Biasa{" "}
                <small className="ml-2 has-text-grey">
                  (Partisipan Maksimal = 40)
                </small>
              </label>

              <br />

              <label className="radio">
                <input
                  type="radio"
                  name="meetingType"
                  id={1}
                  className="mr-3 mt-3"
                  onChange={meetingTypeChange}
                  disabled={button}
                />
                Meeting Konferensi{" "}
                <small className="ml-2 has-text-grey">
                  (Partisipan Maksimal {">"} 40)
                </small>
              </label>
            </div>
          </div>
        </div>
      </div>

      {/* max participant */}
      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Jumlah Partisipan Maksimal</label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <input
                className="input"
                type="number"
                min="1"
                max={submit.meetingType.toString() === "0" ? 40 : 999}
                id="maxParticipant"
                placeholder="Jumlah partisipan maksimal"
                onChange={handleChange}
                disabled={button}
                defaultValue={submit.maxParticipant}
              />
            </div>
          </div>
        </div>
      </div>

      {/* host */}
      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Host</label>
        </div>
        <div className="field-body">
          <div className="field">
            <Select
              defaultValue={[]}
              isMulti
              options={accounts}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={hostChange}
              isDisabled={button}
              placeholder="Cari nama user"
              onInputChange={reactSelectChange}
              isLoading={reactSelectLoading}
            />
          </div>
        </div>
      </div>

      {/* participant */}
      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Partisipan</label>
        </div>
        <div className="field-body">
          <div className="field">
            <Select
              defaultValue={[]}
              isMulti
              options={accounts}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={participantChange}
              isDisabled={button}
              placeholder="Cari nama user"
              onInputChange={reactSelectChange}
              isLoading={reactSelectLoading}
            />
          </div>
        </div>
      </div>

      {/* button */}
      <div className="field is-horizontal">
        <div className="field-label">{/* Left empty for spacing */}</div>

        <div className="field-body">
          <div className="field">
            <div className="control">
              <button className="button is-link" disabled={button}>
                Simpan
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export const dateStyles = {
  position: "absolute",
  width: "80%",
  height: "100%",
  backgroundColor: "#fff",
  zIndex: 99,
  padding: "7px 10px",
  border: "1px solid lightgrey",
  borderRight: "none",
  borderRadius: 5,
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
};

// utk Duration
let hours = [];
for (let i = 0; i < 24; i++) {
  hours.push(i);
}

// utk hari & minggu
let oneToFifteen = [];
for (let i = 1; i < 16; i++) {
  oneToFifteen.push(i);
}

// tanggals
let tanggals = [];
for (let i = 1; i < 32; i++) {
  tanggals.push(i);
}

// minggu - sabtu
let days = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"];

const mapStateToProps = (state) => {
  return {
    userData: state,
  };
};

const defaultSubmitValue = {
  roomName: "",
  title: "",
  description: "",
  password: "",
  isRecuring: false,
  // data baru
  schedule: {
    startDate: new Date().toISOString(),
    start: "",
    end: "",
  },
  interval: 0, // diisi dg 0 (daily) / 1 (weekly) / 2 (monthly)
  intervalValue: 0, // diisi oleh field Setiap (kecuali Bulan, dia diisi field Tanggal)
  occurrenceValues: "string", // diisi oleh field hari dalam 1 minggu
  endType: 0, // ada 2 macam
  endValue: "string",
  // data baru end
  calendar: "blm tau isinya",
  isHostVideoAvailable: true,
  isParticipantVideoAvailable: true,
  isWaitingRoomEnabled: true,
  isJoinBeforeHostEnabled: false,
  isMuteParticipantUponEntry: false,
  isRecordAutomatically: false,
  isPublic: true,
  isAdmit: false,
  maxParticipant: 40,
  meetingParticipant: [],
  // state khusus yg akan dimanipulasi, tdk diinput ke POST
  durationJam: 0,
  durationMenit: 45,
  startDateJam: 10,
  startDateMenit: 0,
  startDate: new Date().toISOString(),
  meetingType: 0,
};

export default connect(mapStateToProps)(AddMeeting);
