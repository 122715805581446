import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import axios from "../../config/axios";

const AddUser = ({ userData }) => {
  // pesan error
  const [error, setError] = useState("");
  const [errorFields, setErrorFields] = useState({});

  // redirect ke daftar berita jika berhasil tambah berita
  const [redirect, setRedirect] = useState(false);

  // disableBtn button & field saat fetch
  const [disableBtn, setDisableBtn] = useState(false);

  // object yg akan disubmit
  const [submit, setSubmit] = useState({
    title: "Pak",
    fullName: "",
    role: "User",
    email: "",
    password: "",
    confirmPassword: "",
  });

  // onchange handler utk setiap input
  const handleChange = (e) => {
    setSubmit((prevSubmit) => {
      if (e.target.id === "isActive") {
        return {
          ...prevSubmit,
          [e.target.id]: e.target.value === "false" ? false : true,
        };
      } else {
        return {
          ...prevSubmit,
          [e.target.id]: e.target.value,
        };
      }
    });
  };

  // POST ke /api/accounts
  const addUser = (e) => {
    // spy tdk refresh halaman saat submit form
    e.preventDefault();

    if (!disableBtn) {
      // disableBtn button saat fetch
      setDisableBtn(true);

      // mulai fetch
      axios
        .post("/accounts", submit, {
          headers: {
            Authorization: "Bearer " + userData.data.jwtToken,
          },
        })
        .then(() => setRedirect(true))
        .catch((err) => {
          setDisableBtn(false);
          console.log(err);
          window.scrollTo(0, 0);
          if (err.response) {
            err.response.data.message && setError(err.response.data.message);
            err.response.data.title && setError(err.response.data.title);
            err.response.data.errors &&
              setErrorFields(err.response.data.errors);
          } else {
            setError("Maaf terjadi kesalahan");
          }
        });
    }
  };

  useEffect(() => {
    // ubah title
    document.title = "Tambah User - BPPT";
  }, []);

  // pindah ke halaman login jika blm login
  if (userData.data.role !== "Admin") {
    return <Redirect to="/meeting/hari-ini" />;
  } else if (redirect) {
    return (
      <Redirect
        to={{
          pathname: "/user",
          state: { pesanSukses: "Tambah user berhasil" },
        }}
      />
    );
  }

  return (
    <form className="column p-5" onSubmit={addUser}>
      {/* peringatan error */}
      <div className="my-5">
        {error && <p className="notification is-danger">{error}</p>}
      </div>

      {/* judul */}
      <p className="is-size-3 mb-5">Tambah User</p>

      {fields.map((field, i) => (
        <div className="field is-horizontal" key={i.toString()}>
          <div className="field-label is-normal">
            <label className="label">{field.label}</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <RenderInput field={field} handleChange={handleChange} />
              </div>

              {/* error per field  */}
              {errorFields[field.error] &&
                errorFields[field.error].map((error, i) => (
                  <small className="help is-danger" key={i.toString()}>
                    {error}
                  </small>
                ))}
            </div>
          </div>
        </div>
      ))}

      <div className="field is-horizontal">
        <div className="field-label">{/* kosong utk kasih jarak */}</div>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <button className="button is-link" disabled={disableBtn}>
                Simpan
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

// di looping utk menghasilkan field
const fields = [
  { label: "Nama Lengkap", id: "fullName", type: "text", error: "FullName" },
  {
    label: "Jenis Kelamin",
    id: "title",
    type: "select",
    options: [
      { value: "Pak", label: "Laki-laki" },
      { value: "Bu", label: "Perempuan" },
    ],
    error: "Title",
  },
  {
    label: "Jabatan",
    id: "role",
    type: "select",
    options: [
      { value: "User", label: "User" },
      { value: "Admin", label: "Admin" },
    ],
    error: "isActive",
  },
  {
    label: "Aktif?",
    id: "isActive",
    type: "select",
    options: [
      { value: true, label: "Ya" },
      { value: false, label: "Tidak" },
    ],
    error: "isActive",
  },
  { label: "Email", id: "email", type: "email", error: "Email" },
  { label: "Password", id: "password", type: "password", error: "Password" },
  {
    label: "Ulangi Password",
    id: "confirmPassword",
    type: "password",
    error: "ConfirmPassword",
  },
];

const RenderInput = ({ field, handleChange }) => {
  // destructuring prop field
  const { type, label, id, options } = field;

  // daftar jsx yg bs dipilih sesuai type
  const inputs = {
    text: (
      <input
        className="input"
        id={id}
        type="text"
        placeholder={label}
        onChange={handleChange}
      />
    ),
    email: (
      <input
        className="input"
        id={id}
        type="email"
        placeholder={label}
        onChange={handleChange}
      />
    ),
    password: (
      <input
        className="input"
        id={id}
        type="password"
        placeholder={label}
        onChange={handleChange}
      />
    ),
    select: (
      <div className="select">
        <select id={id} onChange={handleChange}>
          {options?.map((option) => (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    ),
  };

  return inputs[type];
};

const mapStateToProps = (state) => {
  return {
    userData: state,
  };
};

export default connect(mapStateToProps)(AddUser);
