import React, { useState } from "react";
import { useSelector } from "react-redux";
import importIcs from "./zimbra-calendar/importIcs";

const AddZimbraCalendar = ({ roomName, schedule, setSuccess }) => {
  const userData = useSelector((state) => state);
  const [disableBtn, setDisableBtn] = useState(false);

  const handleItemClick = () => {
    importIcs(
      userData.data.jwtToken,
      roomName,
      schedule.id,
      setSuccess,
      setDisableBtn,
      disableBtn
    );
  };

  return (
    <button
      className="button is-fullwidth is-danger"
      title="Tambah ke Zimbra Calendar"
      onClick={handleItemClick}
      disabled={disableBtn}
    >
      <i className="fas fa-calendar-alt mr-2"></i> Zimbra Calendar
    </button>
  );
};

export default AddZimbraCalendar;
