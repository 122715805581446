import React, { useState } from "react";
import axios from "../../config/axios";
import VideoMeeting from "./VideoMeeting";

const JoinMeetingPassword = ({ display, roomName, userData, location }) => {
  const [eye, setEye] = useState(false);
  const toggleEye = () => setEye((prevEye) => !prevEye);
  const [error, setError] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const [redirectJitsi, setRedirectJitsi] = useState(false);
  const [password, setPassword] = useState("");
  const passwordChange = (e) => setPassword(e.target.value);

  const kirimPassword = (e) => {
    e.preventDefault();

    if (!disableBtn) {
      setDisableBtn(true);

      axios
        .post(
          "/meeting/join",
          {
            roomName,
            password,
          },
          {
            headers: {
              Authorization: "Bearer " + userData.data.jwtToken,
            },
          }
        )
        .then((res) => {
          console.log(res);
          setRedirectJitsi(true);
        })
        .catch((err) => {
          setDisableBtn(false);
          window.scrollTo(0, 0);
          console.log(err);
          if (err.response) {
            err.response.data.message && setError(err.response.data.message);
            err.response.data.title && setError(err.response.data.title);
          } else {
            setError("Maaf terjadi kesalahan");
          }
        });
    }
  };

  if (redirectJitsi) {
    return (
      <VideoMeeting
        userData={userData}
        password={password}
        location={location}
      />
    );
  }

  return (
    <div className={display ? "modal is-active" : "modal"}>
      <div className="modal-background" style={{ opacity: 0.6 }}></div>
      <form className="modal-card" onSubmit={kirimPassword}>
        <header className="modal-card-head">
          <p className="modal-card-title">Meeting membutuhkan password!</p>
        </header>
        <section className="modal-card-body">
          <div className="field">
            <div className="columns">
              <input
                className="input is-inline column is-11"
                type={eye ? "text" : "password"}
                placeholder="Password"
                onChange={passwordChange}
              />
              <span
                className="icon is-small is-clickable column"
                onClick={toggleEye}
              >
                <i className={eye ? "fas fa-eye" : "fas fa-eye-slash"}></i>
              </span>
            </div>

            {error && <small className="help is-danger">{error}</small>}
          </div>
        </section>
        <footer className="modal-card-foot">
          <button className="button is-link" disabled={disableBtn}>
            Kirim
          </button>
        </footer>
      </form>
    </div>
  );
};

export default JoinMeetingPassword;
