import React, { useState, useRef } from "react";
import axios from "../../config/axios";

const AddKategori = ({ endpoint, jwtToken, setOptions }) => {
  const formRef = useRef(null);
  const inputRef = useRef(null);
  const [modalOn, setModalOn] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const toggleModal = () => setModalOn((prevModal) => !prevModal);

  const displayModal = (e) => {
    e.preventDefault();
    toggleModal();
  };

  const submitForm = () => {
    // console.log(inputRef.current.value);
    if (!inputRef.current.value) {
      setError("Isi nama kategori terlebih dahulu");
    } else {
      let data = new FormData(formRef.current);

      axios
        .post(endpoint, data, {
          headers: {
            Authorization: "Bearer " + jwtToken,
          },
        })
        .then((res) => {
          const data = res.data.data;
          console.log("post kategory succeed", data);
          setOptions(data);
          setSuccess("Tambah kategori berhasil");
          setError("");
        })
        .catch((err) => {
          console.log("post kategory failed", err);
          if (err.response) {
            err.response.data.message && setError(err.response.data.message);
            err.response.data.title && setError(err.response.data.title);
          } else {
            setError("Maaf terjadi kesalahan");
          }

          setSuccess("");
        });
    }
  };

  return (
    <>
      <a href="/#" className="ml-2 mt-2 is-inline-block" onClick={displayModal}>
        Tambah kategori
      </a>

      <div className={modalOn ? "modal is-active" : "modal"}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Tambah Kategori</p>
            <button
              type="button"
              className="delete"
              aria-label="close"
              onClick={toggleModal}
            ></button>
          </header>
          <form ref={formRef} className="modal-card-body">
            {error && <p className="notification is-danger">{error}</p>}
            {success && <p className="notification is-success">{success}</p>}

            <input
              ref={inputRef}
              name="Title"
              className="input"
              type="text"
              placeholder="Nama kategori"
            />
          </form>
          <footer className="modal-card-foot">
            <button
              type="button"
              className="button is-link"
              onClick={submitForm}
            >
              Tambahkan
            </button>
            <button type="button" className="button" onClick={toggleModal}>
              Batal
            </button>
          </footer>
        </div>
      </div>
    </>
  );
};

export default AddKategori;
