import dayjs from "dayjs";

const initParticipants = {
  participants: [],
  cariParticipants: [],
};

const participantsReducer = (state = initParticipants, action) => {
  switch (action.type) {
    case "INITIAL_PARTICIPANTS":
      console.log("INITIAL_PARTICIPANTS");
      return {
        participants: action.data,
        cariParticipants: action.data,
      };

    // utk pencarian partisipan
    case "CHANGE_PARTICIPANTS":
      return {
        ...state,
        participants: action.data,
      };

    case "ADD_PARTICIPANT":
      console.log("ADD_PARTICIPANT", action.data);
      const {
        authorId,
        fullName,
        joinDate,
        imageSmall,
        jitsiId,
        isSpeaker,
      } = action.data;

      // new arr
      const newParticipants = state.participants.concat({
        id: authorId,
        displayName: fullName,
        joinTime: dayjs(joinDate).add(-7, "hour"),
        avatar: imageSmall,
        jitsiId,
        isSpeaker,
      });

      return {
        participants: newParticipants,
        cariParticipants: newParticipants,
      };

    case "REMOVE_PARTICIPANT":
      console.log("REMOVE_PARTICIPANT", action.data);
      const newParticipants2 = state.participants.filter(
        (participant) => participant.id !== action.data
      );

      return {
        participants: newParticipants2,
        cariParticipants: newParticipants2,
      };

    case "SPEAKER_FLAG":
      const newParticipants5 = state.participants.map((participant) =>
        participant.jitsiId === action.data
          ? { ...participant, speakerFlag: true }
          : { ...participant, speakerFlag: false }
      );

      return {
        participants: newParticipants5,
        cariParticipants: newParticipants5,
      };

    case "CHANGE_ISSPEAKER":
      const newParticipants6 = state.participants.map((participant) =>
        participant.id === action.data.userId
          ? { ...participant, isSpeaker: action.data.isSpeaker }
          : { ...participant }
      );

      return {
        participants: newParticipants6,
        cariParticipants: newParticipants6,
      };

    default:
      return state;
  }
};

export { initParticipants, participantsReducer };
