import { config } from "../../../../config";

const msalConfig = {
  auth: {
    clientId: config.REACT_APP_MICROSOFT_APP_ID,
    redirectUri: config.REACT_APP_URL_UTAMA,
  },
};

const msalRequest = {
  scopes: ["user.read", "mailboxsettings.read", "calendars.readwrite"],
};

export { msalConfig, msalRequest };
