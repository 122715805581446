import React from "react";
import { config } from "../../../../config";
import VideoAudio from "./VideoAudio";

const RenderParticipant = ({
  participant,
  setTabs,
  setRecipentProps,
  signalRConn,
  roomName,
  isHost,
  jitsi,
  meetingType,
  dispatchParticipants,
}) => {
  return participant.displayName ? (
    <>
      <div className="panel-block is-active">
        {/* nama dan icon user */}
        <span className="panel-icon">
          <img
            src={
              participant.avatar
                ? config.REACT_APP_URL_GAMBAR + participant.avatar
                : "/img/placeholder/bppt14x14.png"
            }
            alt={participant.displayName}
            width="20px"
          />
        </span>

        {participant.displayName.substring(0, 20)}
        {participant.displayName.length > 20 && "..."}
        {participant.speakerFlag && (
          <i
            className="fas fa-volume-up ml-2 mr-2 has-text-danger"
            aria-hidden="true"
          ></i>
        )}

        {/* icon di pojok kanan participant di Participant Panel */}
        <VideoAudio
          participant={participant}
          setTabs={setTabs}
          setRecipentProps={setRecipentProps}
          signalRConn={signalRConn}
          roomName={roomName}
          isHost={isHost}
          jitsi={jitsi}
          meetingType={meetingType}
          dispatchParticipants={dispatchParticipants}
        />
      </div>
    </>
  ) : (
    <div className="has-background-white"></div>
  );
};

export default RenderParticipant;
